import { Box } from '@mui/material'

import { ReactNode } from 'react'

export interface FilterRootProps {
  children: ReactNode
}
export default function FilterRoot(props: FilterRootProps) {
  const { children } = props
  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.border}`,
        borderRadius: '90px',
        flexGrow: 1,
        pl: 1,
        pr: 1,
      }}
    >
      {children}
    </Box>
  )
}
