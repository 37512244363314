import { Button, Stack } from '@mui/material'
import { useFilterApi } from './FilterContext'
import Icon from 'components/MioCandidate/Icon'
export default function ApplyButton() {
  const { applyFilterValues } = useFilterApi()

  return (
    <Button
      variant="contained"
      onClick={() => {
        applyFilterValues()
      }}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Icon.Search sx={{ fontSize: '0.855rem' }} /> <span>Pesquisar</span>
      </Stack>
    </Button>
  )
}
