import { Autocomplete, MenuItem, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import FilterForm from './FilterForm'
import { TabelaPrecoFilter1 } from 'hooks/queries/useTabelaPreco'

export default function Filter() {
  const { changeFilterValue, applyFilterValues, onChangeFilterValue } =
    useFilterApi<{
      plainQuery?: string
    }>()

  const { filterValues } = useFilter<TabelaPrecoFilter1>()

  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Tabelas de Preços"
        />

        <MioFilter.Chips>
          <MioFilter.Chip name="ativo" label={'Somente ativos'}>
            <TextField
              label="Somente ativos"
              name="ativo"
              value={Boolean(filterValues?.ativo)}
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
