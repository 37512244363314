import { useMemo, useCallback } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  DataTable,
  DataTableOptions as Options,
  OptionStyles,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import useEtiqueta, { EtiquetaModel } from 'hooks/queries/useEtiqueta'
import { AxiosError } from 'axios'

export default function Table() {
  const { useQueryObterEtiquetas } = useEtiqueta()

  const { data, isLoading, isFetching, error } = useQueryObterEtiquetas()
  const { openForm, openConfirm } = useFormApi()

  const findData = useCallback(
    (value: string) => {
      return data?.find((d: EtiquetaModel) => d.uuid === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'manutencao', data: EtiquetaModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nome',
        label: 'Nome',
      },

      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      error={(error as AxiosError<unknown, any>)?.message}
      columns={columns}
      data={data || []}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
