import { Stack } from '@mui/material'
import React from 'react'

export interface FilterAdvancedActionsContainerProps {
  children: React.ReactNode
}

export default function FilterAdvancedActionsContainer({
  children,
}: FilterAdvancedActionsContainerProps) {
  return (
    <Stack pt={2} direction="row" spacing={1} justifyContent="flex-end">
      {children}
    </Stack>
  )
}
