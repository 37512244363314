import { Button } from '@data-c/ui'
import { ButtonProps, Menu, MenuItem } from '@mui/material'
import Icon from 'components/MioCandidate/Icon'
import useFunilVenda, { FunilVendaModel } from 'hooks/queries/useFunilVenda'
import usePopOver from 'hooks/usePopover'

export interface FunilKanbanSelectionButtonProps
  extends Omit<ButtonProps, 'onChange' | 'value'> {
  onChange: (funilVenda: FunilVendaModel) => void
  value?: FunilVendaModel
  emptyMessage?: string
}

export default function FunilKanbanSelectionButton(
  props: FunilKanbanSelectionButtonProps,
) {
  const {
    onChange,
    value,
    emptyMessage = 'Nenhum funil selecionado',
    ...rest
  } = props
  const { useQueryRaw } = useFunilVenda()
  const { data, isLoading } = useQueryRaw()
  const funis = data || []
  const { isOpen, anchorEl, open, close } = usePopOver()
  return (
    <div>
      <Button
        sx={{
          width: '130px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={open}
        isLoading={isLoading}
        variant="contained"
        endIcon={<Icon.ChevronDown />}
        {...rest}
      >
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            maxWidth: '100%', // Limita o texto ao tamanho do botão
          }}
        >
          {value?.nome || emptyMessage}
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={close}
        sx={{ '& .MuiMenu-paper': { minWidth: '250px', p: 1 } }}
      >
        {funis.map((f) => (
          <MenuItem onClick={() => onChange(f)} key={f.uuid} value={f.uuid}>
            {f.nome}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
