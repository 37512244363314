import { Alert, Badge, IconButton, Popover, Stack } from '@mui/material'
import { Notifications as NotificationsIcon } from '@mui/icons-material'
import usePopOver from 'hooks/usePopover'

export interface NotificationsProps {
  notifications?: Array<string>
}

export default function Notifications(props: NotificationsProps) {
  const { notifications = [] } = props
  const { open, anchorEl, close } = usePopOver()

  //   if (notifications.length <= 0) return null

  return (
    <div>
      <IconButton onClick={open}>
        <Badge
          variant="dot"
          color="error"
          invisible={notifications.length === 0}
        >
          <NotificationsIcon
            color={notifications.length > 0 ? 'info' : 'disabled'}
          />
        </Badge>
      </IconButton>
      <Popover
        sx={{
          '& .MuiPopover-paper': {
            minWidth: '300px',
            // margin: (theme) => theme.spacing(1),
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={close}
        anchorEl={anchorEl}
      >
        <Stack spacing={1} m={1}>
          {notifications.map((n) => (
            <Alert severity="info">{n}</Alert>
          ))}
        </Stack>
      </Popover>
    </div>
  )
}
