import {
  CurrencyCellStyle,
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useQueryItemTabelaPreco } from 'hooks/queries/useTabelaPreco'

import { useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import {
  TransportableDataTableProps,
  usePagination,
  findData,
  formatCurrency,
} from '@data-c/hooks'
import { ProdutoTransporter } from 'components/Transporters/ItensTabelaPrecoTransporter'
import { ProdutoFilters1 } from 'hooks/queries/useProdutos'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'

interface TableProps extends TransportableDataTableProps<ProdutoTransporter> {
  tabelaPrecoUuid?: string
  prontaEntrega?: boolean
}

export default function ItensTabelaPrecoTable(props: TableProps) {
  const { tabelaPrecoUuid, onTransport, enableTransporter, prontaEntrega } =
    props
  const { pagination, changePageSize, changePage } = usePagination()
  const { appliedValues, searchId } = useFilter<ProdutoFilters1>()
  const { categoriaValue, ...rest } = appliedValues || {}
  if (!tabelaPrecoUuid) {
    throw Error(
      'Não é possível carregar o componente [ItensTabelaPrecoTable] sem informar a tabela de preço',
    )
  }

  const categoriaUuid = categoriaValue?.uuid || ''

  const { data, isLoading, isFetching, error } = useQueryItemTabelaPreco(
    tabelaPrecoUuid,
    {
      queryParams: {
        ...rest,
        categoriaUuid,
        ativo: true,
        prontaEntrega,
      },
      requestId: searchId,
      pagination,
    },
  )
  const { openForm, openConfirm } = useFormApi()

  const itens = useMemo(() => {
    return data?.data.map((item) => ({
      uuidItem: item.uuidItem,
      codigoProduto: item.produto.codigo,
      nomeProduto: item.produto.nome,
      precoProduto: formatCurrency(item?.precoDeTabela || ''),
      ...item.produto,
      produtocls: item.produto?.produtoClasse?.nome || '',
      produtofml: item.produto?.familiaProduto?.nome || '',
      produtolnh: item.produto?.linhaProduto?.nome || '',
      produtogrp: item.produto?.grupoProduto?.nome || '',
    }))
  }, [data?.data])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: ProdutoTransporter) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [openForm, openConfirm, onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
      },
      {
        name: 'produtolnh',
        label: 'Linha',
        options: {
          setCellProps: () => ({
            style: {
              width: '100px',
            },
          }),
        },
      },
      {
        name: 'produtogrp',
        label: 'Grupo',
        options: {
          setCellProps: () => ({
            style: {
              width: '100px',
            },
          }),
        },
      },
      {
        name: 'produtofml',
        label: 'Família',
        options: {
          setCellProps: () => ({
            style: {
              width: '100px',
            },
          }),
        },
      },
      {
        name: 'produtocls',
        label: 'Classe',
        options: {
          setCellProps: () => ({
            style: {
              width: '100px',
            },
          }),
        },
      },
      {
        name: 'precoProduto',
        label: 'Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      //   {
      //     name: 'ativo',
      //     label: 'Ativo',
      //     options: {
      //       setCellProps: () => ({
      //         style: {
      //           width: '60px',
      //           textAlign: 'center',
      //         },
      //       }),
      //       customBodyRender: (value) => {
      //         return <Flag isFlagged={value === 1} type="success" />
      //       },
      //     },
      //   },
      {
        name: 'uuidItem',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value, 'uuidItem')
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem, enableTransporter],
  )
  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={itens || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
