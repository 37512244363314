import { Stack } from '@mui/material'
import { ReactNode } from 'react'

export interface OnboardingCardActionsContainerProps {
  children?: ReactNode
}

export default function OnboardingCardActionsContainer({
  children,
}: OnboardingCardActionsContainerProps) {
  return (
    <Stack
      p={2}
      spacing={1}
      direction="row"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      {children}
    </Stack>
  )
}
