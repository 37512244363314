import { CheckCircle, Close } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { ComponentType, useEffect, useMemo, useState } from 'react'

export interface EditableProps<T> {
  value: T
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  component: ComponentType<TextFieldProps>
  isLoading?: boolean
}
export default function Editable(props: EditableProps<any>) {
  const { value, onChange, component: Component, isLoading } = props
  const [_value, setValue] = useState<any>()
  const [isEdit, setEdit] = useState<boolean>(false)
  const [historicalValue, setHistoricalValue] = useState<any>()

  const parsedValue = useMemo(() => {
    if (value) {
      return parseFloat(value?.replace(/\./g, '').replace(',', '.'))
    }
    return ''
  }, [value])

  function handleChange(e: any) {
    setValue(e.target.value)
  }

  useEffect(() => {
    setHistoricalValue(parsedValue)
    setValue(parsedValue)
  }, [value])

  const valorAlterado = value != _value

  return (
    <Box
      onMouseEnter={() => setEdit(true)}
      onMouseLeave={() => setEdit(false)}
      sx={{ minWidth: '160px' }}
    >
      {isEdit ? (
        <Component
          autoFocus
          fullWidth
          onChange={handleChange}
          value={_value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {isLoading ? (
                    <CircularProgress size={16} />
                  ) : (
                    <>
                      <IconButton>
                        <Close
                          color="error"
                          onClick={() => {
                            setValue(historicalValue)
                            setEdit(false)
                          }}
                        />
                      </IconButton>
                      <IconButton
                        disabled={!valorAlterado}
                        onClick={() => {
                          if (value != _value) onChange(_value)
                        }}
                      >
                        <CheckCircle
                          color={valorAlterado ? 'primary' : 'disabled'}
                        />
                      </IconButton>
                    </>
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Typography sx={{ textAlign: 'right' }}>{value}</Typography>
      )}
    </Box>
  )
}
