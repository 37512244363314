import { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import { TransportableDataTableProps, usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useProdutos, {
  ProdutoFilters1,
  ProdutoModel,
} from 'hooks/queries/useProdutos'
import DataTableProps from 'interfaces/DataTableProps'
import { useFormApi } from '@data-c/providers'

interface TableProps
  extends DataTableProps<ProdutoModel, ProdutoFilters1>,
    TransportableDataTableProps<ProdutoModel> {}

export default function Table(props: TableProps) {
  const { onTransport } = props
  const { pagination, changePageSize, changePage } = usePagination()
  const { searchId, appliedValues } = useFilter<ProdutoFilters1>()
  const navigate = useNavigate()
  const { openForm, openConfirm } = useFormApi()
  const { useQueryObterProdutos } = useProdutos()

  const { categoriaValue, ...rest } = appliedValues || {}

  const { data, isLoading, isFetching, error } = useQueryObterProdutos({
    queryParams: {
      categoriaUuid: categoriaValue?.uuid || '',
      ...rest,
    },
    requestId: searchId,
    pagination,
  })

  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: ProdutoModel) => d.uuid === value)
    },
    [data],
  )

  const formattedData = data?.data.map((item) => {
    return {
      ...item,
      produtocls: item?.produtoClasse?.nome || '',
      produtofml: item?.familiaProduto?.nome || '',
      produtolnh: item?.linhaProduto?.nome || '',
      produtogrp: item?.grupoProduto?.nome || '',
    }
  })

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: ProdutoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/produtos/produto/${data.uuid}`)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [openForm, openConfirm, onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'produtolnh',
        label: 'Linha',
      },
      {
        name: 'produtogrp',
        label: 'Grupo',
      },
      {
        name: 'produtofml',
        label: 'Família',
      },
      {
        name: 'produtocls',
        label: 'Classe',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={formattedData || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
