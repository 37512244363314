import { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { MUIDataTableColumnDef } from 'mui-datatables'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import {
  DataTable,
  DataTableOptions as Options,
  Flag,
  OptionStyles,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { usePagination } from '@data-c/hooks'
import { useQuery as useQueryConfig } from 'hooks/queries/useConfiguracoes'
import useTabelaPreco, {
  TabelaPrecoModel,
  TabelaPrecoFilter1,
} from 'hooks/queries/useTabelaPreco'

export default function Table() {
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useTabelaPreco()

  const { appliedValues, searchId } = useFilter<TabelaPrecoFilter1>()
  const { data, isLoading, isFetching, error } = useQuery({
    pagination,
    queryParams: appliedValues,
    requestId: searchId,
  })
  const { data: configuracoes } = useQueryConfig()

  const { openConfirm } = useFormApi()
  const navigate = useNavigate()

  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: TabelaPrecoModel) => d.uuid === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'manutencao', data: TabelaPrecoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/tabelas-precos/tabela-preco/${data?.uuid}`)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'valorPonto',
        label: 'Valor do Ponto',
        options: {
          display: configuracoes?.usa_programa_fidelidade != 'DESATIVADO',
          setCellProps: () => ({
            style: {
              width: '96px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'percentualResgate',
        label: 'Fator de Resgate',
        options: {
          display: configuracoes?.usa_programa_fidelidade != 'DESATIVADO',
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'trocaPontos',
        label: 'Resgate de Pontos',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={data?.data || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
