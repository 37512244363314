import { Stack } from '@mui/material'
import AutoCompleteCidade from 'components/AutoCompleteCidade'
import { useUserContext } from 'components/Contexts/UserContext'
import DropDownUF from 'components/Inputs/DropDownUF'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { CidadeModel } from 'hooks/queries/useCidade'
import FilterForm from './FilterForm'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi<{
    plainQuery?: string
  }>()

  const { temPermissao } = useUserContext()
  const { appliedValues, filterValues } = useFilter()
  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Clientes"
        />

        <MioFilter.Chips>
          <MioFilter.Chip
            name="representante"
            label={appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="vendedorUuid"
              label="Vendedor"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              placeholder="Selecione um vendedor"
              InputLabelProps={{ shrink: true }}
            />
          </MioFilter.Chip>

          <MioFilter.Chip
            name="cidade"
            label={`${appliedValues?.cidade?.nome} - ${appliedValues?.cidade?.uf}`}
          >
            <Stack spacing={2}>
              <DropDownUF
                name="uf"
                label="Estado"
                value={filterValues?.uf || ''}
                onChange={(e) => {
                  const uf = e.target.value
                  changeFilterValue('uf', uf)
                  changeFilterValue('cidade', null)
                }}
              />

              <AutoCompleteCidade
                name="cidade"
                label="Cidade"
                value={filterValues?.cidade}
                uf={filterValues?.uf || null}
                onChange={(_, value: CidadeModel | null) => {
                  changeFilterValue('cidade', value)
                }}
              />
            </Stack>
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
