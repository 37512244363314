import { Button } from '@mui/material'
import { useFilterApi } from './FilterContext'

export default function ResetButton() {
  const { deleteAllFilterValues } = useFilterApi()

  return (
    <Button
      color="error"
      onClick={() => {
        if (deleteAllFilterValues) deleteAllFilterValues()
      }}
    >
      Resetar
    </Button>
  )
}
