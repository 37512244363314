import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { PedidoModel, useDeletePedido } from 'hooks/queries/usePedidos'
import { filters } from 'hooks/queries/usePedidos'
import Delete from 'components/Delete'
import MioFilter from 'components/MioCandidate/Filter'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Filter from './components/Filter'
import { useUserContext } from 'components/Contexts/UserContext'
import Form from './components/Form'
import { useNavigate } from 'react-router-dom'
import Table from './components/Table'
import Content from 'components/Layouts/Content'
export default function Pedidos() {
  const { mutateAsync: handleDelete } = useDeletePedido()
  const { temPermissao } = useUserContext()
  const podeCriarPedido = temPermissao('negociacoes.create') != 'sem_acesso'
  const navigate = useNavigate()

  return (
    <Container>
      <FormProvider>
        <MioFilter.Provider
          filterValues={{ dateRange: filters.dateRange?.value }}
        >
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Negociações',
                  link: '/pedidos/lista',
                },
              ]}
            />
            <Filter />
            {podeCriarPedido && (
              <Form
                fazDepoisDeIniciarOPedido={(pedido: PedidoModel) => {
                  navigate(`/pedidos/pedido/${pedido.uuid}`)
                }}
              />
            )}
          </ContentTitle.Root>

          <Content>
            <Table />
            <Delete onDelete={handleDelete} />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
