import { Stack } from '@mui/material'
import { ReactNode } from 'react'

export interface OnboardingCardRootProps {
  children?: ReactNode
}

export default function OnboardingCardRoot({
  children,
}: OnboardingCardRootProps) {
  return (
    <Stack
      spacing={1}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{
        minHeight: '20px',
        minWidth: '300px',
        // border: (theme) => `1px solid ${theme.palette.border}`,
        // borderRadius: '4px',
        // flexGrow: 1,
        // p: 2,
        // pl: 10,
        // pr: 10,
      }}
    >
      {children}
    </Stack>
  )
}
