import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
} from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import {
  RelatorioMetaVendasFilters,
  RelatorioMetaVendasFilters1,
} from 'hooks/queries/useRelatorioMetaVendas'
import RadioGroup from 'components/RadioGroup'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect, useMemo } from 'react'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { situacoes } from 'hooks/queries/usePedidos'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilterData<
    RelatorioMetaVendasFilters,
    RelatorioMetaVendasFilters1
  >()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <FilterContainer
      title="Pesquisar Meta de Vendas"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        if (filterLabel === 'Tipo Data') {
          if (filterValue === 'emissao') return 'Data de Faturamento'
          return 'Data de Venda'
        }

        if (filterLabel === 'Situação') {
          const label = filterValue.length === 1 ? 'Situação' : 'Situações'
          let values = ''
          if (Array.isArray(filterValue)) {
            values = filterValue.map((f: any) => f.label).toString()
          }
          return `${label} - ${values}`
        }

        if (filterLabel === 'Etiqueta') {
          const label = filterValue.length === 1 ? 'Etiqueta' : 'Etiquetas'
          let values = ''
          if (Array.isArray(filterValue)) {
            values = filterValue.map((f: any) => f.label).toString()
          }
          return `${label} - ${values}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <fieldset
          style={{
            border: '1px solid #cfcfcf',
            borderRadius: '4px',
          }}
        >
          <legend
            style={{
              color: '#808080',
              fontSize: '0.7rem',
              paddingLeft: '4px',
              paddingRight: '16px',
            }}
          >
            Período
          </legend>

          <Stack
            sx={{
              gap: 2,
              borderRadius: '4px',
            }}
          >
            <RadioGroup
              name="tipoData"
              value={filterValues?.tipoData?.value || 'venda'}
              defaultValue="venda"
              row
              onChange={(e) => {
                const key = e.target.value
                changeFilterValue('tipoData', key)
              }}
            >
              <FormControlLabel
                value="venda"
                label="Venda"
                control={<Radio />}
              />
              <FormControlLabel
                value="emissao"
                label="Faturamento"
                control={<Radio />}
              />
            </RadioGroup>
            <DateRangePicker
              renderFieldSet={false}
              onChange={(value) => {
                changeFilterValue('dateRange', value)
              }}
              value={filterValues?.dateRange?.value}
            />
          </Stack>
        </fieldset>
        <Autocomplete
          multiple
          options={situacoes}
          getOptionLabel={(option) => option?.label}
          value={filterValues?.situacao?.value || []}
          disableCloseOnSelect
          onChange={(_, value) => {
            changeFilterValue('situacao', value)
          }}
          renderInput={(params) => <TextField {...params} label="Situação" />}
        />
        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />
        <Autocomplete
          multiple
          options={formattedEtiquetas || []}
          getOptionLabel={(option) => option?.label}
          value={filterValues?.etiquetaKeyValue?.value || []}
          disableCloseOnSelect
          onChange={(_: any, value: any) =>
            changeFilterValue('etiquetaKeyValue', value)
          }
          renderInput={(params) => <TextField {...params} label="Etiquetas" />}
          loading={isLoadingEtiquetas}
          loadingText={<CircularProgress />}
        />
      </Stack>
    </FilterContainer>
  )
}
