import { MenuItem, Stack, TextFieldProps } from '@mui/material'
import TextField from 'components/Inputs/TextField'
import { useEffect, useState } from 'react'

import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import CategoriaInterface from 'interfaces/CategoriaInterface'

interface T {
  categoriaKey: 'nenhuma' | 'classe' | 'familia' | 'grupo' | 'linha'
  categoriaValue?: CategoriaInterface | null
}

type CategoriaProdutoTextFieldProps = {
  isLoading?: boolean
  onChange: (value: T) => void
  value?: T
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function CategoriaProdutoTextField({
  onChange,
  value,
}: CategoriaProdutoTextFieldProps) {
  const [categoriaKey, setCategoriaKey] = useState<
    'nenhuma' | 'classe' | 'familia' | 'grupo' | 'linha'
  >(value?.categoriaKey || 'nenhuma')
  const [categoriaValue, setCategoriaValue] = useState<
    CategoriaInterface | null | undefined
  >(value?.categoriaValue)

  useEffect(() => {
    onChange({
      categoriaKey,
      categoriaValue: categoriaValue,
    })
  }, [categoriaValue])

  return (
    <Stack
      sx={{
        gap: 2,
        border: (theme) => `solid 1px ${theme.palette.primary.main}`,
        padding: 2,
        borderRadius: '4px',
      }}
    >
      <TextField
        select
        name="tipoCategoria"
        label="Categorização"
        value={categoriaKey || 'nenhuma'}
        onChange={(e) => {
          const value = e.target.value || 'nenhuma'
          setCategoriaKey(
            value as 'nenhuma' | 'classe' | 'familia' | 'grupo' | 'linha',
          )
          setCategoriaValue(null)
        }}
      >
        <MenuItem value="nenhuma">Todas</MenuItem>
        <MenuItem value="classe">Classe</MenuItem>
        <MenuItem value="familia">Família</MenuItem>
        <MenuItem value="grupo">Grupo</MenuItem>
        <MenuItem value="linha">Linha</MenuItem>
      </TextField>

      {categoriaKey === 'classe' && (
        <ClasseProdutoTransporter
          value={categoriaValue || null}
          onChange={(value: ClasseProdutosModel | null) => {
            setCategoriaValue(value)
          }}
        />
      )}

      {categoriaKey === 'familia' && (
        <FamiliaProdutoTransporter
          value={categoriaValue || null}
          onChange={(value: FamiliaProdutosModel | null) => {
            setCategoriaValue(value)
          }}
        />
      )}

      {categoriaKey === 'grupo' && (
        <GrupoProdutoTransporter
          value={categoriaValue || null}
          onChange={(value: GrupoProdutosModel | null) => {
            setCategoriaValue(value)
          }}
        />
      )}

      {categoriaKey === 'linha' && (
        <LinhaProdutoTransporter
          value={categoriaValue || null}
          onChange={(value: LinhaProdutosModel | null) => {
            setCategoriaValue(value)
          }}
        />
      )}
    </Stack>
  )
}
