import { InputBase, InputBaseProps } from '@mui/material'
import _ from 'lodash'
import { ChangeEvent } from 'react'

export interface FilterSearchTextFieldProps
  extends Omit<InputBaseProps, 'onChange'> {
  onChange: (query: string) => void
}

export default function FilterSearchTextField(
  props: FilterSearchTextFieldProps,
) {
  const { onChange, ...rest } = props

  const debouncedHandleChange = _.debounce((query) => onChange(query), 500)

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    debouncedHandleChange(value)
  }

  return <InputBase onChange={handleChange} {...rest} />
}
