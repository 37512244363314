import { Autocomplete, Stack, TextField, MenuItem } from '@mui/material'
import AutoCompleteEtiquetas from 'components/AutocompleteEtiquetas'
import { useUserContext } from 'components/Contexts/UserContext'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import {
  OperacaoEnum,
  PedidoFilters1,
  situacoes,
} from 'hooks/queries/usePedidos'
import { useEffect } from 'react'

export default function FilterForm() {
  const { user, temPermissao } = useUserContext()
  const { filterValues, isOpen } = useFilter<PedidoFilters1>()
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <Stack spacing={1.5}>
      <DateRangePicker
        onChange={(value) => {
          changeFilterValue('dateRange', value)
        }}
        value={filterValues?.dateRange}
      />

      <TextField
        label="Código"
        name="codigo"
        value={filterValues?.codigo || ''}
        onChange={onChangeFilterValue}
      />

      <TextField
        name="carga"
        label="Núm. da Carga"
        value={filterValues?.carga || ''}
        onChange={onChangeFilterValue}
      />

      <AutoCompleteEtiquetas
        value={filterValues?.etiqueta || []}
        onChange={(_: any, value: any) => changeFilterValue('etiqueta', value)}
      />

      <Autocomplete
        multiple
        options={situacoes}
        getOptionLabel={(option) => option?.label}
        value={filterValues?.situacao || []}
        disableCloseOnSelect
        onChange={(_: any, value: any) => changeFilterValue('situacao', value)}
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{ inputLabel: { shrink: true } }}
            label="Situação"
          />
        )}
      />
      <TextField
        label="Operação"
        name="operacao"
        value={filterValues?.operacao}
        onChange={onChangeFilterValue}
        select
      >
        <MenuItem value={OperacaoEnum.VENDA}>Venda</MenuItem>
        <MenuItem value={OperacaoEnum.BONIFICACAO}>Bonificação</MenuItem>
      </TextField>

      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="vendedorUuid"
        label="Vendedor"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        slotProps={{ inputLabel: { shrink: true } }}
      />
      <ClientesTransporter
        label="Cliente"
        name="clienteUuid"
        value={filterValues?.cliente || null}
        vendedorUuid={
          permissaoParaLerClientes === 'pessoal' ? user?.uuid : undefined
        }
        onChange={(cliente) => {
          changeFilterValue('cliente', cliente)
        }}
        slotProps={{ inputLabel: { shrink: true } }}
      />
    </Stack>
  )
}
