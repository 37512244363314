import { TipoVariacaoEnum } from 'components/Inputs/DropDownTipoVariacao'
import { DescontoModel, MixProdutosModel } from 'hooks/queries/useMixProdutos'
import { ItemPedidoModel } from 'hooks/queries/usePedidos'
import ParseTo from 'utils/ParseTo'

export function calcularItemPedido(
  item: ItemPedidoModel,
  tipoCalculo:
    | 'precoUnitarioLiquido'
    | 'precoUnitarioIpi'
    | 'default' = 'default',
  fatorFinanceiro: number = 1,
  mixProduto?: MixProdutosModel | null,
): ItemPedidoModel {
  if (mixProduto && Array.isArray(mixProduto?.descontos)) {
    encontrarRegraDeDescontoPela(item, mixProduto)
  }
  let unitario = item.precoDeTabela
  // item.precoUnitarioLiquido = item.precoDeTabela

  if (item.fatorPrecificacao) {
    unitario =
      item.precoDeTabela * item.fatorPrecificacao.fator * fatorFinanceiro
    item.precoUnitarioLiquido = unitario
  }

  item.precoTotalBruto = (item?.quantidade || 0) * unitario
  /**
   * Se a alteração é o preço unitário líquido, encontra o percentual do desconto
   * Senão calcula o preço unitário líquido utilizando o percentual do desconto
   */
  if (tipoCalculo === 'precoUnitarioLiquido') {
    let auxPercentualDesconto =
      100 - (item.precoUnitarioLiquido / unitario) * 100
    item.percentualDesconto = auxPercentualDesconto
  } else {
    if ((item.percentualDesconto || 0) !== 0) {
      let auxValorDesconto = (unitario * (item.percentualDesconto || 0)) / 100
      item.precoUnitarioLiquido = unitario - auxValorDesconto
    }
  }

  /**
   * Se alterou o percentual do IPI deve achar o novo valor do IPI
   * Senão deve achar o percentual do
   */
  if (tipoCalculo === 'precoUnitarioIpi') {
    let auxPercentualIpi =
      (item.precoUnitarioIpi / item.precoUnitarioLiquido) * 100
    item.percentualIpi = auxPercentualIpi
  } else {
    let auxValorIpi =
      (item.precoUnitarioLiquido * (item.percentualIpi || 0)) / 100
    item.precoUnitarioIpi =
      item.precoUnitarioLiquido - (item.precoUnitarioLiquido - auxValorIpi)
  }

  item.precoTotalIpi = (item?.quantidade || 0) * item.precoUnitarioIpi
  item.precoTotalLiquido = (item?.quantidade || 0) * item.precoUnitarioLiquido
  item.precoTotalComImposto = item.precoTotalLiquido + item.precoTotalIpi

  return item
}

export function useCalcularItemPedido(
  item: ItemPedidoModel,
  tipoCalculo: 'precoUnitarioLiquido' | 'precoUnitarioIpi' | 'default',
  fatorFinanceiro: number = 1,
  mixProduto?: MixProdutosModel | null,
): ItemPedidoModel {
  return calcularItemPedido(item, tipoCalculo, fatorFinanceiro, mixProduto)
}

function encontrarRegraDeDescontoPela(
  item: ItemPedidoModel,
  mixProduto: MixProdutosModel,
): DescontoModel | null {
  const descontos = mixProduto.descontos as Array<DescontoModel>

  let toReturn: DescontoModel | null = null

  for (let i = descontos.length - 1; i >= 0; i--) {
    const quantidadeMinima = descontos[i].quantidadeMinima
    const fatorQuantidade =
      ParseTo.number(item?.quantidade || 0) +
      ParseTo.number(mixProduto?.quantidade || 0)
    if (fatorQuantidade >= quantidadeMinima) {
      toReturn = descontos[i]
      break
    }
  }
  item.percentualDesconto = toReturn?.pdesconto || 0
  item.descontoAplicado = toReturn
  return toReturn
}

export function getPeso(tipo: TipoVariacaoEnum): number {
  switch (tipo) {
    case TipoVariacaoEnum.NENHUMA:
      return 0
    case TipoVariacaoEnum.NIVEL_1:
      return 1
    case TipoVariacaoEnum.NIVEL_2:
      return 2
    default:
      return 0
  }
}

export function encontrarTipoVariacao(itens: ItemPedidoModel[]) {
  const encontrarMaiorPeso = (itensPedido: ItemPedidoModel[]): number => {
    return (
      itensPedido?.reduce((maiorPeso, i) => {
        const pesoAtual = getPeso(
          i?.produto?.tipoVariacao || TipoVariacaoEnum.NENHUMA,
        )
        return Math.max(maiorPeso, pesoAtual)
      }, 0) || 0
    )
  }
  return encontrarMaiorPeso(itens || [])
}
