import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@mui/material/styles'

const primaryColor = '#4BA9C1'
const primaryColorDark = '#2C96B3'

const textFieldHeight = 32

const themeOptions: ThemeOptions = {
  mixins: {
    toolbar: {
      height: 48,
    },
    menu: {
      width: 260,
    },
  },
  palette: {
    situacao: {
      aberto: '#FFFF00',
      fechado: '#FFA500',
      orcamento: '#800080',
      analise: '#0000FF',
      cancelado: '#FF0000',
      liberado: '#008000',
    },
    border: 'rgba(0, 0, 0, 0.23)',
    text: {
      primary: '#262626',
      secondary: '#808080',
    },
    // primary: {
    //   light: '#83EDFF',
    //   main: primaryColor, //#59C3E0
    //   dark: '#2C96B3',
    // },
    primary: {
      light: '#66D1E6', // um tom mais moderado e menos vibrante que ainda proporciona destaque
      main: primaryColor, // cor principal um pouco mais suave, mantendo a identidade
      dark: primaryColorDark, // mantém o tom mais escuro para contraste
    },
    secondary: {
      light: '#eceff1',
      main: '#13143F',
      dark: '#263238',
    },
    background: {
      default: '#FFF',
      paper: '#F4F4F4',
    },
    error: {
      main: '#F44336',
      dark: '#BE0D00',
      light: '#FF6C5F',
    },
    warning: {
      main: '#FFCD38',
      dark: '#C29000',
      light: '#FFED58',
    },
    success: {
      main: '#4CAF50',
      dark: '#187B1C',
      light: '#7DE081',
    },
    grey: {
      '800': '#202024',
      '700': '#262626',
      '600': '#333333',
      '500': '#808080',
      '400': '#BBBBBA',
      '300': '#E0E0E0',
      '200': '#F2F2F2',
      '100': '#FAFAFA',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Lato', 'sans-serif', 'Poppins'].join(','),
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.75rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '0.875rem',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      textTransform: 'none',
    },
    body1: {
      fontSize: '0.775rem',
      color: '#262626',
    },
    body2: {
      fontSize: '0.7685rem',
      color: '#808080',
    },
    subtitle1: {
      fontSize: '0.885rem',
      // color: '#808080',
    },
    subtitle2: {
      fontSize: '0.785rem',
      color: '#808080',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '30px',
          height: '30px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '30px',
          height: '30px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '& .MuiIcon-root': {
              color: primaryColor,
              borderRadius: '0px',
            },
            '& .MuiSvgIcon-root': {
              color: primaryColor,
            },
            '& .MuiTypography-root': {
              color: primaryColor,
            },
          },
          ':hover': {
            background: 'rgba(89, 195, 224, 15%)',
          },
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        // disableGutters: true,
        sx: {
          minHeight: '46px',
        },
      },
      styleOverrides: {
        root: {
          background: '#FFFFFF',
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          // background: '#a91',
          // padding: '0px',
          // margin: '0px',
          // paddingTop: '16px',
          // paddingBottom: '16px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontSize: '0.755rem',
          textTransform: 'none',
          fontWeight: 'bold',
          minWidth: '78px',
          // padding: '0px',
          // boxShadow: '2px 3px 4px -2px rgb(32 32 36 / 30%)',
          // ':disabled': {
          //   background: 'rgba(89 195 224 / 30%)',
          //   color: 'rgb(128 128 128 / 60%)',
          // },
        },
        contained: {
          color: 'rgba(255,255,255,0.85)',
          boxShadow: 'none',
        },
        outlined: {
          background: 'white',
          ':disabled': {
            background: 'none',
            boxShadow: 'none',
            borderColor: '#E0E0E0',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: `${textFieldHeight}px`,
          fontSize: '0.9rem',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          '& label': {
            color: '#808080',
            fontSize: '0.8rem',
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        inputRoot: {
          height: 'auto',
        },
        root: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
            {
              padding: 0,
            },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
        color: 'inherit',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // boxShadow: '2px 3px 4px -2px rgb(32 32 36 / 30%)',
          // backgroundColor: primaryColor,
          // '&:hover': { backgroundColor: '#2C96B3' },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '& .MuiIcon-root': {
              color: primaryColor,
            },
            '& .MuiTypography-root': {
              color: primaryColor,
            },
          },
          ':hover': {
            borderRadius: '6px',
            background: 'rgba(89, 195, 224, 15%)',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-anchorOriginBottomLeft': {
            height: '16px',
            top: 8,
          },
        },
        badge: {
          fontSize: '8pt',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          padding: 0,
          '&.selected': {
            backgroundColor: '#199',
          },
          // '&:hover': {
          //   backgroundColor: '#333',
          // },
        },
        head: {
          backgroundColor: '#FAFAFA',
          border: '1px solid #D0D0D0',
          color: '#808080',
          '& span': {
            color: '#808080',
          },
          '& div': {
            marginLeft: '4px',
          },
        },
        body: {
          backgroundColor: '#ffffff00',
          border: '1px solid #D0D0D0',
          borderTop: 'none',
          borderBottom: 'none',
          '& > div': {
            fontSize: '9pt',
            paddingLeft: '6px',
            paddingRight: '6px',
            paddingTop: '2px',
            paddingBottom: '2px',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          height: '30px',
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: '#ffffde',
            },
          },
        },

        head: {
          borderRadius: '4px',
          '& div': {
            fontWeight: '600',
            fontSize: '13px',
            padding: '2px',
          },
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        toolButton: {
          height: '48px',
        },
        // sortActive: {
        //   color: primaryContrastText,
        // },
        // sortAction: {
        //   '& path': {
        //     color: primaryContrastText,
        //   },
        // },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#333',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#F5F5F5',
          },
          '&:last-child': {
            borderBottom: '1px solid #D0D0D0',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: '48px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '6px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // backgroundColor: '#454',
        },
      },
    },
  },
}

const preparedTheme = createTheme(themeOptions)
const theme = responsiveFontSizes(preparedTheme)

export default theme
