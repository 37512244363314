import { useState } from 'react'

import Transporter, {
  TransporterProps,
} from 'components/MioCandidate/Transporter'

import Table from './components/Table'
// import Filter from './components/Filter'
import { useQueryFichaClienteAsPropduto } from 'hooks/queries/useRelatorioFichaCliente'
import { ProdutoTransporter } from '../ItensTabelaPrecoTransporter'

type FichaClienteTransporterProps = {
  clienteUuid: string | null
  tabelaPrecoUuid: string | null
  dataInicio?: string | null
} & Omit<
  TransporterProps<ProdutoTransporter>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function FichaClienteTransporter(
  props: FichaClienteTransporterProps,
) {
  const { value, clienteUuid, tabelaPrecoUuid, ...rest } = props
  const [query, setQuery] = useState<string | null>()

  const { data, isLoading, error } = useQueryFichaClienteAsPropduto({
    plainQuery: query,
    clienteUuid: clienteUuid || '',
    tabelaPrecoUuid: tabelaPrecoUuid || '',
    dataInicio: null,
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  const relatorio = data || []

  return (
    <Transporter
      dialogProps={{
        title: 'Ficha do Cliente',
        maxWidth: 'lg',
      }}
      onSearch={handleSearch}
      options={relatorio || []}
      isLoading={isLoading}
      table={Table}
      tableProps={{
        clienteUuid,
        tabelaPrecoUuid,
        dataInicio: null,
      }}
      // filter={Filter}
      renderValue={(value) => {
        return `${value?.produto?.codigo || ''} - ${value?.produto?.nome}`
      }}
      value={value}
      error={Boolean(error)}
      helperText={
        Boolean(error) &&
        (error?.response?.data?.message ||
          'Ocorreu um erro ao tentar processar a requisição')
      }
      // helperText={'Ocorreu um erro ao tentar processar a requisição'}
      {...rest}
    />
  )
}
