import {
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

export interface BreadcrumbsItem {
  link: string
  label: string
}

export interface BreadcrumbsProps {
  links: Array<BreadcrumbsItem>
}

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const { links } = props
  const navigate = useNavigate()
  const location = useLocation()
  function handleClick(link: string, index: number) {
    if (link === location.pathname) return
    navigate((index + 1) * -1)
  }

  return (
    <MuiBreadcrumbs sx={{ height: '30px', display: 'flex' }}>
      {links.map((l, i) => {
        const currentPage = links.length === 1 || links.length === i + 1
        if (currentPage) {
          return (
            <Typography
              sx={{ fontSize: '10pt', fontWeight: 'bolder' }}
              variant="subtitle1"
              key={l.link}
            >
              {l.label}
            </Typography>
          )
        }

        return (
          <Button
            key={l.link}
            variant="text"
            onClick={() => handleClick(l.link, i)}
          >
            {l.label}
          </Button>
        )
      })}
    </MuiBreadcrumbs>
  )
}
