import { Typography } from '@mui/material'
import { ReactNode } from 'react'

export interface OnboardingCardPrimaryMessageProps {
  value: string | ReactNode
}

export default function OnboardingCardPrimaryMessage(
  props: OnboardingCardPrimaryMessageProps,
) {
  const { value } = props
  return (
    <Typography style={{ textAlign: 'center' }} variant="h4">
      {value}
    </Typography>
  )
}
