import {
  // draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import invariant from 'tiny-invariant'
import { useEffect, useRef, useState } from 'react'
import {
  Button,
  IconButton,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material'
import { useBoardContext } from '../BoardContext'
import useFunilEtapa, { EtapaModel } from 'hooks/queries/useFunilEtapa'
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element'
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box'
import Form from 'pages/Pedidos/components/Form'
import FunilKanbanCard from '../FunilKanbanCard'
import { PedidoModel } from 'hooks/queries/usePedidos'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { Add, Delete } from '@mui/icons-material'
import { Editable } from '@data-c/ui'
import useNotification from 'hooks/useNotifications'
import { useUserContext } from 'components/Contexts/UserContext'
export interface FunilKanbanColumnProps {
  etapa: EtapaModel
  onClickCard?: (negociacao: PedidoModel) => void
}

type State =
  | { type: 'idle' }
  | { type: 'is-card-over' }
  | { type: 'is-column-over'; closestEdge: Edge | null }
  | { type: 'generate-safari-column-preview'; container: HTMLElement }
  | { type: 'generate-column-preview' }

interface ColumnContainerProps extends StackProps {
  state: State
}

const ColumnContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop != '',
})<ColumnContainerProps>(({ state, theme }) => () => {
  return {
    position: 'relative',
    width: '280px',
    borderRadius: '4px',
    // backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    // overflowY: 'auto',
    ...(state.type === 'is-card-over' && {
      backgroundColor: '#00000022',
    }),
  }
})

export default function FunilKanbanColumn(props: FunilKanbanColumnProps) {
  const { etapa, onClickCard } = props
  const { temPermissao } = useUserContext()
  const idle: State = { type: 'idle' }
  const isCardOver: State = { type: 'is-card-over' }
  const [state, setState] = useState<State>(idle)
  const [isHovering, setHovering] = useState<boolean>(false)
  const columnRef = useRef<HTMLDivElement | null>(null)
  const headerRef = useRef<HTMLDivElement | null>(null)
  const cardListRef = useRef<HTMLDivElement | null>(null)
  const scrollableRef = useRef<HTMLDivElement | null>(null)
  const columnId = etapa.uuid || ''
  const { instanceId, registerColumn, addNewCard, removeColumn } =
    useBoardContext()
  const notifications = useNotification()
  const { useSubmit } = useFunilEtapa(etapa.funilVendaUuid || '')
  const {
    mutateAsync: atualizarEtapa,
    isLoading: atualizandoEtapa,
    error,
  } = useSubmit()
  useEffect(() => {
    invariant(columnRef.current)
    invariant(headerRef.current)
    invariant(cardListRef.current)
    invariant(scrollableRef.current)
    return combine(
      registerColumn({
        columnId,
        entry: {
          element: columnRef.current,
        },
      }),
      draggable({
        element: columnRef.current,
        dragHandle: headerRef.current,
        getInitialData: () => ({
          columnId: columnId,
          type: 'column',
          instanceId,
        }),
      }),
      dropTargetForElements({
        element: columnRef.current,
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId &&
            source.data.type === 'column' &&
            temPermissao('crm_funil_venda.alter')
          )
        },
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          const data = {
            columnId,
          }
          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['left', 'right'],
          })
        },
        onDragEnter: (args) => {
          setState({
            type: 'is-column-over',
            closestEdge: extractClosestEdge(args.self.data),
          })
        },
        onDrag: (args) => {
          // skip react re-render if edge is not changing
          setState((current) => {
            const closestEdge: Edge | null = extractClosestEdge(args.self.data)
            if (
              current.type === 'is-column-over' &&
              current.closestEdge === closestEdge
            ) {
              return current
            }
            return {
              type: 'is-column-over',
              closestEdge,
            }
          })
        },
        onDragLeave: () => {
          setState(idle)
        },
        onDrop: () => {
          setState(idle)
        },
      }),
      dropTargetForElements({
        element: cardListRef.current,
        getData: () => ({ columnId }),
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId && source.data.type === 'card'
          )
        },
        getIsSticky: () => true,
        onDragEnter: () => setState(isCardOver),
        onDragLeave: () => {
          setState(idle)
          setHovering(false)
        },
        onDragStart: () => setState(isCardOver),
        onDrop: () => setState(idle),
      }),
      autoScrollForElements({
        element: scrollableRef.current,
        canScroll: ({ source }) =>
          source.data.instanceId === instanceId && source.data.type === 'card',
      }),
    )
  }, [columnId, registerColumn, instanceId])

  function adicionarPedido(pedido: PedidoModel) {
    addNewCard({ pedido })
  }

  function handleClickDelete(etapa: EtapaModel) {
    removeColumn({ etapa })
  }

  async function handleChangeNomeEtapa(novoNome?: string) {
    try {
      await atualizarEtapa({
        ...etapa,
        nome: novoNome || '',
      })
    } catch (err) {}
  }

  return (
    <ColumnContainer
      ref={columnRef}
      state={state}
      onMouseEnter={(e) => {
        e.stopPropagation()
        setHovering(true)
      }}
      onMouseLeave={() => {
        setHovering(false)
      }}
    >
      {/* <Divider /> */}
      <Stack
        ref={cardListRef}
        spacing={1}
        sx={{
          borderRadius: '4px',
          overflowY: 'auto',
          // flexGrow: 1,
          // height: 'calc(100vh - 1000px)',
          background: (theme) => theme.palette.background.paper,
          p: 1,
        }}
      >
        <Stack
          ref={headerRef}
          direction="row"
          justifyContent="space-between"
          sx={{
            height: '30px',
            cursor: temPermissao('crm_funil_venda.alter') ? 'grab' : 'inherite',
            background: (theme) => theme.palette.background.paper,
          }}
        >
          {temPermissao('crm_funil_venda.alter') ? (
            <Editable
              onChange={handleChangeNomeEtapa}
              error={notifications.extractAxiosError(error)}
              isLoading={atualizandoEtapa}
              value={etapa?.nome || ''}
              typographyProps={{
                variant: 'subtitle1',
              }}
            />
          ) : (
            <Typography variant="subtitle1">{etapa?.nome || ''}</Typography>
          )}

          {isHovering && temPermissao('crm_funil_venda.delete') && (
            <IconButton onClick={() => handleClickDelete(etapa)}>
              <Delete />
            </IconButton>
          )}
        </Stack>

        <Stack spacing={1} ref={scrollableRef}>
          {etapa.negociacoes.map((n) => (
            <FunilKanbanCard
              key={n.uuid}
              negociacao={n}
              onClick={onClickCard}
            />
          ))}
          {isHovering && state.type !== 'is-card-over' ? (
            <Form
              etapa={etapa}
              fazDepoisDeIniciarOPedido={(pedido: PedidoModel) => {
                adicionarPedido(pedido)
              }}
              triggerButtonLabel="Adicionar Negociação"
              triggerButtonProps={{
                variant: 'text',
                startIcon: <Add />,
                sx: {
                  justifyContent: 'flex-start',
                },
              }}
            />
          ) : (
            <Button
              startIcon={<Add />}
              sx={{
                justifyContent: 'flex-start',
              }}
              variant="text"
            >
              Adicionar Negociação
            </Button>
          )}
        </Stack>
      </Stack>
      {state.type === 'is-column-over' && state.closestEdge && (
        <DropIndicator edge={state.closestEdge} gap={'8px'} />
      )}
    </ColumnContainer>
  )
}
