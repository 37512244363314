import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import {
  FatorPrecificacaoModel,
  useQueryObterPorProdutoVendedor,
} from 'hooks/queries/useFatorPrecificacao'
import { useEffect } from 'react'

type DropDownFatorPrecificacao1Props = {
  produtoUuid: string
  value: FatorPrecificacaoModel | null
  onChange: (fator?: FatorPrecificacaoModel | null) => void
  onLoad?: (fator?: FatorPrecificacaoModel | null) => void
} & TextFieldProps

export default function DropDownFatorPrecificacao1(
  props: DropDownFatorPrecificacao1Props,
) {
  const { produtoUuid, value, onChange, onLoad, ...rest } = props

  const { data } = useQueryObterPorProdutoVendedor(produtoUuid)
  const sortedData = data?.sort((a, b) => (a?.id || 0) - (b?.id || 0)) || []

  useEffect(() => {
    if (sortedData.length > 0 && onLoad) {
      onLoad(sortedData[0])
    }
  }, [sortedData])

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const selectedUuid = e.target.value
    const selectedValue = sortedData.find((f) => f.uuid === selectedUuid)
    onChange(selectedValue)
  }

  return (
    <TextField
      select
      value={value?.uuid || ''}
      onChange={(e) => handleChange(e)}
      {...rest}
    >
      {sortedData?.map((item) => {
        return (
          <MenuItem key={item.uuid} value={item?.uuid}>
            {item?.descricao}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
