import { Box, Stack } from '@mui/material'
import AutoCompleteCidade from 'components/AutoCompleteCidade'

import { useUserContext } from 'components/Contexts/UserContext'
import DropDownUF from 'components/Inputs/DropDownUF'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { CidadeModel } from 'hooks/queries/useCidade'
import { useEffect } from 'react'

export default function FilterForm() {
  const { user, temPermissao } = useUserContext()
  const { filterValues, isOpen } = useFilter()
  const { changeFilterValue } = useFilterApi()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <Stack spacing={2}>
      <TimeVendasTransporter
        disabled={temPermissao('clientes.read') === 'pessoal'}
        name="vendedorUuid"
        label="Vendedor"
        value={filterValues?.representante || null}
        onChange={(representante) => {
          changeFilterValue('representante', representante)
        }}
        placeholder="Selecione um vendedor"
        InputLabelProps={{ shrink: true }}
      />

      <Stack direction="row" spacing={2}>
        <Box sx={{ width: '200px' }}>
          <DropDownUF
            name="uf"
            label="Estado"
            value={filterValues?.uf || ''}
            onChange={(e) => {
              const uf = e.target.value
              changeFilterValue('uf', uf)
              changeFilterValue('cidade', null)
            }}
          />
        </Box>
        <AutoCompleteCidade
          name="cidade"
          label="Cidade"
          value={filterValues?.cidade}
          uf={filterValues?.uf || null}
          onChange={(_, value: CidadeModel | null) => {
            changeFilterValue('cidade', value)
          }}
        />
      </Stack>
    </Stack>
  )
}
