import { Container } from '@mui/material'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import MioFilter from 'components/MioCandidate/Filter'
import { filters } from 'hooks/queries/usePedidos'
import Filter from './components/Filter'
import Content from 'components/Layouts/Content'
import Pipeline from './components/Pipeline'
import { FormProvider } from '@data-c/providers'
import { useEffect, useState } from 'react'
import FunilKanbanSelectionButton from './components/Pipeline/components/FunilKanbanSelectionButton'
import { FunilVendaModel } from 'hooks/queries/useFunilVenda'

export default function CrmFunilVenda() {
  const STORAGE_KEY = 'app-fdv:funilSelecionado'
  const [funilSelecionado, setFunilSelecionado] = useState<
    FunilVendaModel | undefined
  >(() => {
    const f = window.localStorage.getItem(STORAGE_KEY)
    return f ? JSON.parse(f) : undefined
  })

  useEffect(() => {
    if (funilSelecionado)
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(funilSelecionado))
  }, [funilSelecionado])

  return (
    <Container>
      <FormProvider>
        <MioFilter.Provider
          filterValues={{ dateRange: filters.dateRange?.value }}
        >
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Funil de Vendas',
                  link: '/funil-venda',
                },
              ]}
            />
            <Filter />
            <FunilKanbanSelectionButton
              value={funilSelecionado}
              onChange={(funil) => {
                setFunilSelecionado(funil)
              }}
            />
          </ContentTitle.Root>
          <Content>
            <Pipeline
              funilVendasUuid={funilSelecionado?.uuid || ''}
              onSelectFunil={(funil: FunilVendaModel) =>
                setFunilSelecionado(funil)
              }
            />
          </Content>
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
