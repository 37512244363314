import { ButtonContainer, Dialog, FormContainer } from '@data-c/ui'
import { FilterProvider, useFormApi, useFormData } from '@data-c/providers'

import {
  PedidoModel,
  SituacaoPedidoEnum,
  ajustesMap,
  useSubmitIniciarPedido,
} from 'hooks/queries/usePedidos'
import { ClientesModel, filtersDuplicatas } from 'hooks/queries/useClientes'

import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { TimeVendasModel } from 'hooks/queries/useTimeVendas'
import {
  Alert,
  Button,
  ButtonProps,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import useValidations from 'hooks/useValidations'
import { EtapaModel } from 'hooks/queries/useFunilEtapa'
import { useQuery as useConfiguracoes } from 'hooks/queries/useConfiguracoes'
import DialogObsCliente from './components/DialogObsCliente'
import useNotification from 'hooks/useNotifications'
import useDialog from 'hooks/useDialog'
import DuplicatasTable from 'components/Tables/DuplicatasTable'
interface IniciarPedidoDataProps {
  representante?: TimeVendasModel | null
  cliente?: ClientesModel | null
  situacao?: SituacaoPedidoEnum | null
  etapaModelUuid?: string
}

const schema = yup.object().shape({
  vendedorUuid: yup.string().required('Informe o Vendedor'),
  clienteUuid: yup.string().required('Informe o Cliente'),
})

export interface FormProps {
  etapa?: EtapaModel
  fazDepoisDeIniciarOPedido?: (pedidoIniciado: PedidoModel) => void
  triggerButtonProps?: ButtonProps
  triggerButtonLabel?: string
}

export default function Form(props: FormProps) {
  const {
    etapa,
    fazDepoisDeIniciarOPedido,
    triggerButtonProps,
    triggerButtonLabel = 'Adicionar',
  } = props
  const { data: configuracoes } = useConfiguracoes()
  const { extractAxiosErrorData, notifyException } = useNotification()
  const { changeValue, toggleSubmit, onChangeFormValue, closeForm } =
    useFormApi<IniciarPedidoDataProps>()
  const { setValidationErrors, validationProps } = useValidations()
  const { formValues: data, isOpen } = useFormData<IniciarPedidoDataProps>()

  const { mutateAsync: iniciarPedido } = useSubmitIniciarPedido()
  const { user, temPermissao } = useUserContext()

  const [openDialogObsCliente, setOpenDialogObsCliente] = useState(false)
  const {
    isOpen: isError,
    openDialog: showError,
    closeDialog: clearError,
    data: errorMsg,
  } = useDialog<string>()

  const {
    isOpen: isOpenDuplicata,
    openDialog: openDuplicata,
    closeDialog: closeDuplicata,
    // data: {},
  } = useDialog<string>()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeValue('representante', user)
    }
    if (isOpen) {
      clearError()
    }
  }, [permissaoParaLerClientes, isOpen])

  function handleSubmitForm() {
    const newData = {
      vendedorUuid: data.representante?.uuid,
      clienteUuid: data.cliente?.uuid,
      situacao: data.situacao,
      funilVendaEtapaUuid: etapa?.uuid,
      ajuste: 100,
    }

    if (Boolean(configuracoes?.usa_ajuste)) {
      newData.ajuste = ajustesMap[configuracoes?.ajuste || 1]
    }

    schema
      .validate(newData, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)

        try {
          const pedido = await iniciarPedido({
            vendedor: newData?.vendedorUuid || '',
            pessoa: newData?.clienteUuid || '',
            situacao: newData?.situacao || SituacaoPedidoEnum.WEB,
            iscotacao:
              newData?.situacao === SituacaoPedidoEnum.COTACAO_SOLICITACAO
                ? true
                : false,
            funilVendaEtapaUuid: newData?.funilVendaEtapaUuid,
            ajuste: newData.ajuste,
          })

          if (fazDepoisDeIniciarOPedido) {
            fazDepoisDeIniciarOPedido(pedido)
          }
          closeForm()
        } catch (err) {
          const errorData = extractAxiosErrorData(err)
          if (errorData?.code === 'E_CLIENTE_INADIMPLENTE') {
            showError(errorData.message)
            return
          }
          notifyException(err)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  filtersDuplicatas.apenasAbertas = {
    label: 'Apenas abertas',
    value: true,
  }
  return (
    <FormContainer
      dialogProps={{ title: 'Iniciar Negociação' }}
      onSubmitForm={handleSubmitForm}
      confirmButtonLabel="Iniciar"
      triggerButtonLabel={triggerButtonLabel}
      triggerButtonProps={triggerButtonProps}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TimeVendasTransporter
            name="vendedorUuid"
            label="Vendedor"
            value={data?.representante || null}
            onChange={(representante) => {
              changeValue('representante', representante)
            }}
            placeholder="Selecione um vendedor"
            InputLabelProps={{ shrink: true }}
            {...validationProps('vendedorUuid')}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ClientesTransporter
            label="Cliente"
            name="clienteUuid"
            value={data?.cliente || null}
            vendedorUuid={data?.representante?.uuid || ''}
            onChange={(cliente) => {
              changeValue('cliente', cliente)
              if (cliente?.observacao && cliente.observacao !== '') {
                setOpenDialogObsCliente(true)
              }
            }}
            slotProps={{ inputLabel: { shrink: true } }}
            {...validationProps('clienteUuid')}
          />
        </Grid>
        <DialogObsCliente
          openDialog={openDialogObsCliente}
          setOpenDialog={setOpenDialogObsCliente}
          observacao={data?.cliente?.observacao!}
          onSubmit={handleSubmitForm}
        />
        {/* <DialogClienteInadimplente
          open={isOpenErrorDialog}
          onClose={closeDialog}
          msg={errorMsg || ''}
        /> */}

        {user?.isadmin && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2">Quero iniciar um(a):</Typography>
              <FormControl>
                <RadioGroup
                  row
                  name="situacao"
                  value={data?.situacao || 'N'}
                  onChange={onChangeFormValue}
                >
                  <FormControlLabel
                    value="N"
                    control={<Radio />}
                    label="Pedido"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="T"
                    control={<Radio />}
                    label="Cotação"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
        )}
      </Grid>

      {isError && (
        <Stack spacing={1}>
          <Alert severity="error">{errorMsg}</Alert>
          <ButtonContainer>
            <Button
              onClick={() => openDuplicata()}
              color="error"
              variant="contained"
            >
              ver duplicatas
            </Button>
          </ButtonContainer>
          <Dialog
            maxWidth="lg"
            title="Duplicatas do Cliente"
            open={isOpenDuplicata}
            onClose={closeDuplicata}
            onConfirm={closeDuplicata}
          >
            <FilterProvider filterValues={filtersDuplicatas}>
              <DuplicatasTable pessoaUuid={data?.cliente?.uuid || ''} />
            </FilterProvider>
          </Dialog>
        </Stack>
      )}
    </FormContainer>
  )
}
