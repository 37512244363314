import OnboardingCardRoot from './OnboardingCardRoot'
import OnboardingCardPrimaryMessage from './OnboardingCardPrimaryMessage'
import OnboardingCardSecondaryMessage from './OnboardingCardSecondaryMessage'
import OnboardingCardImage from './OnboardingCardImage'
import OnboardingCardActionsContainer from './OnboardingCardActionsContainer'

const OnboardingCard = {
  Root: OnboardingCardRoot,
  PrimaryMessage: OnboardingCardPrimaryMessage,
  SecondaryMessage: OnboardingCardSecondaryMessage,
  Image: OnboardingCardImage,
  ActionsContainer: OnboardingCardActionsContainer,
}

export default OnboardingCard
