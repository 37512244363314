import { Checkbox } from '@data-c/ui'
import { Stack } from '@mui/material'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import { FatorPrecificacaoModel } from 'hooks/queries/useFatorPrecificacao'

export default function FilterForm() {
  const { filterValues, isOpen } = useFilter<FatorPrecificacaoModel>()
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()
  return (
    <Stack spacing={1.5}>
      <Checkbox
        // type="checkbox"
        name="ativo"
        label="Somente ativos"
        checked={Boolean(filterValues.ativo)}
        onChange={onChangeFilterValue}
      />
    </Stack>
  )
}
