import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'

import Delete from 'components/Delete'
import Form from './components/Form'
import Content from 'components/Layouts/Content'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import Table from './components/Table'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'

export default function Etiquetas() {
  const { useDelete } = useEtiqueta()
  const { mutateAsync: handleDelete } = useDelete()
  return (
    <Container>
      <FormProvider initialFormValues={{  }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Etiquetas',
                link: '/etiquetas/lista',
              },
            ]}
          />

          <Form />
        </ContentTitle.Root>

        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
