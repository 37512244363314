import {
  PlainQueryProps,
  HttpResponseInterface,
  HttpRequestInterface,
} from '@data-c/hooks'
import { FilterOptions } from '@data-c/providers'
import useCrud, {
  generateEntityKey,
  read,
  RequestInterface,
} from 'hooks/useCrud'
import { AxiosError } from 'axios'
import { ClasseProdutosModel } from './useClasseProdutos'
import { FamiliaProdutosModel } from './useFamiliaProdutos'
import { GrupoProdutosModel } from './useGrupoProdutos'
import { LinhaProdutosModel } from './useLinhaProdutos'
import { TipoVariacaoEnum } from 'components/Inputs/DropDownTipoVariacao'
import CategoriaInterface from 'interfaces/CategoriaInterface'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useNotification from 'hooks/useNotifications'
import api from 'services/api'

export interface ProdutoModel {
  uuid: string
  nome: string
  codigo: string
  descricao: string
  unidade: string
  fatorunidade?: number
  fracionado: boolean
  ativo: boolean
  produtoClasse?: ClasseProdutosModel | null
  produtocls?: string | null
  produtofml?: string | null
  familiaProduto?: FamiliaProdutosModel | null
  produtogrp?: string | null
  grupoProduto?: GrupoProdutosModel | null
  produtolnh?: string | null
  linhaProduto?: LinhaProdutosModel | null
  pesobruto?: number
  pesoliquido?: number
  pDescontomax?: number
  pAcrescimomax?: number
  pontos?: number
  produtochave?: string
  pbonificacao?: number
  valorMinimo?: number
  quantidadeMinima?: number
  percentualAcrescimo?: number
  fracionadoWeb?: number
  tipoVariacao?: TipoVariacaoEnum
  fotos: Array<{
    uuid: string
    fileName: string
  }>
}

export interface ProdutoFilters {
  nome: FilterOptions<string>
  codigo: FilterOptions<string>
  apenasQuantidadeMinima: FilterOptions<boolean>
  categoriaKey?: FilterOptions<
    'nenhuma' | 'classe' | 'grupo' | 'linha' | 'familia'
  >
  categoriaValue?: FilterOptions<CategoriaInterface>
}

export interface ProdutoFilters1 {
  nome: string
  codigo: string
  categoriaKey?: 'nenhuma' | 'classe' | 'grupo' | 'linha' | 'familia'
  categoriaValue?: CategoriaInterface
  categoriaUuid?: string
  plainQuery?: string
}

export const filters: ProdutoFilters = {
  nome: {
    label: 'Nome',
  },
  codigo: {
    label: 'Código',
  },
  apenasQuantidadeMinima: {
    label: 'qtdMinima',
    value: false,
  },
  categoriaKey: {
    label: 'Tipo de Categoria',
    value: 'nenhuma',
  },
  categoriaValue: {
    label: 'Categoria',
  },
}

export async function obterProdutos(
  params: HttpRequestInterface<PlainQueryProps>,
): Promise<HttpResponseInterface<ProdutoModel>> {
  const { pagination: _pagination, queryParams } = params

  const response: RequestInterface<PlainQueryProps> = {
    route: 'administrador/produtos',
    pagination: _pagination,
    queryParams,
  }

  return read<PlainQueryProps, ProdutoModel>(response)
}

export function useQueryObterProdutos(
  params: HttpRequestInterface<PlainQueryProps>,
) {
  return useQuery<HttpResponseInterface<ProdutoModel>, AxiosError>(
    ['PRODUTO', params],
    () => obterProdutos(params),
  )
}

export async function removerFoto(uuid: string): Promise<void> {
  await api.delete(`administrador/produtos/remover-photo/${uuid}`)
}

export function useRemoverFoto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<void, AxiosError, string>(
    (uuid: string) => removerFoto(uuid),
    {
      onSuccess() {
        const key = generateEntityKey('Produto')
        notifications.notifySuccess(`Foto removida com sucesso`)
        queryClient.invalidateQueries([key])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useProdutos() {
  return {
    ...useCrud<ProdutoModel, AxiosError>('administrador/produtos', 'Produto'),
    useQueryObterProdutos,
    useRemoverFoto,
  }
}
