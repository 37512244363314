import { FilterOptions } from '@data-c/providers'
import { AxiosError } from 'axios'
import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import api from 'services/api'
import { CidadeModel } from './useCidade'
import useCrud from 'hooks/useCrud'
import {
  HttpResponseInterface,
  PaginationProps,
  PlainQueryProps,
} from '@data-c/hooks'
import ErrorInterface from 'interfaces/ErrorInterface'
import { VendedorModel } from './useVendedores'
import useNotification from 'hooks/useNotifications'
import { SortProps } from 'interfaces/SortProps'
import { CondicaoPagamentoModel } from './useCondicaoPagamento'
import { TimeVendasModel } from './useTimeVendas'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import { PedidoModel } from './usePedidos'

export interface DuplicataFilter {
  tipoData: FilterOptions<'emissao' | 'vencimento' | 'pagamento'>
  dateRange: FilterOptions<DateRange>
  dataInicial?: FilterOptions<string>
  dataFinal?: FilterOptions<string>
  apenasAbertas?: FilterOptions<boolean>
}

export interface DuplicataFilter1 {
  tipoData?: 'emissao' | 'vencimento' | 'pagamento'
  dateRange?: DateRange
  dataInicial?: string
  dataFinal?: string
  apenasAbertas?: boolean
}

export interface LiberarInadimplenciaInputProps {
  dataLimiteIgnorarInadimplencia: string
  horaLimiteIgnorarInadimplencia: string
  clienteUuid: string
}

export interface PontosModel {
  pedidoUuid: string
  pedido: PedidoModel
  pontos: number
  situacao: string
  situacaoAsText: string
}

export interface DuplicataModel {
  documento: string
  emissao: string
  pagamento: string
  vencimento: string
  pessoa: string
  total: number
  multa?: number
  mora?: number
  vendedor?: string
  emissaoAsBrazilianDate?: string
  vencimentoAsBrazilianDate?: string
  pagamentoAsBrazilianDate?: string
  totalAsBrazilianCurrency?: string
}

export interface ClienteFilters {
  plainQuery: FilterOptions<string>
  situacao: FilterOptions<string>
  cnpjcpf: FilterOptions<string>
  vendedorUuid: FilterOptions<string>
  representante: FilterOptions<TimeVendasModel>
}

export interface ClienteFilters1 {
  plainQuery: string
  situacao: string
  cnpjcpf: string
  representante?: TimeVendasModel
  cidade?: CidadeModel
  vendedorUuid?: string
}

export const filtersDuplicatas: DuplicataFilter = {
  tipoData: {
    label: 'Tipo Data',
    value: 'vencimento',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'year',
      firstDate: DateTime.now().startOf('year').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  apenasAbertas: {
    label: 'Apenas abertas',
    value: false,
  },
}

export enum SituacaoClienteEnum {
  TODAS = 'TODAS',
  CLIENTE = 'CLIENTE',
  LEAD = 'LEAD',
}

export enum VerificacaoSPCEnum {
  TODAS = 'TODAS',
  APROVADOS = 'APROVADOS',
  PENDENTES = 'PENDENTES',
}

export const filters: ClienteFilters = {
  plainQuery: {
    label: 'Código / Nome',
  },
  cnpjcpf: {
    label: 'CNPJ / CPF',
  },
  situacao: {
    label: 'Situação',
    value: SituacaoClienteEnum.TODAS,
  },
  representante: {
    label: 'Vendedor',
  },
  vendedorUuid: {
    value: '',
    label: 'Vendedor',
  },
}

export interface ClientesModel {
  uuid?: string
  empresa: number
  origem: string
  id?: string
  codigo: number | null
  cnpjcpf: string
  ie?: string
  nome: string
  usual: string
  endereco?: string
  numero?: string
  complemento?: string
  bairro?: string
  uf?: string
  cidade?: string
  cidadeModel?: CidadeModel
  cep?: string
  ddd?: string
  dddcelular?: string
  telefone?: string
  celular?: string
  telefoneAsText?: string
  celularAsText?: string
  contato?: string
  useremail: string | null
  isadmin: number
  isvendedor: number
  observacao?: string
  observacaoReferencia?: string
  email?: string
  ativo?: boolean
  verificacaoSpc?: boolean
  cadastroAprovacaoLog?: {
    aprovadoPor: string
    aprovadoEm: string
  }
  condicaoPagamento?: CondicaoPagamentoModel | null
  condicaoPagamentoUuid?: string | null
  dataLimiteIgnorarInadimplencia?: string | null
  duplicatas?: Array<DuplicataModel>
  limiteCredito?: number
  indicadoresLimiteCredito?: IndicadoresLimiteCreditoModel
  pontos?: Array<PontosModel>
  migracaoMio?: boolean
  empresaModel: {
    mioEmpresaUuid: string
  }
}

export interface IndicadoresLimiteCreditoModel {
  valorDuplicatasEmAberto: number
  valorPedidosFechadosEAnalise: number
  limiteDeCredito: number
}

interface DuplicataParamsInterface {
  clienteUuid: string
  dataInicial: string
  dataFinal: string
  tipoData: 'emissao' | 'vencimento' | 'pagamento'
}

export interface ClientesQueryParams extends PlainQueryProps {
  ativo?: boolean
  vendedorUuid?: string
  representante?: TimeVendasModel
}

interface QueryParamsInterface {
  plainQuery?: string | null
  clienteUuid?: string
  pessoaUuid?: string
  pagination: PaginationProps
}

interface VincularVendedoresInputProps {
  clienteUuid: string
  vendedoresSelecionadosIds: Array<string>
}

export async function obterClientes(
  params: HttpRequestInterface<ClientesQueryParams>,
): Promise<HttpResponseInterface<ClientesModel>> {
  const { pagination: _pagination, queryParams } = params
  const response = await api.get(`administrador/clientes`, {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
      'DC-SortName': 'nome',
      'DC-SortDirection': 'ASC',
    },
  })

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterClientesPorVendedor(
  vendedorUuid: string,
  params: HttpRequestInterface<ClientesQueryParams>,
): Promise<HttpResponseInterface<ClientesModel>> {
  const { pagination: _pagination, queryParams } = params
  const response = await api.get(
    `administrador/vendedores/${vendedorUuid}/clientes`,
    {
      params: queryParams,
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
        'DC-SortName': 'nome',
        'DC-SortDirection': 'ASC',
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

function useObterClientesPorVendedor(
  vendedorUuid: string,
  params: HttpRequestInterface<ClientesQueryParams>,
) {
  return useTQuery<
    HttpResponseInterface<ClientesModel>,
    AxiosError<ErrorInterface>
  >(['CLIENTES', vendedorUuid, params], () => {
    return obterClientesPorVendedor(vendedorUuid, params)
  })
}

function useObterClientes(
  params: HttpRequestInterface<ClientesQueryParams>,
  vendedorUuid?: string,
) {
  return useTQuery<
    HttpResponseInterface<ClientesModel>,
    AxiosError<ErrorInterface>
  >(['CLIENTES', params, vendedorUuid], () => {
    if (vendedorUuid) {
      return obterClientesPorVendedor(vendedorUuid, params)
    }
    return obterClientes(params)
  })
}

export async function obterVendedoresVinculados(
  params: QueryParamsInterface,
  sort?: SortProps,
): Promise<HttpResponseInterface<VendedorModel>> {
  const { pagination: _pagination, clienteUuid } = params

  const response = await api.get(
    `administrador/clientes/${clienteUuid}/vendedores-vinculados`,
    {
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterVendedoresNaoVinculados(
  params: QueryParamsInterface,
): Promise<HttpResponseInterface<VendedorModel>> {
  const { pagination: _pagination, clienteUuid, plainQuery } = params

  const response = await api.get(
    `administrador/clientes/${clienteUuid}/vendedores-nao-vinculados`,
    {
      params: { plainQuery },
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function aprovarCadastro(pessoaUuid: string): Promise<void> {
  const response = await api.put(
    `/administrador/clientes/${pessoaUuid}/aprovar-cadastro`,
  )
  return response.data
}

export function useAprovarCadastro() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<void, AxiosError, string>(
    (uuidCliente) => aprovarCadastro(uuidCliente),
    {
      onSuccess() {
        notifications.notifySuccess('Cadastro aprovado com sucesso')
        queryClient.invalidateQueries(['CLIENTES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useLiberarInadimplencia() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<ClientesModel, AxiosError, LiberarInadimplenciaInputProps>(
    (data: LiberarInadimplenciaInputProps) => liberarInadimplencia(data),
    {
      onSuccess(_: ClientesModel) {
        notifications.notifySuccess(
          'Inadimplência do cliente liberada com sucesso',
        )
        queryClient.invalidateQueries(['DUPLICATAS'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function liberarInadimplencia(
  data: LiberarInadimplenciaInputProps,
): Promise<ClientesModel> {
  const response = await api.put(
    `/administrador/clientes/${data.clienteUuid}/liberar-inadimplencia`,
    {
      dataLimiteInadimplencia: data.dataLimiteIgnorarInadimplencia,
      horaLimiteIgnorarInadimplencia: data.horaLimiteIgnorarInadimplencia,
    },
  )
  return response.data
}

/*
 * VENDEDORES
 */

export async function vincularVendedores(
  clienteUuid: string,
  vendedoresSelecionadosIds: Array<string>,
): Promise<VendedorModel> {
  const response = await api.put(
    `/administrador/clientes/${clienteUuid}/vincular-vendedores`,
    { vendedores: vendedoresSelecionadosIds },
    { params: { clienteUuid } },
  )
  return response.data
}

export async function desvincularVendedores(
  clienteUuid: string,
  vendedoresSelecionadosIds: Array<string>,
): Promise<VendedorModel> {
  const response = await api.put(
    `/administrador/clientes/${clienteUuid}/desvincular-vendedores`,
    { vendedores: vendedoresSelecionadosIds },
    { params: { clienteUuid } },
  )

  return response.data
}

export function useQueryObterVendedoresVinculados(
  params: QueryParamsInterface,
  sort?: SortProps,
) {
  return useTQuery<
    HttpResponseInterface<VendedorModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['VENDEDORES', params, sort], () => {
    return obterVendedoresVinculados(params, sort)
  })
}

export function useQueryObterVendedoresNaoVinculados(
  params: QueryParamsInterface,
) {
  return useTQuery<
    HttpResponseInterface<VendedorModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['VENDEDORES', params], () => {
    return obterVendedoresNaoVinculados(params)
  })
}

export function useVincularVendedores() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<VendedorModel, AxiosError, VincularVendedoresInputProps>(
    (data: VincularVendedoresInputProps) =>
      vincularVendedores(data.clienteUuid, data.vendedoresSelecionadosIds),
    {
      onSuccess(_: VendedorModel) {
        notifications.notifySuccess('Vendedores vinculados com sucesso')
        queryClient.invalidateQueries(['VENDEDORES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDesvincularVendedores() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<VendedorModel, AxiosError, VincularVendedoresInputProps>(
    (data: VincularVendedoresInputProps) =>
      desvincularVendedores(data.clienteUuid, data.vendedoresSelecionadosIds),
    {
      onSuccess(_: VendedorModel) {
        notifications.notifySuccess('Vendedores desvinculados com sucesso')
        queryClient.invalidateQueries(['VENDEDORES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useQueryObterDuplicatas(
  params: HttpRequestInterface<DuplicataParamsInterface>,
) {
  return useTQuery<
    HttpResponseInterface<DuplicataModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['DUPLICATAS', params], () => {
    return obterDuplicatas(params)
  })
}

export async function obterDuplicatas(
  params: HttpRequestInterface<DuplicataParamsInterface>,
): Promise<HttpResponseInterface<DuplicataModel>> {
  const { pagination: _pagination, queryParams } = params

  if (!queryParams?.dataFinal) return { data: [], pagination: _pagination }

  const response = await api.get(
    `administrador/clientes/${queryParams?.clienteUuid}/duplicatas`,
    {
      params: { ...queryParams },
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export function useQueryObterPontos(uuidCliente: string) {
  return useTQuery<ClientesModel, AxiosError<ErrorInterface, ErrorInterface>>(
    ['PONTOS', uuidCliente],
    () => {
      return obterPontos(uuidCliente)
    },
  )
}

export async function obterPontos(uuidCliente: string): Promise<ClientesModel> {
  if (!uuidCliente) return {} as ClientesModel

  const response = await api.get(`administrador/clientes/${uuidCliente}/pontos`)
  return response.data
}

export default function useClientes() {
  return {
    ...useCrud<ClientesModel, AxiosError<{ message: string }>>(
      'administrador/clientes',
      'Cliente',
      'male',
    ),
    useObterClientesPorVendedor,
    useObterClientes,
    useVincularVendedores,
    useDesvincularVendedores,
    useQueryObterVendedoresVinculados,
    useQueryObterVendedoresNaoVinculados,
    useAprovarCadastro,
    useQueryObterDuplicatas,
    useQueryObterPontos,
  }
}
