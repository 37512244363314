import { Popover, Stack } from '@mui/material'
import { useFilter, useFilterApi } from './FilterContext'
import { ReactNode } from 'react'
import Section from '../Section'

export interface FilterAdvancedContainerProps {
  children: ReactNode
}

export default function FilterAdvancedContainer(
  props: FilterAdvancedContainerProps,
) {
  const { children } = props
  const { isOpen, container } = useFilter()
  const { closeFilter } = useFilterApi()
  const popoverWidth = container?.getBoundingClientRect().width || 300
  return (
    <Popover
      elevation={0}
      open={isOpen}
      onClose={closeFilter}
      anchorEl={container}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: `${popoverWidth}px`,
          border: (theme) => `1px solid ${theme.palette.border}`,
          borderRadius: '4px',
          pt: 2,
          pl: 1,
          pr: 1,
          pb: 1,
          // background: theme.palette.grey[100],
        },
      }}
    >
      <Section
        sx={
          {
            // border: (theme) => `1px solid ${theme.palette.border}`,
            // borderRadius: '4px',
            // pt: 2,
            // pl: 1,
            // pr: 1,
            // pb: 1,
          }
        }
      >
        <Stack spacing={1}>{children}</Stack>
      </Section>
    </Popover>
  )
}
