import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import api from 'services/api'
import { ErrorInterface } from '@data-c/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useNotification from 'hooks/useNotifications'

export interface EtiquetaModel {
  uuid?: string
  nome: string
  cor?: string
}

export async function deleteEtiqueta(id: string): Promise<EtiquetaModel> {
  const response = await api.delete(`vendas/etiqueta/${id}`)
  return response.data
}

export function useDeleteEtiqueta() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<EtiquetaModel, AxiosError, string>(
    (data: string) => deleteEtiqueta(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Etiqueta excluída com sucesso`)
        queryClient.invalidateQueries(['ETIQUETAS_VINCULADAS'])
        queryClient.invalidateQueries(['ETIQUETAS_NAO_VINCULADAS'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function createEtiqueta(
  data: EtiquetaModel,
): Promise<EtiquetaModel> {
  const response = await api.post(`vendas/etiqueta`, data)
  return response.data
}

export function useCreateEtiqueta() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<EtiquetaModel, AxiosError, EtiquetaModel>(
    (data: EtiquetaModel) => createEtiqueta(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Etiqueta criada com sucesso`)
        queryClient.invalidateQueries(['ETIQUETAS_VINCULADAS'])
        queryClient.invalidateQueries(['ETIQUETAS_NAO_VINCULADAS'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function updateEtiqueta(
  data: EtiquetaModel,
): Promise<EtiquetaModel> {
  const response = await api.put(`vendas/etiqueta/${data.uuid}`, data)
  return response.data
}

export function useUpdateEtiqueta() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<EtiquetaModel, AxiosError, EtiquetaModel>(
    (data: EtiquetaModel) => updateEtiqueta(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Etiqueta alterada com sucesso`)
        queryClient.invalidateQueries(['ETIQUETAS_VINCULADAS'])
        queryClient.invalidateQueries(['ETIQUETAS_NAO_VINCULADAS'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function obterEtiquetas(): Promise<EtiquetaModel[]> {
  const response = await api.get<EtiquetaModel[]>(`vendas/etiqueta`)
  return response.data
}

export function useQueryObterEtiquetas() {
  return useQuery<EtiquetaModel[], AxiosError<ErrorInterface, ErrorInterface>>(
    ['ETIQUETA'],
    () => {
      return obterEtiquetas()
    },
  )
}

export default function useEtiqueta() {
  return {
    ...useCrud<EtiquetaModel, AxiosError>(
      'vendas/etiqueta',
      'Etiqueta',
      'female',
    ),
    useQueryObterEtiquetas,
    useUpdateEtiqueta,
    useCreateEtiqueta,
    useDeleteEtiqueta,
  }
}
