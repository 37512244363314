import { Typography } from '@mui/material'
import { ReactNode } from 'react'

export interface OnboardingCardSecondaryMessageProps {
  value: string | ReactNode
}

export default function OnboardingCardSecondaryMessage(
  props: OnboardingCardSecondaryMessageProps,
) {
  const { value } = props
  return (
    <Typography style={{ textAlign: 'center' }} variant="body1">
      {value}
    </Typography>
  )
}
