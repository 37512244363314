import { IconButton, IconButtonProps } from '@mui/material'
import Icon from 'components/MioCandidate/Icon'
// import { useMenu } from 'components/Contexts/MenuContext'

export interface HeaderToggleMenuProps extends IconButtonProps {}

export default function HeaderToggleMenu(props: HeaderToggleMenuProps) {
  const { ...iconButtonProps } = props
  // const { isOpen } = useMenu()
  return (
    <IconButton {...iconButtonProps}>
      <Icon.Menu />
    </IconButton>
  )
}
