import { Stack } from '@mui/material'
import { ReactNode } from 'react'

export interface ContentTitleRootProps {
  children: ReactNode
}

export default function ContentTitleRoot(props: ContentTitleRootProps) {
  const { children } = props
  return (
    <Stack
      // sx={{ background: '#f3f' }}
      // pt={0.5}
      // pb={0.5}
      pl={1}
      pr={1}
      pt={0.5}
      pb={0.5}
      direction="row"
      alignContent="center"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
    >
      {children}
    </Stack>
  )
}
