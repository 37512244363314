import { Chip, ChipProps } from '@mui/material'
import { ReactNode } from 'react'

export interface FiltrerChipProps extends Omit<ChipProps, 'children'> {
  children?: ReactNode
  name: string
  disabledDelete?: boolean
}

export default function FiltrerChip(props: FiltrerChipProps) {
  const { children, disabledDelete = false, ...rest } = props

  return <Chip {...rest}></Chip>
}
