import {
  Button,
  CurrencyCellStyle,
  DataTable,
  Dialog,
  // Flag,
  DataTableOptions as Options,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  PedidoFilters1,
  PedidoModel,
  SituacaoPedidoEnum,
  useQueryObterPedidos,
} from 'hooks/queries/usePedidos'
import { useMemo, useCallback, useState } from 'react'
import {
  SortProps,
  findData,
  formatCurrency,
  useDialog,
  usePagination,
} from '@data-c/hooks'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
// import { Badge } from '@mui/material'
import { useQuery } from 'hooks/queries/useConfiguracoes'

import { useFilter } from 'components/MioCandidate/Filter/FilterContext'

export default function Table() {
  const [sort, setSort] = useState<SortProps>({
    column: 'venda',
    direction: 'desc',
  })
  const { data: configuracoes } = useQuery()
  const { pagination, changePageSize, changePage } = usePagination()
  const { appliedValues, searchId } = useFilter<PedidoFilters1>()

  const { representante, cliente, dateRange, ...rest } = appliedValues || {}

  const nFilters: PedidoFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }
  const { openConfirm } = useFormApi()
  const {
    data: dataDialog,
    openDialog: openDialog,
    isOpen: isOpenDialog,
    closeDialog: closeDialog,
  } = useDialog()
  const { data, isLoading, isFetching, error } = useQueryObterPedidos(
    {
      queryParams: nFilters,
      pagination,
      requestId: searchId,
    },
    sort,
  )

  const navigate = useNavigate()

  const pedidos = useMemo(() => {
    if (!data?.data) return []
    return data.data.map((pedido: PedidoModel) => ({
      ...pedido,
      representante: `${pedido.representante?.codigo} - ${pedido.representante?.nome}`,
      cliente: `${pedido.cliente?.codigo || ''} - ${pedido.cliente?.nome}`,
      venda: pedido.vendaAsBrazilianDate,
      totalLiquido: pedido.totalLiquidoAsBrazilianCurrency,
      totalComImposto: formatCurrency(pedido?.totalComImposto || ''),
      pesoTotal: `${new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 3,
      }).format(pedido?.pesoTotal || 0)}Kg`,
      observacao: _.truncate(pedido.observacao, { length: 30 }),
      pedidoRelacionadoCodigo: pedido.pedidoRelacionado?.codigo || '',
      condicaoPagamento: pedido.condicaoPagamento?.nome || '',
      formaPagamento: pedido.formaPagamento?.nome || '',
    }))
  }, [data])

  const totais = useMemo(() => {
    return data?.data.reduce(
      (acumulador, valorAtual) => {
        const { total, totalNota, pesoTotal } = acumulador
        return {
          total: total + valorAtual.totalLiquido,
          totalNota: totalNota + valorAtual.totalComImposto,
          pesoTotal: pesoTotal + (valorAtual.pesoTotal || 0),
        }
      },
      { total: 0, totalNota: 0, pesoTotal: 0 },
    )
  }, [data?.data])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: PedidoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/pedidos/pedido/${data.uuid}`)
          break
        case 'delete':
          const situacoesQuePodemSerExcluidas = [
            SituacaoPedidoEnum.WEB,
            SituacaoPedidoEnum.MOBILE,
            SituacaoPedidoEnum.COTACAO_SOLICITACAO,
          ]

          if (
            data?.situacao &&
            situacoesQuePodemSerExcluidas.includes(data?.situacao)
          ) {
            return openConfirm(data)
          }

          openDialog(data.situacaoAsText)
          break
      }
    },
    [navigate],
  )

  function riscarNegociacaoPerdida(indexNegociacao: number) {
    const situacao = pedidos[indexNegociacao].situacao
    return situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA
      ? 'line-through'
      : 'none'
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'pedidoRelacionadoCodigo',
        label: 'Cód. Origem',
        options: {
          display: Boolean(configuracoes?.usa_pedido_pronta_entrega),
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                // width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'venda',
        label: 'Data',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'situacaoAsText',
        label: 'Situação',
        options: {
          sort: false,
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                width: '96px',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'representante',
        label: 'Vendedor',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'cliente',
        label: 'Cliente',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'formaPagamento',
        label: 'Forma Pagamento',
      },
      {
        name: 'condicaoPagamento',
        label: 'Condição Pagamento',
      },
      {
        name: 'observacao',
        label: 'Observação',
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'pesoTotal',
        label: `Peso (${new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 3,
        }).format(totais?.pesoTotal || 0)}Kg)`,
        options: {
          display: Boolean(configuracoes?.exibe_peso),
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textAlign: 'right',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'carga',
        label: `Núm. Carga`,
        options: {
          display: Boolean(configuracoes?.exibe_peso),
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textAlign: 'right',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'totalLiquido',
        label: `Total (R$${formatCurrency(totais?.total || 0)})`,
        options: {
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                textAlign: 'right',
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      {
        name: 'totalComImposto',
        label: `Total Nota (R$${formatCurrency(totais?.totalNota || 0)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          sort: false,
          setCellProps: (_, rowIndex) => {
            return {
              style: {
                ...CurrencyCellStyle().style,
                textDecoration: riscarNegociacaoPerdida(rowIndex),
              },
            }
          },
        },
      },
      // {
      //   name: 'comentarioStatus',
      //   label: 'Comentários',
      //   options: {
      //     sort: false,
      //     setCellProps: () => ({
      //       style: {
      //         width: '60px',
      //         textAlign: 'center',
      //       },
      //     }),
      //     customBodyRender: (value: {
      //       totalComentarios: number
      //       totalNaoLidas: number
      //     }) => {
      //       if (value.totalNaoLidas > 0) {
      //         return (
      //           <Badge color="error" badgeContent={value.totalNaoLidas}></Badge>
      //         )
      //       }

      //       if (value.totalComentarios > 0) {
      //         return <Flag isFlagged={true} type="primary" />
      //       }
      //       return ''
      //     },
      //   },
      // },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellHeaderProps: () => {
            return {
              style: {
                // position: 'sticky',
                // zIndex: 100,
                // right: 0,
              },
            }
          },
          setCellProps: () => {
            return {
              style: {
                // position: 'sticky',
                // zIndex: 10,
                // right: 0,
                width: '100px',
                textAlign: 'center',
              },
            }
          },
          customBodyRender: (value) => {
            const _data = findData<PedidoModel>(data?.data || [], value)
            return (
              <Options
                value={_data}
                onClick={handleClickItem}
                // displayUpdateButton={false}
                displayDeleteButton={true}
                // extraOptions={[
                //   {
                //     id: 'cotacao',
                //     icon: 'calculate',
                //     iconButtonProps: {
                //       color: 'primary',
                //     },
                //   },
                // ]}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem, configuracoes],
  )

  return (
    <>
      {/* <Section>
        <ButtonContainer>
          
          <Button variant="contained" onClick={() => onAdicionarClick()}>
            Adicionar
          </Button>
        </ButtonContainer>
      </Section> */}

      <DataTable
        error={error?.message}
        pagination={data?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        columns={columns}
        data={pedidos || []}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          // fixedHeader: true,
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
        }}
      />

      <Dialog
        title={`A negociação não está em aberto`}
        maxWidth="xs"
        type="info"
        open={isOpenDialog}
        onClose={closeDialog}
        actions={
          <Button variant="contained" onClick={closeDialog}>
            Voltar
          </Button>
        }
      >
        A situação da negociação é: <strong>{`${dataDialog}`}</strong>.
        <br />
        <br />
        Apenas as negociações <strong>Em aberto</strong> podem ser excluídas!
      </Dialog>
    </>
  )
}
