import { FormProvider } from '@data-c/providers'
import {
  Autocomplete,
  CircularProgress,
  DialogProps,
  FilterOptionsState,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  createFilterOptions,
} from '@mui/material'
import React, { ComponentType, ReactElement, useCallback } from 'react'
import { useDialog, TransportableDataTableProps } from '@data-c/hooks'
import { Dialog } from '@data-c/ui'
import _ from 'lodash'
import Icon from 'components/MioCandidate/Icon'
import MioFilter from 'components/MioCandidate/Filter'

export type TransporterProps<T, TP = {}> = {
  form?: ComponentType
  filter?: ComponentType
  table: ComponentType<TransportableDataTableProps<T>>
  tableProps?: TP
  options: Array<T>
  value?: T | null
  inputValue?: unknown
  isLoading?: boolean
  onSearch: (q: string) => void
  renderValue: (value: T) => string
  onChange?: (value: T | null) => void
  optionKeyName?: string
  filterOptions?:
    | ((options: T[], state: FilterOptionsState<T>) => T[])
    | undefined
  dialogProps?: Omit<DialogProps, 'open'>
  endAdornment?: ReactElement | null
} & Omit<TextFieldProps, 'onChange'>

export default function Transporter<T>(props: TransporterProps<T>) {
  const {
    value,
    label,
    options,
    isLoading: loading,
    form: Form,
    table: Table,
    filter: Filter,
    tableProps,
    onChange,
    onSearch,
    renderValue,
    dialogProps,
    disabled,
    filterOptions,
    optionKeyName,
    endAdornment: _endAdornment,
    ...rest
  } = props
  const { isOpen, openDialog, closeDialog } = useDialog()

  const ValidForm = Form ? Form : React.Fragment
  const ValidFilter = Filter ? Filter : React.Fragment

  const debouncedHandleSearch = _.debounce((query) => onSearch(query), 500)

  const handleSearch = useCallback(
    async (_: any, iValue: string) => {
      debouncedHandleSearch(iValue)
    },
    [debouncedHandleSearch],
  )

  const handleChange = useCallback(
    (_: React.SyntheticEvent, value: T | null, __: string) => {
      if (
        optionKeyName &&
        /**@ts-ignore */
        value?.[optionKeyName].includes('Adicionar opção:')
      ) {
        /**@ts-ignore */
        const cleanValue = value[optionKeyName]
          .replace('Adicionar opção:', '')
          .trim()
          .replace(/["']/g, '')
        const newValue = { ...value, [optionKeyName]: cleanValue }
        onChange && onChange(newValue)
        return
      }

      onChange && onChange(value)
    },
    [onChange],
  )

  function resetEndAdornment(
    endAdornment: React.ReactElement,
    isLoading: boolean = false,
    disabled: boolean = false,
  ) {
    if (endAdornment) {
      const children = React.Children.toArray(endAdornment.props?.children)

      const pos = children.length === 1 ? 0 : 1

      children.splice(
        pos,
        0,
        <IconButton
          sx={{ padding: '2px' }}
          size="small"
          color="default"
          onClick={() => openDialog()}
          disabled={disabled}
        >
          <Icon.Search />
        </IconButton>,
      )

      if (_endAdornment) children.unshift(_endAdornment as ReactElement)

      if (isLoading) {
        children.unshift(
          <IconButton disabled={true}>
            <CircularProgress color="primary" size={18} />
          </IconButton>,
        )
      }

      return React.cloneElement(endAdornment, {}, children)
    }
    return endAdornment
  }

  function handleFilterOptions<T>(options: T[], params: FilterOptionsState<T>) {
    if (!optionKeyName) return options
    const filter = createFilterOptions<T>()
    const filtered = filter(options, params)

    const { inputValue } = params
    const isExisting = options.some(
      /**@ts-ignore */
      (option) => inputValue === option[optionKeyName],
    )

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        [optionKeyName]: `Adicionar opção: "${inputValue}"`,
      } as any)
    }

    return filtered
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        loadingText="Carregando..."
        loading={loading}
        onChange={handleChange}
        value={value}
        onInputChange={handleSearch}
        options={options}
        getOptionLabel={renderValue}
        filterOptions={handleFilterOptions}
        renderInput={(params) => {
          return (
            <TextField
              label={label}
              onDoubleClick={() => {
                if (!disabled) openDialog()
              }}
              {...params}
              slotProps={{
                input: {
                  ...params.InputProps,
                  endAdornment: resetEndAdornment(
                    params.InputProps.endAdornment as React.ReactElement,
                    loading,
                    disabled,
                  ),
                },
              }}
              disabled={disabled}
              {...rest}
            />
          )
        }}
        clearIcon={<Icon.Erease />}
        componentsProps={{
          clearIndicator: {
            sx: {
              visibility: 'visible',
            },
          },
        }}
        noOptionsText="Nenhum registro"
      />
      <FormProvider>
        <Dialog
          open={isOpen}
          onClose={() => closeDialog()}
          actions="none"
          {...dialogProps}
        >
          <MioFilter.Provider filterValues={{}}>
            <Stack spacing={1}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <ValidFilter />
                {Form && <ValidForm />}
              </Stack>
              <Table
                enableTransporter={true}
                onTransport={(data) => {
                  closeDialog()
                  onChange && onChange(data)
                }}
                {...tableProps}
              />
            </Stack>
          </MioFilter.Provider>
        </Dialog>
      </FormProvider>
    </>
  )
}

const defaultProps: Pick<TransporterProps<any>, 'dialogProps'> = {
  dialogProps: {
    maxWidth: 'md',
    fullWidth: true,
  },
}

Transporter.defaultProps = defaultProps
