import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import invariant from 'tiny-invariant'

import { v4 } from 'uuid'

type ActionsType<T = any> =
  | { type: 'loadFiltersFromStorage'; storageValues: any }
  | { type: 'updateFilterValues'; filterValues: T }
  | { type: 'updateFilterValue'; payload: { name: string; value: any } }
  | { type: 'updateAppliedValues'; storagePath?: string }
  | {
      type: 'updateIsOpen'
      isOpen: boolean
      target: HTMLElement | undefined
    }
  | { type: 'updateContainer'; container: HTMLElement }
  | { type: 'toggleIsOpen' }
  | { type: 'deleteAppliedValue'; filterName: string }
  | { type: 'deleteAllAppliedValues'; filterValues: T; storagePath?: string }

function reducer<T>(
  state: FilterDataContextProps,
  action: ActionsType<T>,
): FilterDataContextProps {
  switch (action.type) {
    case 'loadFiltersFromStorage':
      return {
        ...state,
        filterValues: action?.storageValues?.filterValues,
        appliedValues: action?.storageValues?.appliedValues,
      }
    case 'updateFilterValues':
      return { ...state, filterValues: action.filterValues }
    case 'updateFilterValue':
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [action.payload.name]: action.payload.value,
        },
      }
    case 'updateAppliedValues':
      let filters = state.filterValues
      let asArray: Array<any> = []
      if (filters && Object.entries(filters).length > 0) {
        const [key] = Object.keys(filters)
        asArray = [{ [key]: filters[key] }]
      }

      if (action?.storagePath) {
        localStorage.setItem(
          action.storagePath,
          JSON.stringify({
            appliedValues: filters,
            filterValuesAsArray: asArray,
            filterValues: state.filterValues,
          }),
        )
      }
      return {
        ...state,
        appliedValues: filters,
        filterValuesAsArray: asArray,
        isOpen: false,
        searchId: v4(),
      }
    case 'updateIsOpen':
      return { ...state, isOpen: action.isOpen, target: action.target }
    case 'updateContainer':
      return { ...state, container: action.container }
    case 'toggleIsOpen':
      return { ...state, isOpen: !state.isOpen }
    case 'deleteAppliedValue':
      delete state.appliedValues[action.filterName]
      return { ...state }
    case 'deleteAllAppliedValues':
      state.appliedValues = {}
      state.filterValues = action.filterValues
      if (action.storagePath) {
        localStorage.removeItem(action.storagePath)
      }
      return { ...state }
  }
}

export interface FilterDataContextProps<T = any> {
  filterValues: T
  filterValuesAsArray: Array<Record<string, T>>
  appliedValues: T
  isOpen: boolean
  target?: HTMLElement
  container?: HTMLElement
  searchId?: string
}

export const FilterDataContext = React.createContext<FilterDataContextProps>(
  {} as FilterDataContextProps,
)

export interface FilterApiContextProps<T = any> {
  setContainer: (container: HTMLElement) => void
  openFilter: (event: React.MouseEvent<HTMLElement>) => void
  closeFilter: () => void
  toggleFilter: () => void
  onChangeFilterValue?: (e: React.ChangeEvent<HTMLInputElement>) => void
  changeFilterValue: (name: string, value: any) => void
  changeFilterValues: (filterValues: T) => void
  applyFilterValues: () => void
  deleteFilterValue?: (filterName: string) => void
  deleteAllFilterValues?: () => void
}

export const FilterApiContext = React.createContext<FilterApiContextProps>(
  {} as FilterApiContextProps,
)

export interface FilterOptions<T> {
  label?: string
  value?: T
}

export interface FilterProviderProps<T = any> {
  children: ReactNode
  filterValues?: T
  storagePath?: string
}

export function FilterProvider<T>(props: FilterProviderProps<T>) {
  const { children, filterValues: _filters = {}, storagePath } = props
  const [state, dispatch] = useReducer(reducer, {
    filterValues: {},
  } as FilterDataContextProps)

  useEffect(() => {
    dispatch({ type: 'updateFilterValues', filterValues: _filters })
    dispatch({ type: 'updateAppliedValues', storagePath })
  }, [_filters])

  useEffect(() => {
    if (storagePath) {
      const f = localStorage.getItem(storagePath)
      if (f && f !== 'undefined') {
        const fParsed = JSON.parse(f)
        dispatch({ type: 'loadFiltersFromStorage', storageValues: fParsed })
      }
    }
  }, [])

  const api: FilterApiContextProps = useMemo(() => {
    function openFilter(event?: React.MouseEvent<HTMLElement>) {
      dispatch({
        type: 'updateIsOpen',
        isOpen: true,
        target: (event?.target as HTMLElement) || undefined,
      })
    }

    function closeFilter() {
      dispatch({ type: 'updateIsOpen', isOpen: false, target: undefined })
    }

    function toggleFilter() {
      dispatch({ type: 'toggleIsOpen' })
    }

    function onChangeFilterValue(e: React.ChangeEvent<HTMLInputElement>) {
      const type = e.target.type
      const name = e.target.name
      let value: string | boolean | number
      switch (type) {
        case 'text':
          value = e.target.value
          break
        case 'checkbox':
          value = e.target.checked
          break
        default:
          value = e.target.value
      }
      dispatch({ type: 'updateFilterValue', payload: { name, value } })
    }
    function changeFilterValue(name: string, value: any) {
      dispatch({ type: 'updateFilterValue', payload: { name, value } })
    }
    function changeFilterValues(filterValues: any) {
      dispatch({ type: 'updateFilterValues', filterValues })
    }
    function applyFilterValues() {
      dispatch({ type: 'updateAppliedValues', storagePath })
    }
    function deleteFilterValue(filterName: string) {
      dispatch({ type: 'deleteAppliedValue', filterName })
    }
    function deleteAllFilterValues() {
      dispatch({
        type: 'deleteAllAppliedValues',
        filterValues: _filters,
        storagePath,
      })
      dispatch({ type: 'updateAppliedValues', storagePath })
    }
    function setContainer(container: HTMLElement) {
      dispatch({ type: 'updateContainer', container })
    }

    return {
      setContainer,
      openFilter,
      closeFilter,
      onChangeFilterValue,
      changeFilterValue,
      changeFilterValues,
      applyFilterValues,
      deleteFilterValue,
      deleteAllFilterValues,
      toggleFilter,
    }
  }, [_filters])

  return (
    <FilterApiContext.Provider value={api}>
      <FilterDataContext.Provider value={state}>
        {children}
      </FilterDataContext.Provider>
    </FilterApiContext.Provider>
  )
}

export function useFilter<T = any>() {
  const value = useContext<FilterDataContextProps<T>>(FilterDataContext as any)
  invariant(value, 'cannot find FilterDataContext provider')
  return value
}

export function useFilterApi<T = any>() {
  const value = useContext<FilterApiContextProps<T>>(FilterApiContext as any)
  invariant(value, 'cannot find FilterApiContextContext provider')
  return value
}
