import {
  findData,
  formatCurrency,
  TransportableDataTableProps,
} from '@data-c/hooks'
import {
  CurrencyCellStyle,
  DataTable,
  DataTableOptions,
  OptionStyles,
} from '@data-c/ui'
import { ProdutoTransporter } from 'components/Transporters/ItensTabelaPrecoTransporter'
import { useQueryFichaClienteAsPropduto } from 'hooks/queries/useRelatorioFichaCliente'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useCallback, useMemo } from 'react'
import ParseTo from 'utils/ParseTo'

interface TableProps extends TransportableDataTableProps<ProdutoTransporter> {
  clienteUuid?: string | null
  dataInicio?: string | null
  tabelaPrecoUuid?: string | null
}

export default function Table(props: TableProps) {
  const { clienteUuid, tabelaPrecoUuid, onTransport, enableTransporter } = props

  const { data, isLoading, error } = useQueryFichaClienteAsPropduto({
    clienteUuid: clienteUuid || '',
    tabelaPrecoUuid: tabelaPrecoUuid || '',
    dataInicio: null,
  })

  const handleClickItem = useCallback(
    (event: 'transport', data: ProdutoTransporter) => {
      switch (event) {
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ultimaCompra',
        label: 'Última Compra',
        options: {
          setCellProps() {
            return { width: '125px' }
          },
        },
      },
      {
        name: 'quantidadeItensComprados',
        label: 'Qtd. Total Comprado',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'quantidadeRecorrenteCompra',
        label: 'Qtd. Vezes Comprado',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'menorPreco',
        label: 'Menor Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'maiorPreco',
        label: 'Maior Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'ultimoPreco',
        label: 'Último Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'uuidItem',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data || [], value, 'uuidItem')
            return (
              <DataTableOptions
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data],
  )

  const relatorio = useMemo(() => {
    return (
      data?.map((item) => ({
        ...item,
        uuidItem: item.uuidItem,
        codigoProduto: item.produto.codigo,
        nomeProduto: item.produto.nome,
        precoProduto: formatCurrency(item?.precoDeTabela || ''),
        ...item.produto,
        menorPreco: formatCurrency(item?.menorPreco || ''),
        maiorPreco: formatCurrency(item?.maiorPreco || ''),
        ultimoPreco: formatCurrency(item?.ultimoPreco || ''),
        quantidadeItensComprados: ParseTo.number(
          item?.quantidadeItensComprados || 0,
        ),
        produtocls: item.produto?.produtoClasse?.nome || '',
        produtofml: item.produto?.familiaProduto?.nome || '',
        produtolnh: item.produto?.linhaProduto?.nome || '',
        produtogrp: item.produto?.grupoProduto?.nome || '',
      })) || []
    )
  }, [data])

  // const relatorio = data || []

  // function handleSearch(q: string | null) {
  //   setQuery(q)
  // } error={error?.message}

  return (
    <DataTable
      columns={columns}
      data={relatorio}
      isLoading={isLoading}
      error={error?.message}
    />
  )
}
