import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'

import {
  Box,
  Button,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import Menu from 'components/MioCandidate/Menu'
import { MenuItemProps } from 'components/MioCandidate/Menu/MenuItem'
// import Header from 'components/Header'

import { Navigate } from 'react-router-dom'
import Fallback from 'components/Fallback'
import * as Sentry from '@sentry/react'
import { useUserContext } from 'components/Contexts/UserContext'
import api from 'services/api'
import socket from 'services/ws'
import { useCredentials, Empresa } from '@data-c/hooks'
import credentials from 'configs/credentials'
import Unauthorized from 'components/Displays/Unauthorized'
import { useMenu } from 'components/Contexts/MenuContext'
import { Header } from 'components/MioCandidate/Header'
import usePopOver from 'hooks/usePopover'

interface LayoutProps {
  children: ReactNode
  isProtected: boolean
  role?: string
}

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AccountPopover from 'components/Header/components/AccountPopover'
import Icon from 'components/MioCandidate/Icon'
import { ArrowDropDown, Check } from '@mui/icons-material'
import { Surface } from '@data-c/ui'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { useQueryClient } from '@tanstack/react-query'

function renderMenuItems(items: MenuItemProps[]): React.ReactNode {
  return items.map((item) => {
    // Verifica se o item deve ser renderizado (com base na propriedade `visible`)
    if (!item.visible) {
      return null
    }

    return (
      <Menu.Item
        key={item.id}
        link={item.link}
        id={item.id}
        label={item.label}
        icon={item.icon}
      >
        {/* Renderiza os subitens de forma recursiva, se existirem */}
        {item.items && renderMenuItems(item.items)}
      </Menu.Item>
    )
  })
}

const Layout = (props: LayoutProps) => {
  const { children, isProtected, role } = props
  const { isAuthenticated, userLogged, selecionarLicenca, token } =
    useCredentials(credentials)
  const theme = useTheme()
  const { user, temPermissao, loadConfiguracoes, loadUser, isLoading } =
    useUserContext()
  const { toggleMenu, isOpen } = useMenu()
  if (isProtected && !isAuthenticated) {
    return <Navigate to="/login" replace />
  }
  const queryClient = useQueryClient()
  const empresas = useMemo(() => {
    if (userLogged) {
      return userLogged.permissoes['app-fdv']?.empresas || []
    }
    return []
  }, [userLogged])

  const {
    open: openMenu,
    close: closeMenu,
    isOpen: isOpenMenu,
    anchorEl,
  } = usePopOver()

  function registerSw() {
    navigator.serviceWorker
      .register('/service-worker.js', { scope: '/' })
      .then(async (sw) => {
        sw.update()
        let subscription = await sw.pushManager.getSubscription()
        if (!subscription) {
          subscription = await sw.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              'BKRP8i5k9eVt6LHURZ17oFyJREolcDkmJsXjEAjvVrW4bwcpU29Ft5d532pO5Ld7yqrcFQa9m9WRYJtt7qt5-sA',
          })
          await api.post('webpush/register', subscription)
        }
      })
      .catch((e) => console.log('SW', e))
  }

  useEffect(() => {
    loadUser()
    loadConfiguracoes()
  }, [])

  let podeAcessar = true

  if (role && !temPermissao(role)) {
    podeAcessar = false
  }

  useEffect(() => {
    function onConnect() {
      socket.emit('join', user?.uuid)
    }
    function onDisconnect() {}
    function onError(_: any) {}

    if (user) {
      // setUser(user)
      registerSw()
      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)
      socket.on('error', onError)
      socket.connect()

      return () => {
        socket.off('connect', onConnect)
        socket.off('disconnect', onDisconnect)
        socket.off('error', onError)
        socket.disconnect()
      }
    }
  }, [user])

  const menuItems: Array<MenuItemProps> = useMemo((): Array<MenuItemProps> => {
    function visibleIn(
      environments: Array<'production' | 'development'>,
      onlyAdmin: boolean = false,
    ): boolean {
      const environment: 'production' | 'development' =
        (import.meta.env.VITE_ENV as 'production' | 'development') ||
        'development'

      let hasPermission = true

      if (onlyAdmin) {
        hasPermission = user?.isadmin || false
      }

      return environments.includes(environment) && hasPermission
    }

    return [
      {
        id: 'painel_desempenho',
        label: 'Painel de Desempenho',
        icon: <Icon.Dashboard />,
        link: '/painel-desempenho',
        visible: false,
        isFirstLevel: true,
      },
      {
        id: 'menu_negociacoes',
        label: 'Negociações',
        icon: <Icon.Negociacao />,
        link: '/pedidos/lista',
        isFirstLevel: true,
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('funil_vendas.visibilidade'),
      },
      {
        id: 'menu_funil_venda',
        label: 'Funil de Vendas',
        icon: <Icon.Kanban />,
        link: '/funil-venda',
        isFirstLevel: true,
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('negociacoes.visibilidade'),
      },
      {
        id: 'menu_cadastros',
        label: 'Cadastros',
        icon: <Icon.Cadastro />,
        isFirstLevel: true,
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('cadastros.visibilidade'),
        items: [
          {
            id: 'menu_clientes',
            label: 'Clientes',
            icon: <Icon.Clientes />,
            link: '/clientes/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('clientes.visibilidade'),
          },
          {
            id: 'menu_vendedores',
            label: 'Vendedores',
            link: '/vendedores/lista',
            icon: 'hail',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('vendedores.visibilidade'),
          },
          {
            id: 'menu_tabela_precos',
            label: 'Tabela de Preços',
            icon: 'price_change',
            link: '/tabelas-precos/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('tabela_precos.visibilidade'),
            // visible: false,
          },
          {
            id: 'menu_condicao_pagamento',
            label: 'Condição de Pgto.',
            icon: 'date_range',
            link: '/condicao-pagamento',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('condicao_pagamento.visibilidade'),
          },
          {
            id: 'menu_forma_pagamento',
            label: 'Forma de Pagamento',
            icon: 'payment',
            link: '/forma-pagamento/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('forma_pagamento.visibilidade'),
          },
          {
            id: 'menu_fator_precificacao',
            label: 'Fator de Precificação',
            icon: 'price_change',
            link: '/fator-precificacao',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('fator_precificacao.visibilidade'),
          },
          // {
          //   id: 'importacao',
          //   label: 'Importar',
          //   icon: 'newspaper',
          //   link: '/importacao',
          //   visible: visibleIn(['development', 'production']),
          // },
          {
            id: 'menu_produtos',
            label: 'Produtos',
            icon: 'shopping_bag',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('produtos.visibilidade'),
            items: [
              {
                id: 'menu_produtos_listamenu_produtos_lista',
                label: 'Produtos',
                icon: 'circle',
                link: '/produtos/lista',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos.visibilidade'),
              },
              {
                id: 'menu_mix_produtos',
                label: 'Mix de Produtos',
                icon: 'circle',
                link: '/mix-produtos/lista',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('mix_produtos.visibilidade'),
              },
              {
                id: 'menu_linha_produtos',
                label: 'Linha de Produtos',
                icon: 'circle',
                link: '/linha-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_linha.visibilidade'),
              },
              {
                id: 'menu_grupo_produtos',
                label: 'Grupo de Produtos',
                icon: 'circle',
                link: '/grupo-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_grupo.visibilidade'),
              },
              {
                id: 'menu_familia_produtos',
                label: 'Família de Produtos',
                icon: 'circle',
                link: '/familia-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_familia.visibilidade'),
              },
              {
                id: 'menu_classe_produtos',
                label: 'Classe de Produtos',
                icon: 'circle',
                link: '/classe-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_classe.visibilidade'),
              },
            ],
          },
        ],
      },
      {
        id: 'menu_crm',
        label: 'CRM',
        icon: 'support_agent',
        isFirstLevel: true,
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('crm.visibilidade'),
        items: [
          // {
          //   id: 'menu_leads',
          //   label: 'Leads',
          //   icon: 'groups',
          //   link: '/clientes/lista',
          //   visible:
          //     visibleIn(['development']) && temPermissao('leads.visibilidade'),
          // },
          {
            id: '16',
            label: 'Atividades',
            icon: 'circle',
            link: '/crm/atividades',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('atividades.visibilidade'),
          },
          {
            id: '17',
            label: 'Motivos de Perda',
            icon: 'circle',
            link: '/motivos-de-perda',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('motivos_perda.visibilidade'),
          },
          {
            id: 'crm_funis-vendas',
            label: 'Funis de Venda',
            icon: 'circle',
            link: '/funis-venda/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('funis_venda.visibilidade'),
          },
          {
            id: 'crm_etiquetas',
            label: 'Etiquetas',
            icon: 'circle',
            link: '/etiquetas/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('etiquetas.visibilidade'),
          },
          // {
          //   id: 'menu_funil_venda',
          //   label: 'Funil de Vendas',
          //   icon: 'circle',
          //   link: '/funil-venda',
          //   visible:
          //     visibleIn(['development', 'production']) &&
          //     temPermissao('crm_funil_venda.visibilidade'),
          // },
        ],
      },
      {
        id: 'menu_relatorios',
        label: 'Relatórios',
        icon: 'bar_chart',
        isFirstLevel: true,
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('relatorios.visibilidade'),
        items: [
          {
            id: 'relatorio-previsao-entrega',
            label: 'Previsão de Entrega',
            icon: 'bar_chart',
            link: '/relatorio-previsao-entrega',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('previsao_entrega.visibilidade'),
            isNew: true,
          },
          {
            id: 'relatorio-performance-produtos',
            label: 'Performance de Produtos',
            icon: 'bar_chart',
            link: '/relatorio-performance-produtos',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('performance_produtos.visibilidade'),
            isNew: true,
          },
          {
            id: 'taxa_conversao_negocio',
            label: 'Taxa Conversão Negócio',
            icon: 'bar_chart',
            link: '/taxa-conversao-negocio',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('taxa_conversao_negocio.visibilidade'),
          },
          {
            id: 'abc_produtos',
            label: 'Curva ABC Produtos',
            icon: 'bar_chart',
            link: '/abc-produtos',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('abc_produtos.visibilidade'),
          },
          {
            id: 'abc_clientes',
            label: 'Curva ABC Clientes',
            icon: 'bar_chart',
            link: '/abc-clientes',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('abc_clientes.visibilidade'),
          },
          {
            id: 'menu_meta_vendas',
            label: 'Meta de Vendas',
            icon: 'bar_chart',
            link: '/meta-vendas',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('meta_vendas.visibilidade'),
          },
          {
            id: 'menu_ficha_cliente',
            label: 'Ficha do Cliente',
            icon: 'bar_chart',
            link: '/ficha-clientes',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('ficha_clientes.visibilidade'),
          },
          {
            id: 'menu_ativacao_clientes',
            label: 'Ativação de Clientes',
            icon: 'bar_chart',
            link: '/ativacao-clientes',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('positivacao_clientes.visibilidade'),
          },
          {
            id: 'menu_negociacoes_perdidas',
            label: 'Oportunidades Perdidas',
            icon: 'bar_chart',
            link: '/negociacoes-perdidas',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('negociacoes_perdidas.visibilidade'),
          },
        ],
      },
      {
        id: 'menu_configuracoes',
        label: 'Configurações',
        icon: 'settings',
        link: '/configuracoes',
        isFirstLevel: true,
        visible:
          visibleIn(['development']) &&
          temPermissao('configuracoes.visibilidade'),
      },
    ]
  }, [user])

  const memoizedRenderMenuItems = useCallback(
    () => renderMenuItems(menuItems),
    [menuItems],
  )

  async function handleAlterarLicenca(e: Empresa) {
    try {
      await selecionarLicenca(token || undefined, e.uuid)
      queryClient.clear()
      queryClient.invalidateQueries()
      window.location.reload()
    } catch (err) {}
  }

  return (
    <Box
      sx={{
        // backgroundColor: '#fff',
        // backgroundImage: `url('${bgImage}')`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        display: 'flex',
        marginTop: `${theme.mixins.toolbar.height}px`,
      }}
    >
      <Header.Root>
        <Header.Content>
          <Header.ToggleMenu onClick={toggleMenu} />
          <Button
            sx={{ fontSize: '0.950rem', pt: 0, pb: 0, pl: 0.5, pr: 0.5 }}
            variant="text"
          >
            Força de Vendas
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault()
              window.open(
                'https://datacsistemashelp.zendesk.com/hc/pt-br/articles/32757378727060-Nota-de-Vers%C3%A3o-For%C3%A7a-de-Vendas-1-0-4',
                '_blank',
                'noreferrer',
              )
            }}
            sx={{
              fontSize: '0.7rem',
              minWidth: 0,
              pt: 0,
              pb: 0,
              pl: 0.5,
              pr: 0.5,
              color: (theme) => theme.palette.grey[500],
            }}
            variant="text"
          >
            {import.meta.env.VITE_VERSION}{' '}
            {/* <ArrowDropDownIcon sx={{ fontSize: '1.05rem' }} /> */}
          </Button>
        </Header.Content>
        <Header.Content>
          {empresas.length > 0 && (
            <>
              <Button
                onClick={openMenu}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
                variant="text"
              >
                {userLogged?.empresa.nome}
                <ArrowDropDown />
              </Button>
              <Popover
                open={isOpenMenu}
                onClose={closeMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  '& .MuiPopover-paper': {},
                }}
              >
                <React.Fragment>
                  <Surface
                    sx={{ m: 1, maxHeight: '300px', overflow: 'auto' }}
                    elevation={0}
                  >
                    {empresas.map((e) => {
                      return (
                        <MenuItem onClick={() => handleAlterarLicenca(e)}>
                          <Stack direction="row" gap={1} alignItems="center">
                            {userLogged?.empresaSelecionada === e.uuid && (
                              <Check />
                            )}
                            <Stack>
                              <Typography variant="body2">
                                {formatToCPFOrCNPJ(e.documento)}
                              </Typography>
                              <Typography variant="body2" color="#000">
                                {e.nome}
                              </Typography>
                            </Stack>
                          </Stack>
                        </MenuItem>
                      )
                    })}
                  </Surface>
                </React.Fragment>
              </Popover>
            </>
          )}
        </Header.Content>
        <Header.Content>
          <AccountPopover />
        </Header.Content>
      </Header.Root>
      <Menu.Root open={isOpen} toggleMenu={toggleMenu}>
        {memoizedRenderMenuItems()}
      </Menu.Root>
      <Box
        sx={{
          // background: '#444',
          // pt: 0.5,
          display: 'flex',
          flexDirection: 'row',
          flex: '1 1 0%',
          position: 'relative',
          overflow: 'auto',
        }}
      >
        <Sentry.ErrorBoundary
          fallback={(errorProps) => <Fallback {...errorProps} />}
        >
          {podeAcessar && children}
          {!podeAcessar && !isLoading && <Unauthorized />}
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  )
}

Layout.defaultProps = {
  isProtected: true,
}

export default Layout
