import {
  PlainQueryProps,
  HttpRequestInterface,
  HttpResponseInterface,
} from '@data-c/hooks'
import useCrud from 'hooks/useCrud'
import api from 'services/api'
import { AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'

export interface FatorPrecificacaoModel extends PlainQueryProps {
  id?: number
  uuid?: string
  codigo?: string
  descricao?: string
  observacao?: number
  pcomissao?: number
  fator: number
  ativo: boolean
}

export async function obterPorProdutoVendedor(
  produtoUuid: string,
): Promise<FatorPrecificacaoModel[]> {
  if (!produtoUuid) {
    return []
  }

  const response = await api.get<FatorPrecificacaoModel[]>(
    `/vendas/fator-precificacao/${produtoUuid}`,
  )
  return response.data
}

export function useQueryObterPorProdutoVendedor(produtoUuid: string) {
  return useQuery<FatorPrecificacaoModel[], AxiosError>(
    ['FatorPrecificacaoModel', produtoUuid],
    () => obterPorProdutoVendedor(produtoUuid),
  )
}

export default function useFatorPrecificacao() {
  return useCrud<FatorPrecificacaoModel, AxiosError>(
    'administrador/fator-precificacao',
    'Fator de Precificação',
    'male',
  )
}

export async function obterFatorPrecificacao<T>(
  params: HttpRequestInterface<T>,
): Promise<HttpResponseInterface<FatorPrecificacaoModel>> {
  const { pagination: _pagination, queryParams } = params

  const response = await api.get('administrador/fator-precificacao', {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
    },
  })

  const { data, meta: pagination } = response.data

  return { data, pagination }
}

export async function obterFatorPrecificacaoPorId(
  id?: string,
): Promise<FatorPrecificacaoModel> {
  const response = await api.get(`administrador/fator-precificacao/${id}`)
  return response.data
}
