import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useNotification from 'hooks/useNotifications'
import { obterEtiquetas, EtiquetaModel } from 'hooks/queries/useEtiqueta'
import _ from 'lodash'
export default function AutoCompleteEtiquetas(
  props: AutoCompleteProps<EtiquetaModel[]>,
) {
  const { onChange, value } = props

  const [options, setOptions] = useState<EtiquetaModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const notifications = useNotification()

  async function handleObterEtiquetas() {
    if (options?.length > 0) return
    setLoading(true)
    try {
      const etiquetas = await obterEtiquetas()
      setOptions(etiquetas)
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }
  }

  const filtered = _.differenceBy(options, value || [], 'uuid')

  return (
    <Autocomplete
      multiple
      onOpen={handleObterEtiquetas}
      disablePortal
      options={filtered || []}
      onChange={onChange}
      getOptionLabel={(etiqueta: EtiquetaModel) => {
        return `${etiqueta.nome}`
      }}
      getOptionKey={(etiqueta: EtiquetaModel) => etiqueta.uuid || ''}
      value={value || []}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          slotProps={{ inputLabel: { shrink: true } }}
          label="Etiquetas"
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
