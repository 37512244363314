import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'

import useTabelaPreco from 'hooks/queries/useTabelaPreco'

import Delete from 'components/Delete'
import Form from './components/Form'
import Table from './components/Table'
import MioFilter from 'components/MioCandidate/Filter'

import Content from 'components/Layouts/Content'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import Filter from './components/Filter'

export default function TabelasPrecos() {
  const { useDelete } = useTabelaPreco()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <MioFilter.Provider filterValues={{ ativo: true }}>
          <ContentTitle.Root>
            <Breadcrumbs
              links={[
                {
                  label: 'Tabela de Preços',
                  link: '/tabelas-precos/lista',
                },
              ]}
            />
            <Filter />
            <Form />
          </ContentTitle.Root>
          <Content>
            <Table />
          </Content>
          <Delete onDelete={handleDelete} />
        </MioFilter.Provider>
      </FormProvider>
    </Container>
  )
}
