import { Autocomplete, MenuItem, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import MioFilter from 'components/MioCandidate/Filter'
import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import FilterForm from './FilterForm'
import {
  OperacaoEnum,
  operacoesMap,
  PedidoFilters1,
  situacoes,
} from 'hooks/queries/usePedidos'
import { DateTime } from 'luxon'
import DateRangePicker from 'components/Inputs/DateRangePicker'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import AutoCompleteEtiquetas from 'components/AutocompleteEtiquetas'

export default function Filter() {
  const { changeFilterValue, applyFilterValues, onChangeFilterValue } =
    useFilterApi<{
      plainQuery?: string
    }>()

  const { user, temPermissao } = useUserContext()
  const { appliedValues, filterValues } = useFilter<PedidoFilters1>()
  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Negociações"
        />

        <MioFilter.Chips>
          <MioFilter.Chip
            disabledDelete
            name="dateRange"
            label={`${DateTime.fromFormat(
              appliedValues?.dateRange?.firstDate || '',
              'yyyy-MM-dd',
            ).toFormat('dd/MM/yyyy')} até ${DateTime.fromFormat(
              appliedValues?.dateRange?.secondDate || '',
              'yyyy-MM-dd',
            ).toFormat('dd/MM/yyyy')}`}
          >
            <DateRangePicker
              onChange={(value) => {
                changeFilterValue('dateRange', value)
              }}
              value={appliedValues?.dateRange}
            />
          </MioFilter.Chip>

          <MioFilter.Chip name="codigo" label={appliedValues?.codigo || ''}>
            <TextField
              label="Código"
              name="codigo"
              value={filterValues?.codigo || ''}
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>

          <MioFilter.Chip name="carga" label={appliedValues?.carga || ''}>
            <TextField
              name="carga"
              label="Núm. da Carga"
              value={filterValues?.carga || ''}
              onChange={onChangeFilterValue}
            />
          </MioFilter.Chip>

          <MioFilter.Chip
            name="etiqueta"
            label={appliedValues?.etiqueta?.map((m) => m.nome).join(', ') || ''}
          >
            <AutoCompleteEtiquetas
              value={filterValues?.etiqueta || []}
              onChange={(_: any, value: any) =>
                changeFilterValue('etiqueta', value)
              }
            />
          </MioFilter.Chip>

          <MioFilter.Chip
            name="situacao"
            label={
              appliedValues?.situacao?.map((m) => m.label).join(', ') || ''
            }
          >
            <Autocomplete
              multiple
              options={situacoes}
              getOptionLabel={(option) => option?.label}
              value={filterValues?.situacao || []}
              disableCloseOnSelect
              onChange={(_: any, value: any) =>
                changeFilterValue('sitaucao', value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{ inputLabel: { shrink: true } }}
                  label="Situação"
                />
              )}
            />
          </MioFilter.Chip>

          <MioFilter.Chip
            name="operacao"
            label={operacoesMap[appliedValues?.operacao || '']}
          >
            <TextField
              label="Operação"
              name="operacao"
              value={filterValues?.operacao}
              onChange={onChangeFilterValue}
              select
            >
              <MenuItem value={OperacaoEnum.VENDA}>Venda</MenuItem>
              <MenuItem value={OperacaoEnum.BONIFICACAO}>Bonificação</MenuItem>
            </TextField>
          </MioFilter.Chip>

          <MioFilter.Chip
            name="representante"
            label={appliedValues?.representante?.nome}
          >
            <TimeVendasTransporter
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="vendedorUuid"
              label="Vendedor"
              value={filterValues?.representante || null}
              onChange={(representante) => {
                changeFilterValue('representante', representante)
              }}
              slotProps={{ inputLabel: { shrink: true } }}
            />
          </MioFilter.Chip>

          <MioFilter.Chip name="cliente" label={appliedValues?.cliente?.nome}>
            <ClientesTransporter
              name="clienteUuid"
              label="Cliente"
              value={filterValues?.cliente || null}
              vendedorUuid={
                permissaoParaLerClientes === 'pessoal' ? user?.uuid : undefined
              }
              onChange={(cliente) => {
                changeFilterValue('cliente', cliente)
              }}
              slotProps={{ inputLabel: { shrink: true } }}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>
        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
