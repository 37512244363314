import { TabelaPrecoModel } from 'hooks/queries/useTabelaPreco'
import Form from 'pages/TabelasPrecos/components/Form'

interface TabCadastroTabelaPrecoProps {
  tabelaPreco: TabelaPrecoModel
}

export default function TabCadastroTabelaPreco(
  props: TabCadastroTabelaPrecoProps,
) {
  const { tabelaPreco } = props

  return <Form view="plain" data={tabelaPreco} />
}
