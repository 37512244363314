import { useState } from 'react'
import { TransporterProps } from '@data-c/ui'

import { CorModel, useQueryItemTabelaPreco } from 'hooks/queries/useTabelaPreco'

import ItensTabelaPrecoTable from 'pages/TabelasPrecos/components/ItensTabelaPrecoTable'
import Transporter from 'components/MioCandidate/Transporter'
import { ProdutoModel } from 'hooks/queries/useProdutos'
import { ItemPedidoModel } from 'hooks/queries/usePedidos'
import Filter from 'pages/Produtos/components/Filter'
import { FormControlLabel, Switch } from '@mui/material'
import { useQuery } from 'hooks/queries/useConfiguracoes'

type ItensTabelaPrecoTransporterProps = {
  tabelaPrecoUuid: string
  onChange: (value: ProdutoTransporter | null, prontaEntrega?: boolean) => void
  prontaEntrega?: boolean
} & Omit<
  TransporterProps<ProdutoTransporter>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options' | 'onChange'
>

export interface ProdutoTransporter extends ItemPedidoModel {
  uuidItem?: string
  produto: ProdutoModel
  cor?: CorModel
  grade?: CorModel
  // quantidade?: number
  precoDeTabela: number
  valorUnitarioIpi: number
  percentualComissao: number
  estoque: number
  valorPonto?: number
  ultimaCompra?: string
  ultimoPreco?: number
  maiorPreco?: number
  menorPreco?: number
  quantidadeItensComprados?: number
  quantidadeRecorrenteCompra?: number
}

export default function ItensTabelaPrecoTransporter(
  props: ItensTabelaPrecoTransporterProps,
) {
  const {
    value,
    onChange,
    tabelaPrecoUuid,
    prontaEntrega: _prontaEntrega,
    ...rest
  } = props
  const [query, setQuery] = useState<string | null>(null)
  const { data: configuracoes } = useQuery()
  const [prontaEntrega, setProntaEntrega] = useState<boolean>(
    Boolean(_prontaEntrega),
  )
  console.log('value', value)
  const { data, isLoading } = useQueryItemTabelaPreco(tabelaPrecoUuid, {
    queryParams: {
      plainQuery: query,
      prontaEntrega: Boolean(prontaEntrega),
      ativo: true,
    },
    pagination: {
      page: 1,
      pageSize: 20,
    },
  })

  function handleSearch(q: string) {
    setQuery(q)
  }

  function handleChange(value: ProdutoTransporter | null) {
    onChange(value, prontaEntrega)
  }

  return (
    <Transporter
      onChange={handleChange}
      onSearch={handleSearch}
      dialogProps={{
        title: 'Produtos',
        maxWidth: 'lg',
        fullWidth: true,
        // sx: { height: '900px' },
      }}
      options={data?.data || []}
      isLoading={isLoading}
      table={ItensTabelaPrecoTable}
      tableProps={{
        tabelaPrecoUuid,
        prontaEntrega,
      }}
      filter={Filter}
      label="Produto"
      renderValue={(value) => {
        return `${value?.produto?.codigo || ''} - ${value?.produto?.nome || ''}`
      }}
      value={value}
      endAdornment={
        Boolean(configuracoes?.usa_pedido_pronta_entrega) ? (
          <FormControlLabel
            sx={{
              border: (theme) => `1px solid ${theme.palette.border}`,
              borderRadius: '90px',
              height: '26px',
              pl: 1,
              mr: 1,
            }}
            checked={prontaEntrega}
            onChange={(e) => {
              //@ts-ignore
              setProntaEntrega(e.target.checked)
            }}
            control={<Switch color="primary" />}
            label="Pronta Entrega"
            labelPlacement="start"
          />
        ) : null
      }
      {...rest}
    />
  )
}
