import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Popover,
  Stack,
  Avatar,
  useTheme,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ArrowDropDown, HelpOutline } from '@mui/icons-material'
import Icon from 'components/MioCandidate/Icon'

import usePopOver from 'hooks/usePopover'
import { useCredentials, useDialog } from '@data-c/hooks'
import credentialsConfig from 'configs/credentials'
import { useUserContext } from 'components/Contexts/UserContext'
import useNotification from 'hooks/useNotifications'
import { AlterarSenha } from '@data-c/ui'

export default function AccountPopover() {
  const notification = useNotification()
  const theme = useTheme()
  const navigate = useNavigate()
  const { user } = useUserContext()
  const { logout, userLogged } = useCredentials(credentialsConfig)

  const {
    open: openMenu,
    close: closeMenu,
    isOpen: isOpenMenu,
    anchorEl,
  } = usePopOver()

  const {
    isOpen: isOpenAlterarSenha,
    openDialog: openAlterarSenha,
    closeDialog: closeAlterarSenha,
  } = useDialog()

  function handleOpen(event: any) {
    openMenu(event)
  }

  function handleLogout() {
    logout()
    window.location.href = '/'
    // dispatch({ type: 'USER_LOGOUT' })
  }

  function handleOpenAlterarSenha() {
    openAlterarSenha()
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alginItems: 'center' }}>
        <Button
          variant="text"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleOpen}
        >
          {userLogged ? `${userLogged.name}` : 'Usuário do Sistema'}
          <ArrowDropDown />
        </Button>
      </Box>

      <Popover
        open={isOpenMenu}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiPopover-paper': {
            // background: theme.palette.grey[100],
          },
        }}
      >
        <Stack
          sx={{ m: 2, minWidth: '300px' }}
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Avatar
            alt="photoURL"
            sx={{
              width: '64px',
              height: '64px',
              border: `solid 2px ${
                user?.isadmin ? theme.palette.grey[100] : 'transparent'
              }`,
            }}
          />
          <Stack justifyContent="center" alignItems="center" spacing={0}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Olá, {userLogged ? `${userLogged.name}` : 'Usuário do Sistema'}!
            </Typography>
            <Typography variant="body2">
              {userLogged ? userLogged.email : '---'}
            </Typography>
          </Stack>

          {/* <Stack justifyContent="center" alignItems="center" spacing={0}>
            <Typography variant="body1">
              {userLogged ? userLogged.empresa.nome : '---'}
            </Typography>
            <Typography variant="body2">
              {formatToCPFOrCNPJ(userLogged?.empresa?.documento || '')}
            </Typography>
          </Stack> */}
        </Stack>

        {/* {empresas.length > 0 && (          
        )} */}

        <Divider />

        <MenuItem onClick={handleOpenAlterarSenha} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <Icon.Password />
            Alterar Senha
          </Stack>
        </MenuItem>

        {user?.isadmin && (
          <MenuItem onClick={() => navigate('/contas')} sx={{ m: 1 }}>
            <Stack direction="row" gap={1}>
              <Icon.Users />
              Usuários do Sistema
            </Stack>
          </MenuItem>
        )}

        {user?.isadmin && (
          <MenuItem onClick={() => navigate('/configuracoes')} sx={{ m: 1 }}>
            <Stack direction="row" gap={1}>
              <Icon.Configs />
              Configurações
            </Stack>
          </MenuItem>
        )}
        <Divider />

        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            window.open(
              'https://datacsistemashelp.zendesk.com/hc/pt-br/categories/360001609791-For%C3%A7a-de-Vendas',
              '_blank',
              'noreferrer',
            )
          }}
          sx={{ m: 1 }}
        >
          <Stack direction="row" gap={1}>
            <HelpOutline />
            Manual do Sistema
          </Stack>
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <Icon.Logout />
            Sair
          </Stack>
        </MenuItem>
      </Popover>

      <AlterarSenha
        credentialsConfig={credentialsConfig}
        isOpen={isOpenAlterarSenha}
        onClose={closeAlterarSenha}
        onFailed={(err: any) => {
          notification.notifyException(err)
        }}
        onSuccess={() => {
          notification.notifySuccess('Sua senha foi alterada com sucesso')
          closeAlterarSenha()
        }}
      />

      {/* <AlterarFotoPerfil
        isOpen={isOpenAlterarFotoPerfil}
        anchorEl={anchorEl}
        onClose={closeAlterarFotoPerfil}
      /> */}
    </Box>
  )
}
