export default class ParseTo {
  public static number(value: string | number) {
    if (value === null) return 0.0
    return parseFloat(String(value))
  }

  public static boolean(value: string | number): boolean {
    if (value === 'false') return false
    return value ? true : false
  }

  public static onlyNumbers(value: string, allowComma: boolean = true) {
    if (typeof value !== 'string' || !value) return value

    let regex = /[^\d]+/g
    if (allowComma) {
      regex = /[^\d|,]+/g
    }

    const formated = value?.replace(regex, '')
    return formated
  }
}
