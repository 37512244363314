import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import Delete from 'components/Delete'
import Form from './components/Form'
import Content from 'components/Layouts/Content'
import useFunilVenda from 'hooks/queries/useFunilVenda'
import Table from './components/Table'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'

export default function FunisDeVendas() {
  const { useDelete } = useFunilVenda()
  const { mutateAsync: handleDelete } = useDelete()
  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Funil de Vendas',
                link: '/funis_venda/lista',
              },
            ]}
          />

          <Form />

          {/* <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28518211079316-Cadastro-de-Clientes" /> */}
        </ContentTitle.Root>

        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
