import { Container, Grid2 as Grid, TextField, Switch } from '@mui/material'

import { Button, Content } from '@data-c/ui'
import Error from 'components/Displays/Error'
import Loading from 'components/Displays/Loading'

import {
  ConfiguracoesModel,
  useQuery,
  useSubmit,
} from 'hooks/queries/useConfiguracoes'

import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import { useForm } from '@data-c/hooks'
import CardConfig from './components/CardConfig'
import DropDownUsaCondicaoPagamento from './components/DropDownUsaCondicaoPagamento'
import DropDownUsaTabelaPreco from './components/DropDownUsaTabelaPreco'
import DropDownUsaFormaPagamento from './components/DropDownUsaFormaPagamento'
import DropDownUsaEstoque from './components/DropDownUsaEstoque'
import { useEffect } from 'react'

export default function Configuracoes() {
  const { data: config, isLoading, error } = useQuery()

  const { data, handleChange, changeValue, setData } = useForm<
    ConfiguracoesModel | undefined
  >(config)
  const { mutateAsync: salvarAlteracoes, isLoading: isSubmitting } = useSubmit()

  useEffect(() => {
    if (config) setData(config)
  }, [config])

  function handleSubmit() {
    if (data) {
      salvarAlteracoes(data)
    }
  }

  return (
    <Container>
      <ContentTitle.Root>
        <Breadcrumbs
          links={[
            {
              label: 'Configurações',
              link: '/configuracoes',
            },
          ]}
        />
        <Button
          sx={{ minWidth: '120px' }}
          isLoading={isSubmitting}
          variant="contained"
          onClick={handleSubmit}
        >
          Salvar Alterações
        </Button>
        {/* <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28518211079316-Cadastro-de-Clientes" /> */}
      </ContentTitle.Root>

      <Content>
        {isLoading && <Loading />}
        {Boolean(error) && <Error />}
        {!isLoading && Boolean(!error) && (
          <Grid container spacing={1}>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Duplicata"
                description="Representa o número de dias de tolerância após o vencimento de uma duplicata "
              >
                <TextField
                  name="usa_duplicata"
                  value={data?.usa_duplicata || ''}
                  onChange={(e) => {
                    const value = e.target.value
                    const parsedValue = parseInt(value)
                    changeValue('usa_duplicata', parsedValue || null)
                  }}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Quantidade por Volume"
                description="Habilita o lançamento de quantidade por volume nos itens do pedido"
              >
                <Switch
                  name="usa_qt_volume"
                  checked={Boolean(data?.usa_qt_volume)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Fator de Comissão"
                description="Habilita a ferramenta de calculo dos itens através do fator de comissão"
              >
                <Switch
                  name="usa_fator"
                  checked={Boolean(data?.usa_fator)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Tabela de Preços"
                description="Define como as tabelas de preços serão habilitadas para cada tipo de usuário vendedor e/ou cliente"
              >
                <DropDownUsaTabelaPreco
                  name="usa_tabpreco"
                  value={data?.usa_tabpreco || ''}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Condição de pagamento"
                description="Define como as condições de pagamentos serão habilitadas para cada tipo de usuário (vendedor e/ou cliente)"
              >
                <DropDownUsaCondicaoPagamento
                  name="filtra_condicaopagamento"
                  value={data?.filtra_condicaopagamento || ''}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Envia e-mail da cópia do pedido"
                description="Quando habilitado o sistema envia a cópia do pedido assim que o pedido é fechado e recebido na web."
              >
                <Switch
                  name="envia_email"
                  checked={Boolean(data?.envia_email)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Desconto dos itens"
                description="Habilita a manipulação de desconto nos itens do pedido"
              >
                <Switch
                  name="edita_desconto"
                  checked={Boolean(data?.edita_desconto)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Desconto do Pedido"
                description="Habilita a manipulação de desconto do pedido"
              >
                <Switch
                  name="edita_desconto_pedido"
                  checked={Boolean(data?.edita_desconto_pedido)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Preço Unitário"
                description="Habilita a manipulação de preço unitário do "
              >
                <Switch
                  name="edita_unitario"
                  checked={Boolean(data?.edita_unitario)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Forma de Pagamento"
                description="Define como as formas de pagamento serão habilitadas para cada tipo de usuário vendedor e/ou cliente"
              >
                <DropDownUsaFormaPagamento
                  name="usa_formapgto"
                  value={data?.usa_formapgto || ''}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Estoque"
                description="Habilita a visualização da quantidade em estoque dos produtos."
              >
                <DropDownUsaEstoque
                  name="usa_estoque"
                  value={data?.usa_estoque || 0}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Limite de Crédito"
                description="Habilita a validação no limite de crédito no pedido"
              >
                <Switch
                  name="usa_limite_credito"
                  checked={Boolean(data?.usa_limite_credito)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Desconto Agregado"
                description="Habilita a utilização do desconto agregado"
              >
                <Switch
                  name="usa_desconto_agregado"
                  checked={Boolean(data?.usa_desconto_agregado)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Crédito e Débito"
                description="Habilita a utilização do crédito e débito"
              >
                <Switch
                  name="usa_credito_debito"
                  checked={Boolean(data?.usa_credito_debito)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Desconto Progressivo"
                description="Habilita a utilização da desconto progressivo"
              >
                <Switch
                  name="usa_promocao_progressiva"
                  checked={Boolean(data?.usa_promocao_progressiva)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Casas Decimais"
                description="Se habilitado formata os valores com 4 casas decimais"
              >
                <Switch
                  name="usa_milesimal"
                  checked={Boolean(data?.usa_milesimal)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Bloqueio de venda de itens sem quantidade em estoque"
                description="Habilita o bloqueio de venda de itens sem quantidade em estoque"
              >
                <Switch
                  name="bloqueia_estoque"
                  checked={Boolean(data?.bloqueia_estoque)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
              <CardConfig
                title="Estoque Pronta Entrega"
                description="Habilita o uso do estoque de pronta entrega"
              >
                <Switch
                  name="usa_pedido_pronta_entrega"
                  checked={Boolean(data?.usa_pedido_pronta_entrega)}
                  onChange={handleChange}
                />
              </CardConfig>
            </Grid>
            {Boolean(data?.usa_pedido_pronta_entrega) && (
              <Grid size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
                <CardConfig
                  title="Baixa Automática de Estoque Pronta Entrega"
                  description="Quando habilitado o estoque de pronta entrega sofrerá baixa automática ao no fechamento da negociação"
                >
                  <Switch
                    name="usa_baixa_automatica_pe"
                    checked={Boolean(data?.usa_baixa_automatica_pe)}
                    onChange={handleChange}
                  />
                </CardConfig>
              </Grid>
            )}
          </Grid>
        )}
      </Content>
    </Container>
  )
}
