import {
  useFilter,
  useFilterApi,
} from 'components/MioCandidate/Filter/FilterContext'
import MioFilter from 'components/MioCandidate/Filter'
import { ProdutoFilters1 } from 'hooks/queries/useProdutos'
import FilterForm from './FilterForm'
import CategoriaProdutoTextField from './CategoriaProdutoTextField'

export default function Filter() {
  const { changeFilterValue, applyFilterValues } = useFilterApi()
  const { appliedValues, filterValues } = useFilter<ProdutoFilters1>()
  return (
    <MioFilter.Root>
      <MioFilter.Container>
        <MioFilter.SearchTextField
          onChange={(query) => {
            changeFilterValue('plainQuery', query)
            applyFilterValues()
          }}
          fullWidth
          autoFocus
          placeholder="Pesquisar Produtos"
        />

        <MioFilter.Chips>
          <MioFilter.Chip
            name="categoriaValue"
            label={`${appliedValues?.categoriaKey}: ${appliedValues?.categoriaValue?.nome}`}
          >
            <CategoriaProdutoTextField
              value={{
                categoriaKey: filterValues.categoriaKey || 'nenhuma',
                categoriaValue: filterValues.categoriaValue,
              }}
              onChange={(value) => {
                changeFilterValue('categoriaKey', value.categoriaKey)
                changeFilterValue('categoriaValue', value.categoriaValue)
              }}
            />
          </MioFilter.Chip>
        </MioFilter.Chips>

        <MioFilter.AdvancedButton />
      </MioFilter.Container>
      <MioFilter.AdvancedContainer>
        <FilterForm />
        <MioFilter.AdvancedActionsContainer>
          <MioFilter.ResetButton />
          <MioFilter.ApplyButton />
        </MioFilter.AdvancedActionsContainer>
      </MioFilter.AdvancedContainer>
    </MioFilter.Root>
  )
}
