import useCrud, { generateEntityKey } from 'hooks/useCrud'
import { EtapaModel } from './useFunilEtapa'
import api from 'services/api'

import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import { FilterOptions } from '@data-c/providers'
import {
  ErrorInterface,
  HttpResponseInterface,
  PaginationProps,
  SortProps,
} from '@data-c/hooks'
import { VendedorModel } from './useVendedores'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotifications'

export interface FunilVendaModel {
  uuid?: string
  nome: string
  ativo?: boolean
  etapas?: Array<EtapaModel>
}

interface QueryParamsInterface {
  plainQuery?: string | null
  funilVendaUuid?: string
  pessoaUuid?: string
  pagination: PaginationProps
}

interface VincularVendedoresInputProps {
  funilVendaUuid: string
  vendedoresSelecionadosIds: Array<string>
}

export interface FunilVendaFilter {
  nome: FilterOptions<string>
  ativo: FilterOptions<boolean>
}

export interface FunilVendaFilter1 {
  nome: string
  ativo: boolean
  plainQuery?: string
}

export const filters: FunilVendaFilter = {
  nome: {
    label: 'Nome',
  },
  ativo: {
    label: 'Ativo',
    value: true,
  },
}

export async function obterFunisRaw(): Promise<Array<FunilVendaModel>> {
  const response = await api.get(`crm/funil-venda-raw`)
  return response.data
}

export function useQueryRaw() {
  const key = generateEntityKey('Funil de Vendas')
  return useTQuery<Array<FunilVendaModel>>([key], () => {
    return obterFunisRaw()
  })
}

export async function obterVendedoresVinculados(
  params: QueryParamsInterface,
  sort?: SortProps,
): Promise<HttpResponseInterface<VendedorModel>> {
  const { pagination: _pagination, funilVendaUuid } = params

  const response = await api.get(
    `crm/funil-venda/${funilVendaUuid}/vendedores`,
    {
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export function useQueryObterVendedoresVinculados(
  params: QueryParamsInterface,
  sort?: SortProps,
) {
  return useTQuery<
    HttpResponseInterface<VendedorModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['VENDEDORES', params, sort], () => {
    return obterVendedoresVinculados(params, sort)
  })
}

export async function obterVendedoresNaoVinculados(
  params: QueryParamsInterface,
): Promise<HttpResponseInterface<VendedorModel>> {
  const { pagination: _pagination, funilVendaUuid, plainQuery } = params

  const response = await api.get(
    `crm/funil-venda/${funilVendaUuid}/vendedores-nao-vinculados`,
    {
      params: { plainQuery },
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export function useQueryObterVendedoresNaoVinculados(
  params: QueryParamsInterface,
) {
  return useTQuery<
    HttpResponseInterface<VendedorModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['VENDEDORES', params], () => {
    return obterVendedoresNaoVinculados(params)
  })
}

export async function vincularVendedores(
  funilVendaUuid: string,
  vendedoresSelecionadosIds: Array<string>,
): Promise<VendedorModel> {
  const response = await api.put(
    `/crm/funil-venda/${funilVendaUuid}/vincular-vendedores`,
    vendedoresSelecionadosIds,
    { params: { funilVendaUuid } },
  )
  return response.data
}

export async function desvincularVendedores(
  funilVendaUuid: string,
  vendedoresSelecionadosIds: Array<string>,
): Promise<VendedorModel> {
  const response = await api.put(
    `/crm/funil-venda/${funilVendaUuid}/desvincular-vendedores`,
    vendedoresSelecionadosIds,
    { params: { funilVendaUuid } },
  )

  return response.data
}

export function useVincularVendedores() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<VendedorModel, AxiosError, VincularVendedoresInputProps>(
    (data: VincularVendedoresInputProps) =>
      vincularVendedores(data.funilVendaUuid, data.vendedoresSelecionadosIds),
    {
      onSuccess(_: VendedorModel) {
        notifications.notifySuccess('Vendedores vinculados com sucesso')
        queryClient.invalidateQueries(['VENDEDORES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDesvincularVendedores() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<VendedorModel, AxiosError, VincularVendedoresInputProps>(
    (data: VincularVendedoresInputProps) =>
      desvincularVendedores(
        data.funilVendaUuid,
        data.vendedoresSelecionadosIds,
      ),
    {
      onSuccess(_: VendedorModel) {
        notifications.notifySuccess('Vendedores desvinculados com sucesso')
        queryClient.invalidateQueries(['VENDEDORES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

async function readById(
  uuid: string,
  qs?: any,
): Promise<FunilVendaModel | null> {
  if (!qs.dataInicial) return null

  const response = await api.get<FunilVendaModel>(`crm/funil-venda/${uuid}`, {
    params: qs,
  })
  return response.data
}

function useObterPorUuid(uuid: string, searchId?: string, qs?: any) {
  const entityKey = generateEntityKey('Funil de Vendas')
  return useTQuery<FunilVendaModel | null, AxiosError>(
    [entityKey, uuid, searchId, qs],
    () => {
      return readById(uuid, qs)
    },
  )
}

export default function useFunilVenda() {
  return {
    ...useCrud<FunilVendaModel>('crm/funil-venda', 'Funil de Vendas', 'male'),
    useObterPorUuid,
    useQueryRaw,
    useQueryObterVendedoresVinculados,
    useQueryObterVendedoresNaoVinculados,
    useDesvincularVendedores,
    useVincularVendedores,
  }
}
