import { Button, Popover, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import { useFilter, useFilterApi } from './FilterContext'
import { FiltrerChipProps } from './FilterChip'
import usePopOver from 'hooks/usePopover'

export interface FilterChipsProps {
  children: ReactNode
  // onChange?: () => void
  // onDelete?: () => void
}

export default function FilterChips({
  children: childrenProp,
}: FilterChipsProps) {
  const { appliedValues } = useFilter()
  const { applyFilterValues, deleteFilterValue } = useFilterApi()
  const {
    isOpen,
    open,
    close,
    anchorEl,
    data: popoverData,
  } = usePopOver<{
    name: string
    disabledDelete: boolean
    element: ReactNode
    target: HTMLElement
  }>()

  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement<FiltrerChipProps>(child)) {
      return null
    }

    const containsInAppliedValues =
      appliedValues && child.props.name in appliedValues
    if (!containsInAppliedValues) return null

    return React.cloneElement(child, {
      onDelete: !child.props.disabledDelete
        ? (_: any) => {
            if (deleteFilterValue) deleteFilterValue(child.props.name || '')
            close()
          }
        : undefined,
      onClick: (e: any) => {
        open(e, {
          target: e.currentTarget,
          element: child.props.children,
          name: child.props.name,
          disabledDelete: child.props.disabledDelete || false,
        })
      },
    })
  })

  return (
    <Stack direction="row" spacing={1}>
      {children}
      <Popover
        open={Boolean(isOpen) || false}
        onClose={() => close()}
        anchorEl={anchorEl}
        elevation={0}
        sx={{
          '& .MuiPopover-paper': {
            minWidth: '300px',
            border: (theme) => `1px solid ${theme.palette.border}`,
            borderRadius: '4px',
            pt: 2,
            pl: 1,
            pr: 1,
            pb: 2,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverData?.element}
        <Stack pt={2} direction="row" spacing={0.5} justifyContent="flex-end">
          {!popoverData?.disabledDelete && (
            <Button
              color="error"
              onClick={() => {
                if (deleteFilterValue)
                  deleteFilterValue(popoverData?.name || '')
                close()
              }}
            >
              Apagar
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => {
              close()
              applyFilterValues()
            }}
          >
            Aplicar
          </Button>
        </Stack>
      </Popover>
    </Stack>
  )
}
