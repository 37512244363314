import { Stack } from '@mui/material'
import React from 'react'

export interface OnboardingCardImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

export default function OnboardingCardImage(props: OnboardingCardImageProps) {
  return (
    <Stack
      // spacing={1}
      sx={{
        maxWidth: '300px',
      }}
    >
      <img {...props} />
    </Stack>
  )
}
