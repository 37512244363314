import { useCallback, useEffect, useMemo, useState } from 'react'

import { Stack } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { findData, formatCurrency, useDialog } from '@data-c/hooks'
import { DataTable, CurrencyCellStyle, DataTableOptions } from '@data-c/ui'

import {
  ItemPedidoModel,
  ItensPedidoSelecionados,
  PedidoModel,
  SituacaoPedidoEnum,
  useDeleteItemPedido,
  useUpdateBloqueioItemPedido,
} from 'hooks/queries/usePedidos'
// import { calcularItemPedido } from 'hooks/business/pedido'
import { UsuarioModel } from 'hooks/queries/useUsuario'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import { useUserContext } from 'components/Contexts/UserContext'
import AdicionarItem from '../AdicionarItem'
import ConfirmDialog from 'components/Displays/ConfirmDialog'
import Notifications from '../Notifications'
import { encontrarTipoVariacao } from 'hooks/business/pedido'

export interface ItensPedidoProps {
  pedido: PedidoModel
  usuarioLogado: UsuarioModel
  isLoading?: boolean
  isFetching?: boolean
  configuracoes?: ConfiguracoesModel
}

export default function ItensPedido(props: ItensPedidoProps) {
  const { pedido, usuarioLogado, configuracoes } = props
  const [value, setValue] = useState<ItemPedidoModel | null>(null)
  const { temPermissao } = useUserContext()
  const [itensPedidoSelecionados, setItensPedidoSelecionados] = useState<
    ItensPedidoSelecionados[]
  >([])
  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'
  const { mutateAsync } = useUpdateBloqueioItemPedido()
  const {
    data: dataToDelete,
    isOpen: isOpenDeleteDialog,
    openDialog: openConfirmDelete,
    closeDialog: closeConfirmDeleteDialog,
  } = useDialog<ItemPedidoModel>()
  const { mutateAsync: deleteItem, isLoading: isDeleting } =
    useDeleteItemPedido()
  const calulcaImposto =
    pedido.ajuste === 100 && configuracoes?.usa_calculo_imposto

  const podeEditar = useMemo(() => {
    const situacao = pedido.situacao

    return (
      (situacao === SituacaoPedidoEnum.WEB ||
        situacao === SituacaoPedidoEnum.MOBILE ||
        situacao === SituacaoPedidoEnum.COTACAO_SOLICITACAO ||
        situacao === SituacaoPedidoEnum.COTACAO_ANALISE ||
        situacao === SituacaoPedidoEnum.COTACAO_APROVADA) &&
      podeEditarPedido
    )
  }, [pedido, podeEditarPedido])
  let pesoTotalDoPedido = 0

  const maiorTipoVariacao = encontrarTipoVariacao(pedido.itens)

  const _itens = useMemo(
    () =>
      pedido?.itens
        ? pedido.itens.map((item) => {
            pesoTotalDoPedido += item.pesoTotal || 0
            return {
              ...item,
              codigoProduto: item.produto?.codigo,
              nomeProduto: item.produto?.nome,
              nomeCor: item.cor?.nome,
              precoTotalLiquido: formatCurrency(item.precoTotalLiquido),
              precoUnitarioLiquido: formatCurrency(item.precoUnitarioLiquido),
              precoDeTabela: formatCurrency(item.precoDeTabela),
              precoTotalIcms: formatCurrency(item.precoTotalIcms),
              precoTotalIpi: formatCurrency(item.precoTotalIpi),
              precoTotalComImposto: formatCurrency(item.precoTotalLiquido),
              percentualDesconto: formatCurrency(item.percentualDesconto),
              fatorPrecificacaoNome: item.fatorPrecificacao?.descricao,
              pesoAsText: `${item?.pesoTotal || ''} Kg`,
            }
          })
        : [],
    [pedido],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'perder_item', data: ItemPedidoModel) => {
      switch (event) {
        case 'edit':
          setValue(data)
          break
        case 'delete':
          openConfirmDelete(data)
          break
        case 'perder_item':
          // openDialog(data)
          break
      }
    },
    [],
  )

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      await updateBloqueioItemPedido()
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [itensPedidoSelecionados])

  async function updateBloqueioItemPedido() {
    const possuiItensPedido = itensPedidoSelecionados.length > 0

    if (possuiItensPedido) {
      await mutateAsync({
        itensPedidoSelecionados,
      })

      setItensPedidoSelecionados([])
    }
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
        options: {
          setCellProps: () => ({
            style: {
              minWidth: '240px',
            },
          }),
        },
      },
      {
        name: 'nomeCor',
        label: 'Variação 1',
        options: {
          display: maiorTipoVariacao > 0,
          setCellProps: () => ({
            style: {
              // minWidth: '240px',
            },
          }),
        },
      },
      {
        name: 'previsaoCompraAsBrazilianDate',
        label: 'Previsão',
        options: {
          display: Boolean(configuracoes?.exibe_previsaocompra),
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'pesoAsText',
        label: `Peso (${pesoTotalDoPedido} Kg)`,
        options: {
          display: Boolean(configuracoes?.exibe_peso),
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'fatorPrecificacaoNome',
        label: 'Fator',
        options: {
          display: Boolean(configuracoes?.usa_fator),
          setCellProps: () => ({
            style: {
              width: '96px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'precoDeTabela',
        label: 'Preço de Tabela',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'percentualDesconto',
        label: 'Desconto(%)',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoUnitarioLiquido',
        label: 'Preço de Venda',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalLiquido',
        label: `Total (R$${formatCurrency(pedido.totalLiquido)})`,
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalIpi',
        label: `Total IPI (R$${formatCurrency(pedido.totalIpi)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalComImposto',
        label: `Total Nota (R$${formatCurrency(pedido.totalComImposto)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          setCellProps: CurrencyCellStyle,
        },
      },
      // {
      //   name: 'itemBloqueado',
      //   label: 'Bloqueado',
      //   options: {
      //     display: usuarioLogado.isadmin,
      //     setCellProps: () => ({
      //       style: {
      //         width: '70px',
      //         height: '42px',
      //         textAlign: 'center',
      //       },
      //     }),
      //     customBodyRender: (value, tableMeta) => {
      //       const rowIndex = tableMeta.rowIndex
      //       const itemPedidoId = pedido.itens[rowIndex].uuid || ''

      //       return (
      //         <>
      //           {itensPedidoSelecionados.some(
      //             (item) => item.itemPedidoId === itemPedidoId,
      //           ) ? (
      //             <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      //               <CircularProgress size={14} />
      //             </Box>
      //           ) : (
      //             <Checkbox
      //               disabled={!podeEditar}
      //               name="itemBloqueado"
      //               checked={Boolean(value)}
      //               onChange={(e) => {
      //                 const isChecked = e.target.checked

      //                 setItensPedidoSelecionados((prevState) => [
      //                   ...prevState,
      //                   { itemPedidoId, itemBloqueado: isChecked },
      //                 ])
      //               }}
      //             />
      //           )}
      //         </>
      //       )
      //     },
      //   },
      // },
      // {
      //   name: 'precoTotalIcms',
      //   label: 'ICMS',
      //   options: {
      //     setCellProps: CurrencyCellStyle,
      //   },
      // },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          display: podeEditar,
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => ({
            style: {
              width: '200px',
              textAlign: 'center',
            },
            // textAlign: 'center',
          }),
          customBodyRender: (value) => {
            const _data = findData(pedido.itens || [], value)

            const itemBloqueadoParaNaoAdministradores =
              !usuarioLogado.isadmin && _data?.itemBloqueado === true
            return (
              <DataTableOptions
                disabled={itemBloqueadoParaNaoAdministradores}
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
                // extraOptions={[
                //   {
                //     id: 'perder_item',
                //     icon: 'archive',
                //     visible: true,
                //     iconButtonProps: {
                //       color: 'primary',
                //     },
                //   },
                // ]}
              />
            )
          },
        },
      },
    ],
    [pedido, handleClickItem, itensPedidoSelecionados, configuracoes],
  )

  async function handleDelete() {
    if (dataToDelete) {
      await deleteItem(dataToDelete)
      closeConfirmDeleteDialog()
    }
  }

  const notifications = useMemo(() => {
    const toReturn: Array<string> = []

    const temDescontoAgregado =
      Array.isArray(pedido?.tabelaPreco?.descontoAgregado) &&
      pedido?.tabelaPreco.descontoAgregado.length > 0

    if (temDescontoAgregado) {
      pedido?.tabelaPreco?.descontoAgregado?.forEach((d) => {
        const value =
          d.pesoValor === 1
            ? formatCurrency(d.pesoValorMinimo)
            : `${d.pesoValorMinimo} Kg`

        const msg = `${d.pdesconto}% de desconto acima de ${value}`
        toReturn.push(msg)
      })
    }

    return toReturn
  }, [pedido])

  return (
    <Stack spacing={1}>
      {podeEditar && (
        <Stack direction="row" spacing={1}>
          {notifications.length > 0 && (
            <Notifications notifications={notifications} />
          )}

          <AdicionarItem
            calculaImposto={calulcaImposto}
            tabelaPrecoUuid={pedido?.tabelaPrecoUuid || ''}
            pedido={pedido || ({} as PedidoModel)}
            value={value}
            onReset={() => {
              setValue(null)
            }}
          />
        </Stack>
      )}

      <DataTable
        columns={columns}
        data={_itens}
        options={{ pagination: false, responsive: 'standard' }}
      />
      {/* <PerderItemForm
        isOpen={isOpen}
        closeDialog={closeDialog}
        item={itemPerdidoSelecionado}
      /> */}

      <ConfirmDialog
        isOpen={isOpenDeleteDialog}
        isLoading={Boolean(isDeleting)}
        close={closeConfirmDeleteDialog}
        onConfirm={handleDelete}
        // message="Você quer remover essa foto?"
        confirmLabel="Sim"
      />
    </Stack>
  )
}
