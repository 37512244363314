import { formatCurrency, onlyNumbers, useForm } from '@data-c/hooks'
import {
  Button,
  ButtonContainer,
  // Checkbox,
  CurrencyTextField,
} from '@data-c/ui'
import TextField from 'components/Inputs/TextField'
import { Grid2 as Grid, Stack, Typography } from '@mui/material'
import { ItemPedidoModel } from 'hooks/queries/usePedidos'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import { useUserContext } from 'components/Contexts/UserContext'
import { useCalcularItemPedido } from 'hooks/business/pedido'
import Divider from 'components/Divider'
import DropDownFatorPrecificacao1 from 'components/Inputs/DropDownFatorPrecificacao1'
import { MixProdutosModel } from 'hooks/queries/useMixProdutos'
import { memo, useEffect, useMemo } from 'react'
import ParseTo from 'utils/ParseTo'

export interface FormProps {
  autoFocus?: boolean
  isSubmitting?: boolean
  item: ItemPedidoModel
  calculaImposto?: boolean
  fatorFinanceiro?: number
  mixProdutos?: Array<MixProdutosModel>
  onClose?: () => void
  onSave?: (item: ItemPedidoModel) => void
  onChange?: (item: ItemPedidoModel) => void
  index?: number
  validationProps?: (fieldName: string) => {
    helperText: string
    error: boolean
  }
}

const Form = (props: FormProps) => {
  const {
    onChange: onFormChange,
    autoFocus,
    mixProdutos,
    isSubmitting,
    item: data,
    calculaImposto = false,
    onClose,
    validationProps,
    onSave,
    fatorFinanceiro = 1,
    index,
  } = props
  const {
    data: rawItem,
    handleChange,
    changeValue,
    reason = 'precoUnitarioIpi',
  } = useForm<ItemPedidoModel>(data)
  const { data: configuracoes } = useQuery()
  const { user } = useUserContext()

  const mixProduto = useMemo(() => {
    const mixEncontrado =
      mixProdutos?.find((m) => {
        const encontrado = m.produtos?.some((p) => {
          const some = p.uuid === rawItem.produto?.uuid
          return some
        })
        return encontrado
      }) || null
    const m = { ...mixEncontrado }
    if (Boolean(data?.uuid) && mixEncontrado) {
      const quantidadeHistorica = data.quantidade
      m.quantidade =
        ParseTo.number(m?.quantidade || 0) - ParseTo.number(quantidadeHistorica)
    }
    return m as MixProdutosModel
  }, [mixProdutos, rawItem, data])
  const item = useCalcularItemPedido(
    rawItem,
    reason,
    fatorFinanceiro,
    mixProduto,
  )

  useEffect(() => {
    if (onFormChange) {
      onFormChange(item)
    }
  }, [onFormChange, item])

  const usaFator = Boolean(configuracoes?.usa_fator)
  const fatorBreakPoint = usaFator ? 2 : 4

  function generatePropsName(name: string, index?: number) {
    if (index !== undefined) {
      return `[${index}].${name}`
    }
    return name
  }

  return (
    <Stack spacing={1}>
      <Grid container spacing={1} sx={{ pt: 1 }}>
        {item?.estoque !== undefined && (
          <Grid size={12} sx={{ textAlign: 'right', mb: 1 }}>
            <Typography variant="h6">{`Estoque: ${
              item?.estoque || 0
            }`}</Typography>
          </Grid>
        )}
        <Grid
          size={{ xs: 12, sm: 6, md: fatorBreakPoint, lg: fatorBreakPoint }}
        >
          {Boolean(item?.produto?.fracionado) ? (
            <CurrencyTextField
              autoFocus={autoFocus}
              name={generatePropsName('quantidade', index)}
              label="Quantidade"
              required
              value={item?.quantidade || ''}
              onChange={(_, value) => {
                // const quantidade = onlyNumbers(value, false)
                changeValue('quantidade', value)
              }}
              {...(validationProps
                ? validationProps(generatePropsName('quantidade', index))
                : {})}
            />
          ) : (
            <TextField
              // inputRef={qtdRef}
              autoFocus={autoFocus}
              name={generatePropsName('quantidade', index)}
              label="Quantidade"
              required
              value={item?.quantidade || ''}
              onChange={(e) => {
                const quantidade = onlyNumbers(e.target.value, false)
                changeValue('quantidade', quantidade)
              }}
              {...(validationProps
                ? validationProps(generatePropsName('quantidade', index))
                : {})}
              sx={{
                '& .MuiInputBase-input': {
                  textAlign: 'right', // Alinha o texto à direita
                },
              }}
            />
          )}
        </Grid>
        {usaFator && (
          <Grid
            size={{ xs: 12, sm: 6, md: fatorBreakPoint, lg: fatorBreakPoint }}
          >
            <DropDownFatorPrecificacao1
              label="Fator Precificação"
              name={generatePropsName('fatorPrecificacao.uuid', index)}
              produtoUuid={item.produto?.uuid || ''}
              value={item?.fatorPrecificacao || null}
              onChange={(fator) => {
                changeValue('fatorPrecificacao', fator)
              }}
              onLoad={(fator) => {
                if (!item?.fatorPrecificacao) {
                  changeValue('fatorPrecificacao', fator)
                }
              }}
              {...(validationProps
                ? validationProps(
                    generatePropsName('fatorPrecificacao.uuid', index),
                  )
                : {})}
            />
          </Grid>
        )}

        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
          <CurrencyTextField
            name="precoDeTabela"
            label="Preço de Tabela (R$)"
            required
            value={item?.precoDeTabela || ''}
            disabled
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
          <CurrencyTextField
            disabled={Boolean(!configuracoes?.edita_desconto)}
            name="percentualDesconto"
            label="Desconto (%)"
            value={item?.percentualDesconto || ''}
            onChange={(_, value) => {
              changeValue('percentualDesconto', value, 'percentualDesconto')
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
          <CurrencyTextField
            disabled={Boolean(!configuracoes?.edita_unitario)}
            name={generatePropsName('precoUnitarioLiquido', index)}
            label="Preço de Venda (R$)"
            required
            value={item?.precoUnitarioLiquido || ''}
            onChange={(_, value) => {
              changeValue('precoUnitarioLiquido', value, 'precoUnitarioLiquido')
            }}
            {...(validationProps
              ? validationProps(
                  generatePropsName('precoUnitarioLiquido', index),
                )
              : {})}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
          <CurrencyTextField
            name={generatePropsName('precoTotalLiquido', index)}
            label="Preço Total (R$)"
            disabled
            value={item?.precoTotalLiquido || ''}
            {...(validationProps
              ? validationProps(generatePropsName('precoTotalLiquido', index))
              : {})}
          />
        </Grid>
      </Grid>
      {Boolean(configuracoes?.usa_calculo_imposto) && (
        <Grid container spacing={1} sx={{ pt: 1 }} justifyContent="flex-end">
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
            <CurrencyTextField
              name={generatePropsName('percentualIpi', index)}
              label="IPI (%)"
              value={item?.percentualIpi || ''}
              onChange={(_, value) => {
                changeValue('percentualIpi', value, 'percentualIpi')
              }}
              {...(validationProps
                ? validationProps(generatePropsName('percentualIpi', index))
                : {})}
              disabled={!calculaImposto || !user?.isadmin}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
            <CurrencyTextField
              name={generatePropsName('precoUnitarioIpi', index)}
              label="Preço Uni. IPI (R$)"
              value={item?.precoUnitarioIpi || ''}
              onChange={(_, value) =>
                changeValue('precoUnitarioIpi', value, 'precoUnitarioIpi')
              }
              {...(validationProps
                ? validationProps(generatePropsName('precoUnitarioIpi', index))
                : {})}
              disabled={!calculaImposto || !user?.isadmin}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
            <CurrencyTextField
              disabled
              name="precoTotalIpi"
              label="Preço Total IPI (R$)"
              value={item?.precoTotalIpi || ''}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2 }}>
            <CurrencyTextField
              disabled
              name="precoTotalComImposto"
              label="Total Nota (R$)"
              value={item?.precoTotalComImposto || ''}
              // inputProps={{
              //   style: {
              //     textAlign: 'right',
              //     fontWeight: 'bold',
              //   },
              // }}
            />
          </Grid>
        </Grid>
      )}

      {Boolean(configuracoes?.edita_observacao) && (
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid size={{ xs: 12 }}>
            <TextField
              name="observacao"
              label="Observação"
              value={item?.observacao || ''}
              multiline
              rows={2}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      )}
      {mixProduto?.uuid && (
        <Stack
          sx={{
            border: (theme) => `1px solid ${theme.palette.border}`,
            borderRadius: '4px',
            p: 1,
          }}
          spacing={1}
        >
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bolder' }}
            >{`Tabela de descontos do Mix ${
              mixProduto?.nome || ''
            }`}</Typography>
            <Typography variant="subtitle1">{`Qtd: ${
              ParseTo.number(mixProduto.quantidade || 0) +
              ParseTo.number(item.quantidade || 0)
            }`}</Typography>
          </Stack>
          <Divider />
          {mixProduto?.descontos?.map((d) => {
            const selected = item.descontoAplicado?.uuid === d.uuid
            return (
              <Stack
                sx={{
                  background: (theme) =>
                    selected ? theme.palette.background.paper : 'inherite',
                  borderRadius: '4px',
                }}
                spacing={1}
                direction="row"
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    color: (theme) =>
                      selected ? theme.palette.primary.main : 'inherite',
                    fontWeight: selected ? 'bolder' : 'none',
                  }}
                >
                  {d.quantidadeMinima}
                </Typography>
                <Typography
                  sx={{
                    color: (theme) =>
                      selected ? theme.palette.primary.main : 'inherite',
                    fontWeight: selected ? 'bolder' : 'none',
                  }}
                >
                  {d.preco
                    ? formatCurrency(d.preco)
                    : `${formatCurrency(d.pdesconto)}%`}
                </Typography>
              </Stack>
            )
          })}
        </Stack>
      )}
      {/* <Grid container spacing={1} justifyContent="flex-end">
        {user?.isadmin && (
          <Grid size={{ xs: 12 }}>
            <Checkbox
              label="Bloqueado"
              name="itemBloqueado"
              checked={Boolean(item?.itemBloqueado)}
              onChange={handleChange}
            />
          </Grid>
        )}
      </Grid> */}
      {onSave && (
        <>
          <Divider />
          <ButtonContainer>
            <Button
              onClick={() => {
                if (onClose) onClose()
              }}
              color="error"
            >
              Cancelar
            </Button>
            <Button
              isLoading={Boolean(isSubmitting)}
              onClick={() => onSave(item)}
              variant="contained"
            >
              Salvar
            </Button>
          </ButtonContainer>
        </>
      )}
    </Stack>
  )
}

export default memo(Form)
