import { Stack, Typography } from '@mui/material'
import {
  ItemPedidoModel,
  PedidoModel,
  useSalvarItensPedido,
} from 'hooks/queries/usePedidos'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import { useQueryObterItensNivel1 } from 'hooks/queries/useTabelaPreco'
import { useCallback, useEffect, useState } from 'react'
import Form from './Form'
import { ButtonContainer, Button } from '@data-c/ui'
import * as yup from 'yup'
import { isValidNumber } from '@data-c/hooks'
import useValidations from 'hooks/useValidations'
import useNotification from 'hooks/useNotifications'
import queryClient from 'services/query-client'
export interface FormVariacao1Props {
  produto: ItemPedidoModel
  pedido: PedidoModel
  calculaImposto?: boolean
  onClose?: () => void
  prontaEntrega?: boolean
}
const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.array().of(
  yup.object().shape({
    // quantidade: yup
    //   .number()
    //   .nullable(true)
    //   .transform(transform)
    //   .when('estoque', (estoque, schema) => {
    //     return schema.max(
    //       estoque,
    //       'A quantidade não pode ultrapassar q quantidade disponível no estoque',
    //     )
    //   }),

    precoUnitarioLiquido: yup
      .number()
      .nullable(true)
      .required('Informe o Preço de Venda')
      .moreThan(0, 'O Preço de Venda deve ser maior do que R$0,00')
      .transform(transform),
    percentualIpi: yup
      .number()
      .nullable(true)
      .when('$calculaImposto', (calculaImposto, schema) => {
        if (calculaImposto) {
          return yup
            .number()
            .nullable(true)
            .required('Informe o IPI (%)')
            .transform(transform)
        }
        return schema
      }),
    precoUnitarioIpi: yup
      .number()
      .nullable(true)
      .when('$calculaImposto', (calculaImposto, schema) => {
        if (calculaImposto) {
          return yup
            .number()
            .nullable(true)
            .required('Informe o IPI (R$)')
            .transform(transform)
        }
        return schema
      }),
    fatorPrecificacao: yup
      .object()
      .nullable(true)
      .when('$usaFator', (usaFator, schema) => {
        if (usaFator) {
          return yup
            .object()
            .shape({
              uuid: yup.string().required('Informe o Fator de Precificação'),
            })
            .nullable(true)
            .required('Informe o Fator de Precificação')
        }
        return schema
      }),
    precoTotalLiquido: yup
      .number()
      .nullable(true)
      .when('quantidade', (quantidade, schema) => {
        if (quantidade > 0) {
          return yup
            .number()
            .nullable(true)
            .required('O Preço Total não pode ser um valor vazio')
            .moreThan(0, 'Preço Total deve ser maior do que R$0,00')
            .transform(transform)
        }
        return schema
      }),
  }),
)

export default function FormVariacao1(props: FormVariacao1Props) {
  const {
    produto,
    pedido,
    calculaImposto = false,
    prontaEntrega = false,
    onClose,
  } = props
  const { data: configuracoes } = useQuery()
  const [itens, setItens] = useState<Array<ItemPedidoModel>>([])
  const { setValidationErrors, validationProps } = useValidations()
  const { mutateAsync, isLoading } = useSalvarItensPedido()
  const { data: produtos } = useQueryObterItensNivel1(
    pedido?.tabelaPrecoUuid || '',
    produto?.produto?.uuid || '',
    pedido?.uuid || '',
    prontaEntrega,
  )
  const notifications = useNotification()
  const usaFator = Boolean(configuracoes?.usa_fator)

  useEffect(() => {
    if (Array.isArray(produtos)) {
      setItens(produtos)
    }
  }, [produtos])

  const handleChange = useCallback((item: ItemPedidoModel) => {
    setItens((prev) =>
      prev?.map((i) => {
        if (i?.cor?.uuid === item.cor?.uuid) {
          return item
        }
        return i
      }),
    )
  }, [])

  function handleSave() {
    setValidationErrors(null)
    schema
      .validate(itens, {
        abortEarly: false,
        context: {
          usaFator: Boolean(usaFator),
          calculaImposto: Boolean(calculaImposto),
        },
      })
      .then(async () => {
        await mutateAsync({
          pedidoUuid: pedido?.uuid || '',
          itens: itens,
        })
        if (onClose) onClose()
        notifications.notifySuccess(`Operação realizada com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      })
      .catch((err) => {
        console.log('ERROUUUUU', err)
        setValidationErrors(err)
      })
      .finally(() => {})
  }

  return (
    <Stack spacing={1}>
      {itens?.map((item, index) => (
        <Stack
          key={item.corUuid}
          sx={{
            background: (theme) => theme.palette.background.default,
            border: (theme) => `1px solid ${theme.palette.border}`,
            borderRadius: '4px',
            p: 1,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">{item?.cor?.nome || '---'}</Typography>
            {item?.estoque !== undefined && (
              <Typography variant="h6">{`Estoque: ${
                item?.estoque || 0
              }`}</Typography>
            )}
          </Stack>

          <Form
            index={index}
            validationProps={validationProps}
            autoFocus={index === 0}
            item={item}
            onChange={handleChange}
          />
        </Stack>
      ))}
      <ButtonContainer>
        <Button
          onClick={() => {
            if (onClose) onClose()
          }}
          color="error"
        >
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained" isLoading={isLoading}>
          Salvar
        </Button>
      </ButtonContainer>
    </Stack>
  )
}
