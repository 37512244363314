import {
  monitorForElements,
  // dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import {
  IconButton,
  LinearProgress,
  Popper,
  Stack,
  TextField,
} from '@mui/material'
import Surface from 'components/Surface'
import useFunilVenda, { FunilVendaModel } from 'hooks/queries/useFunilVenda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FunilKanban from './components/FunilKanban'
import { BoardContext, BoardContextProps } from './components/BoardContext'
import FunilKanbanColumn from './components/FunilKanbanColumn'
import { createRegistry } from './components/registry'
import useFunilVendaEtapa, {
  EtapaModel,
  useSalvarOrdenacao,
} from 'hooks/queries/useFunilEtapa'
import invariant from 'tiny-invariant'
import {
  PedidoFilters1,
  PedidoModel,
  useUpdatePedido,
} from 'hooks/queries/usePedidos'
import lodash from 'lodash'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { useQueryClient } from '@tanstack/react-query'
import usePopOver from 'hooks/usePopover'
import { Close } from '@mui/icons-material'
import useNotification from 'hooks/useNotifications'
import { useUserContext } from 'components/Contexts/UserContext'
import { useDialog } from '@data-c/hooks'
import { useFilter } from 'components/MioCandidate/Filter/FilterContext'
import OnboardingCard from 'components/MioCandidate/OnboardingCard'
import image from 'assets/images/empty-data.svg'
import FunilKanbanSelectionButton from './components/FunilKanbanSelectionButton'
export interface PipelineProps {
  funilVendasUuid: string
  onSelectFunil: (funil: FunilVendaModel) => void
}

interface KanbanBoardState {
  funilVendaUuid: string
  etapas: Array<EtapaModel>
}

type Trigger = 'pointer' | 'keyboard'

export default function Pipeline(props: PipelineProps) {
  const { funilVendasUuid: funilSelecionadoUuid, onSelectFunil } = props
  const { temPermissao } = useUserContext()
  // const [isHovering, setHovering] = useState<boolean>(false)
  // const [isDragging, setDragging] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<string | boolean>(
    false,
  )
  const {
    anchorEl,
    open,
    isOpen,
    close,
    data: popData,
    updateData,
  } = usePopOver<{
    tipo: 'etapa' | 'funil'
    posicao?: number
    nome?: string
    funilUuid?: string
  }>()
  const { closeDialog, isOpen: isOpenDialog, openDialog } = useDialog()

  const queryClient = useQueryClient()
  const notifications = useNotification()
  const navigate = useNavigate()
  const { mutateAsync: updatePedido } = useUpdatePedido({ attempts: 0 })

  const { appliedValues, searchId } = useFilter<PedidoFilters1>()

  const { useObterPorUuid, useSubmit: useSubmitSalvarFunil } = useFunilVenda()
  const { mutateAsync: salvarFunil, isLoading: salvandoFunil } =
    useSubmitSalvarFunil()

  const { representante, cliente, dateRange, situacao, etiqueta, ...rest } =
    appliedValues || {}

  const situacoes: Array<string> = []
  Array.isArray(situacao)
    ? situacao.forEach((d) =>
        d.value === 'E,A,L'
          ? d.value.split(',').forEach((dd) => situacoes.push(dd))
          : situacoes.push(d.value),
      )
    : []

  const etiquetas: Array<string> = []
  Array.isArray(etiqueta)
    ? etiqueta.forEach((e) => etiquetas.push(e?.uuid || ''))
    : []

  const nFilters = {
    ...rest,
    clienteUuid: cliente?.uuid,
    representanteUuid: representante?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
    situacao: situacoes,
    etiqueta: etiquetas,
  }

  const { data: funilSelecionado, isLoading: carregandoFunilSelecionado } =
    useObterPorUuid(funilSelecionadoUuid as string, searchId, nFilters)

  const { mutateAsync: salvarOrdenacao } = useSalvarOrdenacao()
  const [data, setData] = useState<KanbanBoardState>(() => {
    return {
      funilVendaUuid: funilSelecionado?.uuid || '',
      etapas: funilSelecionado?.etapas || [],
    }
  })
  const [registry] = useState(createRegistry)
  const [instanceId] = useState(() => Symbol('instance-id'))

  const { useSubmit, useDelete } = useFunilVendaEtapa(
    funilSelecionadoUuid as string,
  )
  const { mutateAsync: excluirEtapa } = useDelete()
  const { mutateAsync: adicionarEtapa, isLoading: adicionandoEtapa } =
    useSubmit()

  // useEffect(() => {
  //   if (!funilSelecionadoUuid && temFunil) {
  //     setFunilSelecionadoUuid(funisDeVenda?.data[0].uuid)
  //   }
  // }, [funisDeVenda])

  useEffect(() => {
    setData({
      funilVendaUuid: funilSelecionado?.uuid || '',
      etapas: funilSelecionado?.etapas || [],
    })
  }, [funilSelecionado])

  const stableData = useRef(data)
  useEffect(() => {
    stableData.current = data
  }, [data])

  async function handleSalvarFunil(data: FunilVendaModel) {
    try {
      if (!data.nome) return openDialog()
      const response = await salvarFunil(data)
      if (response?.data?.uuid) onSelectFunil(response?.data)
      close()
    } catch (e) {}
  }

  async function salvarReordenacaoDasColunas(
    data: Array<Pick<EtapaModel, 'uuid' | 'posicao'>>,
  ): Promise<Boolean> {
    const toastId = notifications.notifyLoading('Movendo a etapa')
    try {
      await salvarOrdenacao({
        data,
        funilUuid: funilSelecionado?.uuid as string,
      })
      notifications.updateNotify(toastId, 'success', 'Etapa movida com sucesso')
      return true
    } catch (err) {
      notifications.updateNotify(
        toastId,
        'error',
        notifications.extractAxiosError(err),
      )
      return false
    }
  }

  async function criarNovaEtapa() {
    if (popData?.nome && popData?.posicao) {
      setValidationError(false)
      const etapa = {
        nome: popData.nome,
        posicao: popData.posicao,
        negociacoes: [],
      }

      await adicionarEtapa(etapa)
      close()
      queryClient.invalidateQueries(['FUNILDEVENDAS', funilSelecionadoUuid])
    } else {
      setValidationError('Informe o nome da etapa')
    }
  }

  const removeColumn = useCallback(
    async ({ etapa }: { etapa: EtapaModel }) => {
      const toastId = notifications.notifyLoading('Excluindo a etapa')
      try {
        await excluirEtapa(etapa)
        notifications.updateNotify(
          toastId,
          'success',
          'Etapa excluida com sucesso',
        )
      } catch (err) {
        notifications.updateNotify(
          toastId,
          'error',
          notifications.extractAxiosError(err),
        )
      }

      queryClient.invalidateQueries(['FUNILDEVENDAS', funilSelecionadoUuid])
    },
    [funilSelecionadoUuid],
  )

  const reorderColumn = useCallback(
    async ({
      startIndex,
      finishIndex,
    }: // trigger = 'keyboard',
    {
      startIndex: number
      finishIndex: number
      trigger?: Trigger
    }) => {
      const history: Array<EtapaModel> = JSON.parse(JSON.stringify(data.etapas))
      const reordered = reorder({
        list: data.etapas as Array<EtapaModel>,
        startIndex,
        finishIndex,
      }).map((e, i) => {
        e.posicao = i + 1
        return e
      })
      data.etapas = reordered
      const dataTosubmit = reordered.map((e) => {
        return {
          posicao: e.posicao,
          uuid: e.uuid as string,
        }
      })

      const result = await salvarReordenacaoDasColunas(dataTosubmit)
      if (result) {
        setData({
          ...data,
          etapas: reordered,
        })
      } else {
        setData({
          ...data,
          etapas: history,
        })
      }
    },
    [data],
  )

  const moveCard = useCallback(
    async ({
      negociacaoUuid,
      etapaOrigemUuid,
      etapaDestinoUuid,
    }: {
      negociacaoUuid: string
      etapaOrigemUuid: string
      etapaDestinoUuid: string
    }): Promise<void> => {
      const etapaOrigem = data.etapas.find((e) => e.uuid === etapaOrigemUuid)
      if (!Array.isArray(etapaOrigem?.negociacoes)) {
        return
      }
      const negociacao = etapaOrigem.negociacoes.find(
        (n) => n.uuid === negociacaoUuid,
      )
      if (!negociacao) return

      //transferindo a negociacao da etapa origem para etapa destino

      const history: Array<EtapaModel> = JSON.parse(JSON.stringify(data.etapas))

      const etapasAtualizadas = data.etapas.map((e) => {
        if (e.uuid === etapaOrigemUuid && Array.isArray(e.negociacoes)) {
          e.negociacoes = e.negociacoes.filter(
            (n) => n.uuid !== negociacao?.uuid,
          )
          return e
        }
        if (e.uuid === etapaDestinoUuid) {
          if (!Array.isArray(e.negociacoes)) {
            e.negociacoes = []
          }
          e.negociacoes.push(negociacao)
          e.negociacoes = e.negociacoes.sort((a, b) => {
            const vendaA = DateTime.fromISO(a.venda)
            const vendaB = DateTime.fromISO(b.venda)
            return vendaA.toMillis() - vendaB.toMillis()
          })
          return e
        }
        return e
      })
      negociacao.funilVendaEtapaUuid = etapaDestinoUuid
      const status = await atualizarPedido(negociacao)
      if (status) {
        setData({
          ...data,
          etapas: etapasAtualizadas,
        })
      } else {
        setData({
          ...data,
          etapas: history,
        })
      }
    },
    [data],
  )

  const addNewCard = useCallback(({ pedido }: { pedido: PedidoModel }) => {
    setData((previousState) => {
      const etapasAtualizadas = previousState.etapas.map((e) => {
        if (e.uuid === pedido.funilVendaEtapaUuid) {
          e.negociacoes.push(pedido)
        }
        return e
      })

      return { ...previousState, etapas: etapasAtualizadas }
    })
  }, [])

  useEffect(() => {
    return combine(
      monitorForElements({
        canMonitor({ source }) {
          return source.data.instanceId === instanceId
        },
        onDrop(args) {
          // setDragging(false)
          const { location, source } = args
          if (!location.current.dropTargets.length) {
            return
          }
          if (source.data.type === 'column') {
            const startIndex: number = data.etapas.findIndex(
              (etapa: EtapaModel) => etapa.uuid === source.data.columnId,
            )
            const target = location.current.dropTargets[0]

            const indexOfTarget: number = data.etapas.findIndex(
              (etapa: EtapaModel) => etapa.uuid === target.data.columnId,
            )

            if (startIndex === indexOfTarget) return

            const closestEdgeOfTarget: Edge | null = extractClosestEdge(
              target.data,
            )

            const finishIndex = getReorderDestinationIndex({
              startIndex,
              indexOfTarget,
              closestEdgeOfTarget,
              axis: 'horizontal',
            })

            reorderColumn({ startIndex, finishIndex, trigger: 'pointer' })
          }

          if (source.data.type === 'card') {
            const negociacaoUuid = source.data.itemId
            invariant(typeof negociacaoUuid === 'string')

            const [, startColumnRecord] = location.initial.dropTargets
            const etapaOrigemUuid = startColumnRecord.data.columnId

            if (location.current.dropTargets.length === 1) {
              const [destinationColumnRecord] = location.current.dropTargets
              const etapaDestinoUuid = destinationColumnRecord.data.columnId
              if (etapaOrigemUuid === etapaDestinoUuid) {
                return
              }
              invariant(typeof etapaDestinoUuid === 'string')
              invariant(typeof etapaOrigemUuid === 'string')
              moveCard({ negociacaoUuid, etapaDestinoUuid, etapaOrigemUuid })
            }

            if (location.current.dropTargets.length === 2) {
              const [_, destinationColumnRecord] = location.current.dropTargets
              const etapaDestinoUuid = destinationColumnRecord.data.columnId
              if (etapaOrigemUuid === etapaDestinoUuid) {
                return
              }
              invariant(typeof etapaDestinoUuid === 'string')
              invariant(typeof etapaOrigemUuid === 'string')
              moveCard({ negociacaoUuid, etapaDestinoUuid, etapaOrigemUuid })
            }
          }
        },
        onDragStart: () => {
          // setDragging(true)
        },
      }),
    )
  }, [data, moveCard, reorderColumn, instanceId])

  const contextValue: BoardContextProps = useMemo(() => {
    return {
      // getColumns,
      reorderColumn,
      addNewCard,
      removeColumn,
      moveCard,
      registerCard: registry.registerCard,
      registerColumn: registry.registerColumn,
      instanceId,
    }
  }, [reorderColumn, registry, moveCard, instanceId, addNewCard, removeColumn])

  async function atualizarPedido(pedido: PedidoModel): Promise<Boolean> {
    const toastId = notifications.notifyLoading('Movendo negociação')
    try {
      await updatePedido(pedido)
      notifications.updateNotify(
        toastId,
        'success',
        'Negociação movida com sucesso',
      )
      return true
    } catch (err) {
      notifications.updateNotify(
        toastId,
        'error',
        notifications.extractAxiosError(err),
      )
      return false
    }
  }

  function handleClickCard(pedido: PedidoModel) {
    navigate(`/pedidos/pedido/${pedido.uuid}`)
  }

  return (
    <Stack spacing={1} flexGrow={1}>
      <BoardContext.Provider value={contextValue}>
        {carregandoFunilSelecionado && <LinearProgress />}
        <FunilKanban
          alignCenter={data.etapas.length === 0}
          onMouseEnter={() => {
            // setHovering(true)
          }}
          onMouseLeave={() => {
            // if (!isOpen) setHovering(false)
          }}
        >
          {!funilSelecionadoUuid && (
            <OnboardingCard.Root>
              <OnboardingCard.Image src={image} />
              <OnboardingCard.PrimaryMessage
                value={'Configure seu Funil de Vendas'}
              />
              <OnboardingCard.SecondaryMessage
                value={
                  <>
                    Organize suas prospecções! <br />
                    Crie um funil novo ou escolha um existente para acompanhar
                    suas oportunidades de venda. <br />
                  </>
                }
              />
              <OnboardingCard.ActionsContainer>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    open(e, {
                      tipo: 'funil',
                    })
                  }}
                >
                  Adicionar
                </Button>
                <FunilKanbanSelectionButton
                  emptyMessage="Seleciona um funil"
                  variant="outlined"
                  value={funilSelecionado || undefined}
                  onChange={(funil) => {
                    onSelectFunil(funil)
                  }}
                />
              </OnboardingCard.ActionsContainer>
            </OnboardingCard.Root>
          )}

          {data.etapas.length === 0 &&
            !carregandoFunilSelecionado &&
            funilSelecionadoUuid && (
              <OnboardingCard.Root>
                <OnboardingCard.Image src={image} />
                <OnboardingCard.PrimaryMessage
                  value={'Configure as etapas deste funil'}
                />
                <OnboardingCard.SecondaryMessage
                  value={
                    <>
                      Adicionar as etapas do seu funil de vendas é como desenhar
                      o mapa do sucesso para o seu negócio.
                      <br />
                      Cada etapa representa uma oportunidade de conquista, um
                      passo mais próximo de alcançar seus objetivos.
                    </>
                  }
                />
                <OnboardingCard.ActionsContainer>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      open(e, {
                        tipo: 'etapa',
                        posicao: 1,
                        funilUuid: funilSelecionadoUuid,
                      })
                    }}
                  >
                    Adicionar Etapa
                  </Button>
                </OnboardingCard.ActionsContainer>
              </OnboardingCard.Root>
            )}

          {data.etapas.map((etapa, i) => (
            <>
              {/* {isHovering &&
                !isDragging &&
                temPermissao('crm_funil_venda.create') && (
                  <ColumnButton
                    variant="text"
                    onClick={(e) => {
                      setValidationError(false)
                      open(e, {
                        tipo: 'etapa',
                        posicao: i + 1,
                        funilUuid: funilSelecionadoUuid,
                      })
                    }}
                  >
                    +
                  </ColumnButton>
                )} */}
              <FunilKanbanColumn
                etapa={etapa}
                key={etapa.uuid}
                onClickCard={handleClickCard}
              />
              {i === data.etapas.length - 1 &&
                // isHovering &&
                // !isDragging &&
                temPermissao('crm_funil_venda.create') && (
                  // <ColumnButton
                  //   variant="contained"
                  //   onClick={(e) => {
                  //     setValidationError(false)
                  //     open(e, {
                  //       tipo: 'etapa',
                  //       posicao: i + 2,
                  //       funilUuid: funilSelecionadoUuid,
                  //     })
                  //   }}
                  // >
                  //   +
                  // </ColumnButton>
                  <Button
                    onClick={(e) => {
                      setValidationError(false)
                      open(e, {
                        tipo: 'etapa',
                        posicao: i + 2,
                        funilUuid: funilSelecionadoUuid,
                      })
                    }}
                    variant="outlined"
                    sx={{ width: '280px', height: '28px', mt: 0.5 }}
                  >
                    Adicionar Etapa
                  </Button>
                )}
            </>
          ))}
        </FunilKanban>
      </BoardContext.Provider>

      <Popper placement="right" anchorEl={anchorEl} open={isOpen}>
        <Surface elevation={2}>
          <ButtonContainer sx={{ m: 0, p: 0 }}>
            <IconButton onClick={() => close()}>
              <Close />
            </IconButton>
          </ButtonContainer>
          <Stack spacing={0.5}>
            <TextField
              helperText={validationError}
              error={Boolean(validationError)}
              autoFocus={true}
              label={
                popData?.tipo === 'etapa' ? 'Nome da Etapa' : 'Nome do Funil'
              }
              onChange={(e) => {
                const nome = e.target.value
                updateData((previousState) => {
                  return {
                    ...previousState,
                    tipo: previousState?.tipo || 'etapa',
                    nome: nome,
                  }
                })
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') close()
                if (e.key === 'Enter') {
                  if (popData?.tipo === 'etapa') {
                    criarNovaEtapa()
                  } else {
                    // handleSalvarFunil({ nome: popData?.nome || '' })
                  }
                }
              }}
            />
            <Button
              isLoading={adicionandoEtapa || salvandoFunil}
              variant="contained"
              onClick={() => {
                if (popData?.tipo === 'etapa') {
                  criarNovaEtapa()
                } else {
                  handleSalvarFunil({ nome: popData?.nome || '' })
                }
              }}
            >
              Adicionar {lodash.camelCase(popData?.tipo || '')}
            </Button>
          </Stack>
        </Surface>
      </Popper>

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Não foi possível criar o funil"
        maxWidth="xs"
        actions={
          <ButtonContainer>
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation()
                closeDialog()
              }}
            >
              Ok
            </Button>
          </ButtonContainer>
        }
      >
        Para criar um funil, é necessário fornecer um nome. Por exemplo: 'Meu
        Funil de Vendas'.
      </Dialog>
    </Stack>
  )
}
