import { IconButton } from '@mui/material'
import Icon from 'components/MioCandidate/Icon'
import { useFilterApi } from './FilterContext'
export default function FilterAdvancedButton() {
  const { openFilter } = useFilterApi()
  return (
    <IconButton onClick={(e) => openFilter(e)}>
      <Icon.Filter />
    </IconButton>
  )
}
