import { FunilVendaModel } from 'hooks/queries/useFunilVenda'
import Form from 'pages/FunisDeVenda/components/Form'

interface TabCadastroProps {
  funilVenda: FunilVendaModel
}

export default function TabCadastro(props: TabCadastroProps) {
  const { funilVenda } = props

  return <Form view="plain" data={funilVenda || ({} as FunilVendaModel)} />
}
