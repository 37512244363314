import { useNavigate, useParams } from 'react-router-dom'
import { Container, Tab, Tabs } from '@mui/material'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import { FormProvider } from '@data-c/providers'

import useTabelaPreco, { TabelaPrecoModel } from 'hooks/queries/useTabelaPreco'
import useTabs from 'hooks/useTabs'

import TabPanel from 'components/TabPanel'
import Loading from 'components/Displays/Loading'

import TabDescontoAgregado from './components/TabDescontoAgregado'
import TabCadastroTabelaPreco from './components/TabCadastroTabelaPreco'
import TabPrecos from './components/TabPrecos'
import Content from 'components/Layouts/Content'
import HelpButton from 'components/HelpButton'

export default function TabelaPreco() {
  const { id: tabelaPrecoUuid } = useParams()
  const { useQueryByUuid } = useTabelaPreco()
  const { data: tabelaPreco, isLoading } = useQueryByUuid<TabelaPrecoModel>(
    tabelaPrecoUuid as string,
  )

  const navigate = useNavigate()

  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <FormProvider>
        <ContentTitle.Root>
          <Breadcrumbs
            links={[
              {
                label: 'Tabela de Preços',
                link: '/tabelas-precos/lista',
              },
              {
                label: `${tabelaPreco?.nome || ''}`,
                link: `/pedidos/pedido/${tabelaPreco?.uuid}`,
              },
            ]}
          />

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs tabela de preco"
          >
            <Tab label="Cadastro" value={0} />
            <Tab label="Produtos" value={1} />
            <Tab label="Desconto Agregado" value={2} />
          </Tabs>
        </ContentTitle.Root>

        {/* <PageTitle
          title={`${tabelaPreco?.nome || ''}`}
          onBack={() => navigate(-1)}
        >
          <ButtonContainer>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs tabela de preco"
            >
              <Tab label="Cadastro" value={0} />
              <Tab label="Produtos" value={1} />
              <Tab label="Desconto Agregado" value={2} />
            </Tabs>
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28587708696468-Tabela-de-Pre%C3%A7os" />
          </ButtonContainer>
        </PageTitle> */}

        <Content>
          <TabPanel value={value} index={0}>
            <TabCadastroTabelaPreco
              tabelaPreco={tabelaPreco || ({} as TabelaPrecoModel)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabPrecos tabelaPrecoUuid={tabelaPreco?.uuid || ''} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TabDescontoAgregado tabelaPrecoUuid={tabelaPrecoUuid} />
          </TabPanel>
        </Content>
      </FormProvider>
    </Container>
  )
}
