import { useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  DataTable,
  DataTableOptions as Options,
  Flag,
  OptionStyles,
} from '@data-c/ui'
import { useFilterData, useFormApi } from '@data-c/providers'
import { usePagination } from '@data-c/hooks'
import useFunilVenda, {
  FunilVendaFilter1,
  FunilVendaModel,
} from 'hooks/queries/useFunilVenda'
import { AxiosError } from 'axios'

export default function Table() {
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useFunilVenda()
  const { appliedValues, searchId } = useFilterData<FunilVendaFilter1>()
  const { data, isLoading, isFetching, error } = useQuery({
    pagination,
    queryParams: appliedValues,
    requestId: searchId,
  })

  const { openForm, openConfirm } = useFormApi()
  const navigate = useNavigate()

  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: FunilVendaModel) => d.uuid === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'manutencao', data: FunilVendaModel) => {
      switch (event) {
        case 'edit':
          navigate(`/funis-venda/funil-venda/${data?.uuid}`)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      error={(error as AxiosError<unknown, any>)?.message}
      columns={columns}
      data={data?.data || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
