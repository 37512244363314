import {
  Alert,
  Box,
  Container,
  FormControlLabel,
  Grid2 as Grid,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import Breadcrumbs from 'components/MioCandidate/Breadcrumbs'
import ContentTitle from 'components/MioCandidate/ContentTitle'
import {
  PedidoModel,
  useQueryObterPreview,
  useSubmitFecharNegociacao,
} from 'hooks/queries/usePedidos'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from 'components/Displays/Loading'
import Content from 'components/Layouts/Content'
import { Button } from '@data-c/ui'
import Table from './components/Table'
import { useMemo, useState } from 'react'
import { formatCurrency, formatPeso } from '@data-c/hooks'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import Divider from 'components/Divider'
import { encontrarTipoVariacao } from 'hooks/business/pedido'
import useNotification from 'hooks/useNotifications'

export interface PedidoFecharProps {}

function DescricaoPedido(props: {
  value: string
  label: string
  strike?: boolean
}) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography
        sx={{
          textDecoration: Boolean(!props.strike) ? 'none' : 'line-through',
        }}
        variant="body1"
      >
        {' '}
        {props.label}
      </Typography>
      <Typography
        sx={{
          textDecoration: Boolean(!props.strike) ? 'none' : 'line-through',
        }}
        variant="body1"
        fontWeight="bold"
      >
        {props.value}
      </Typography>
    </Stack>
  )
}

export default function PedidoFechar() {
  const { id } = useParams()
  const [error, setError] = useState<false | string>(false)
  const { data, isLoading } = useQueryObterPreview(id as string)
  const navigate = useNavigate()
  const { data: configuracoes } = useQuery()
  const [aplicarDesconto, setAplicarDesconto] = useState<boolean>(true)
  const {
    mutateAsync: fecharNegociacao,
    isLoading: isSubmittingFecharNegociacao,
  } = useSubmitFecharNegociacao()
  const { extractAxiosErrorData } = useNotification()
  const maiorTipoVariacao = encontrarTipoVariacao(
    data?.preview?.map((d) => d.item) || [],
  )

  const totalizador = useMemo(() => {
    const totais = {
      totalProntaEntrega: 0,
      totalProducao: 0,
      total: 0,
    }
    data?.preview?.forEach((p) => {
      totais.totalProntaEntrega +=
        p.quantidadeProntaEntrega * p.item.precoUnitarioLiquido
      totais.totalProducao +=
        p.quantidadeFabricacao * p.item.precoUnitarioLiquido
      totais.total += p.item.precoTotalLiquido
    })

    return totais
  }, [data])

  const usaProntaEntrega = Boolean(configuracoes?.usa_pedido_pronta_entrega)

  const temProducao = totalizador.totalProducao > 0 && usaProntaEntrega
  const temProntaEntrega =
    totalizador.totalProntaEntrega > 0 && usaProntaEntrega

  const pedido = data?.pedido
  const descontoAgregadoDesbloqueado = useMemo(() => {
    const descontosAgregados = pedido?.tabelaPreco?.descontoAgregado || []
    return (
      [...descontosAgregados].reverse().find((desconto) => {
        const valor =
          (desconto?.tipoPesoValor || 1) === 1
            ? pedido?.totalLiquido
            : pedido?.pesoTotal || 0
        return (valor || 0) >= desconto.pesoValorMinimo
      }) || null
    )
  }, [pedido])

  const totalDescontoAgregado = useMemo(() => {
    const pDesconto = descontoAgregadoDesbloqueado?.pdesconto || 0

    let auxValorDesconto = ((pedido?.totalLiquido || 0) * pDesconto) / 100
    return (pedido?.totalLiquido || 0) - auxValorDesconto
  }, [descontoAgregadoDesbloqueado, pedido])

  async function handleFecharNegociacao() {
    try {
      setError(false)
      await fecharNegociacao({
        pedido: data?.pedido as PedidoModel,
        descontoAgregado: descontoAgregadoDesbloqueado,
      })
      navigate(-1)
    } catch (err) {
      const errorData = extractAxiosErrorData(err)
      if (errorData?.code === 'E_LIMITE_ESTOURADO') {
        setError(errorData.message)
      }
    }
  }

  if (isLoading)
    return (
      <Container>
        <Loading isLoading={isLoading} />
      </Container>
    )

  return (
    <Container>
      <ContentTitle.Root>
        <Breadcrumbs
          links={[
            {
              label: 'Negociações',
              link: '/pedidos/lista',
            },
            {
              label: data?.pedido?.codigo?.toString() || '',
              link: `/pedidos/pedido/${data?.pedido?.uuid}`,
            },
            {
              label: 'Fechar Negociação',
              link: `/pedidos/pedido/${data?.pedido?.uuid}/fechar/`,
            },
          ]}
        />
      </ContentTitle.Root>
      <Content>
        <Grid container spacing={1}>
          <Grid size={4}>
            <Stack
              spacing={1}
              p={1}
              sx={{
                borderRadius: '4px',
                border: (theme) => `1px solid ${theme.palette.border}`,
              }}
            >
              {temProducao && temProntaEntrega && (
                <Alert severity="info">
                  A negociação será divida em duas: <br />
                  <ul>
                    <li>
                      <b>Pronta Entrega:</b> Itens disponíveis para envio
                      imediato
                    </li>
                    <li>
                      <b>Produção:</b> Itens que precisam ser produzidos antes
                      do envio.
                    </li>
                  </ul>
                </Alert>
              )}
              <DescricaoPedido
                label={'Cliente:'}
                value={data?.pedido?.cliente?.nome || ''}
              />
              <DescricaoPedido
                label={'Tabela de Preço:'}
                value={data?.pedido?.tabelaPreco?.nome || ''}
              />
              <DescricaoPedido
                label={'Condição Pgto.:'}
                value={data?.pedido?.condicaoPagamento?.nome || ''}
              />
              {data?.pedido?.formaPagamento && (
                <DescricaoPedido
                  label={'Forma Pgto.:'}
                  value={data?.pedido?.formaPagamento?.nome || ''}
                />
              )}
              {Boolean(configuracoes?.exibe_peso) && (
                <DescricaoPedido
                  label={'Peso:'}
                  value={formatPeso(data?.pedido?.pesoTotal || 0)}
                />
              )}
              {temProducao && temProntaEntrega && (
                <DescricaoPedido
                  label={'Pronta Entrega:'}
                  value={formatCurrency(totalizador.totalProntaEntrega)}
                />
              )}
              {temProducao && temProntaEntrega && (
                <DescricaoPedido
                  label={'Produção:'}
                  value={formatCurrency(totalizador.totalProducao)}
                />
              )}
              <DescricaoPedido
                label={'Total:'}
                value={formatCurrency(totalizador.total)}
              />
              {descontoAgregadoDesbloqueado && (
                <Stack
                // mt={2}
                // p={1}
                // sx={{
                //   border: (theme) => `1px solid ${theme.palette.border}`,
                //   borderRadius: '4px',
                // }}
                >
                  <Divider />

                  <Alert severity="info">
                    Desconto de {descontoAgregadoDesbloqueado?.pdesconto}%
                    desbloqueado
                  </Alert>

                  <Box sx={{ width: '100%', textAlign: 'right' }}>
                    <FormControlLabel
                      labelPlacement="start"
                      label="Quero aplicar o desconto"
                      control={
                        <Switch
                          name="ativo"
                          onChange={() => {
                            setAplicarDesconto(!aplicarDesconto)
                          }}
                          checked={aplicarDesconto}
                        />
                      }
                    />
                  </Box>

                  <DescricaoPedido
                    strike={!aplicarDesconto}
                    label={'Total com Desconto:'}
                    value={`R$${formatCurrency(totalDescontoAgregado)}`}
                  />

                  {/* <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      fontSize="11pt"
                      sx={{
                        textDecoration: aplicarDesconto
                          ? 'none'
                          : 'line-through',
                      }}
                    >
                      Total c/ desconto:
                    </Typography>
                    
                    <Typography
                      sx={{
                        textDecoration: aplicarDesconto
                          ? 'none'
                          : 'line-through',
                      }}
                      variant="body1"
                      fontSize="11pt"
                      fontWeight="bolder"
                    >
                      {`R$${formatCurrency(totalDescontoAgregado)}`}
                    </Typography>
                  </Stack> */}
                </Stack>
              )}
              <Button
                variant="contained"
                isLoading={isSubmittingFecharNegociacao}
                onClick={handleFecharNegociacao}
              >
                Fechar Negociação
              </Button>
              {error && <Alert severity="error">{error}</Alert>}
            </Stack>
          </Grid>
          <Grid size={8}>
            <Table
              data={data?.preview || []}
              temProducao={temProducao}
              maiorTipoVariacao={maiorTipoVariacao}
            />
          </Grid>
        </Grid>
      </Content>
    </Container>
  )
}
