import { Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function CRMVisibilidade() {
  const { role, changeRolePermission } = useRoleContext()
  const { crm, atividades, motivos_perda, funis_venda, etiquetas } =
    role?.permissoes || {}

  return (
    <CardConfig
      title="CRM"
      description="Define se o usuário tem permissão para acessar o menu CRM"
      collapse={Boolean(crm?.visibilidade)}
      component={
        <Switch
          name="crm.visibilidade"
          checked={Boolean(crm?.visibilidade)}
          onChange={changeRolePermission}
          disabled={Boolean(role?.fixa)}
        />
      }
    >
      <CardConfig
        title="Atividades"
        description="Define se o usuário tem permissão para acessar o menu Atividades"
        disabledPadding
        component={
          <Switch
            name="atividades.visibilidade"
            checked={Boolean(atividades?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Motivos de Perda"
        description="Define se o usuário tem permissão para acessar o menu Motivos de Perda"
        disabledPadding
        component={
          <Switch
            name="motivos_perda.visibilidade"
            checked={Boolean(motivos_perda?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Funis de Venda"
        description="Define se o usuário tem permissão para acessar o menu Funis de Vendas"
        disabledPadding
        component={
          <Switch
            name="funis_venda.visibilidade"
            checked={Boolean(funis_venda?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Etiquetas"
        description="Define se o usuário tem permissão para acessar o menu Etiquetas"
        disabledPadding
        component={
          <Switch
            name="etiquetas.visibilidade"
            checked={Boolean(etiquetas?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
    </CardConfig>
  )
}
