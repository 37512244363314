import { ComponentType, useMemo, useState } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Stack, TextFieldProps } from '@mui/material'

import { Button, CurrencyTextField, DataTable, Flag } from '@data-c/ui'
import { formatCurrency, useDialog, usePagination } from '@data-c/hooks'

import useTabelaPreco, {
  ItemTabelaPrecoFilter1,
  UpdateVinculoProdutosInputProps,
} from 'hooks/queries/useTabelaPreco'

import CurrencyTextButton from 'components/Inputs/CurrencyTextButton'
import { ConfirmationDialog } from './components/ConfirmationDialog'
import { useFilterData } from '@data-c/providers'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import CheckboxButton from 'components/Inputs/CheckboxButton'
import Editable from 'components/Editable'
import { ItemPedidoModel } from 'hooks/queries/usePedidos'
import { encontrarTipoVariacao } from 'hooks/business/pedido'
export interface TableProps {
  tabelaPrecoUuid: string
}

function temProdutosSelecionados(
  indexProdutosSelecionados: Array<string | undefined>,
): boolean {
  return indexProdutosSelecionados.length > 0
}

export default function Table(props: TableProps) {
  const { tabelaPrecoUuid } = props
  const { data: configuracoes } = useQuery()
  const { appliedValues, searchId } = useFilterData<ItemTabelaPrecoFilter1>()

  const [quantidadeMinima, setQuantidadeMinima] = useState<number>(0)
  const [precoGlobal, setPrecoGlobal] = useState<number>(0)
  const [comissaoGlobal, setComissaoGlobal] = useState<number>(0)
  const [valorPontoGlobal, setValorPontoGlobal] = useState<number>(0)
  const [indexProdutosSelecionados, setIndexProdutosSelecionados] = useState<
    number[]
  >([])

  const [loadingItens, setLoadingItens] = useState<Array<number>>([])
  const { changePageSize, changePage, pagination } = usePagination()
  const {
    useQueryItemTabelaPreco,
    useDesvincularProdutos,
    useUpdateProdutosVinculados,
  } = useTabelaPreco()
  const { mutateAsync: updateProdutoVinculado } = useUpdateProdutosVinculados()
  const {
    data: produtosVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryItemTabelaPreco(tabelaPrecoUuid, {
    queryParams: appliedValues,
    requestId: searchId,
    pagination,
  })
  const maiorTipoVariacao = encontrarTipoVariacao(
    (produtosVinculados?.data || []) as Array<ItemPedidoModel>,
  )
  const {
    mutateAsync: desvincularProdutos,
    isLoading: isLoadingDesvincularProdutos,
  } = useDesvincularProdutos()
  const {
    mutateAsync: updateProdutosVinculados,
    isLoading: isLoadingUpdatePrecoProdutosVinculados,
  } = useUpdateProdutosVinculados()

  const { isOpen: isOpenDialog, openDialog, closeDialog } = useDialog()

  const formattedData = useMemo(
    () =>
      produtosVinculados?.data?.map((item) => {
        return {
          ...item,
          codigoProduto: item.produto.codigo,
          nomeProduto: item.produto.nome,
          precoProduto: formatCurrency(item?.precoDeTabela || ''),
          percentualComissao: formatCurrency(item?.percentualComissao || ''),
          valorPonto: formatCurrency(item?.valorPonto || ''),
          corNome: item?.cor?.nome || '',
          gradeNome: item?.grade?.nome,
          classeProduto: item?.produto.produtoClasse?.nome,
          familiaProduto: item?.produto.familiaProduto?.nome,
          grupoProduto: item?.produto.grupoProduto?.nome,
          linhaProduto: item?.produto.linhaProduto?.nome,
        }
      }) || [],
    [produtosVinculados],
  )

  async function handleChangeValue(
    value: any,
    rowIndex: number,
    valueType: string,
  ) {
    const produtoSelecionado = [
      {
        produtoUuid: formattedData[rowIndex]?.produto?.uuid || '',
        uuid: formattedData[rowIndex]?.uuidItem || '',
        [valueType]: value,
      },
    ]
    setLoadingItens((previous) => [...previous, rowIndex])
    await updateProdutoVinculado({
      item: produtoSelecionado,
      tabelaPrecoUuid,
    })
    setLoadingItens((previous) => previous.filter((p) => p != rowIndex))
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          // setCellProps() {
          //   return { width: '96px' }
          // },
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
        options: {
          sort: false,
        },
      },
      {
        name: 'corNome',
        label: 'Variação 1',
        options: {
          display: maiorTipoVariacao > 0,
          sort: false,
        },
      },
      {
        name: 'gradeNome',
        label: 'Variação 2',
        options: {
          display: configuracoes?.modulo_pedido === 'GRADE',
          sort: false,
        },
      },
      {
        name: 'quantidadeMinima',
        label: 'Grade fechada',
        options: {
          display: Boolean(configuracoes?.usa_grade_fechada),
          setCellProps() {
            return {
              style: {
                textAlign: 'center',
                // width: '60px',
              },
            }
          },
          customBodyRender(value) {
            return <Flag isFlagged={value === 1} type="success" />
          },
          sort: false,
        },
      },
      {
        name: 'classeProduto',
        label: 'Classe',
        options: {
          sort: false,
        },
      },
      {
        name: 'familiaProduto',
        label: 'Família',
        options: {
          sort: false,
        },
      },
      {
        name: 'grupoProduto',
        label: 'Grupo',
        options: {
          sort: false,
        },
      },
      {
        name: 'linhaProduto',
        label: 'Linha',
        options: {
          sort: false,
        },
      },
      {
        name: 'valorPonto',
        label: 'Valor do Ponto',
        options: {
          display: configuracoes?.usa_programa_fidelidade != 'DESATIVADO',
          setCellProps() {
            return {
              style: { textAlign: 'right', width: '140px' },
            }
          },
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <Editable
                value={value || 0}
                onChange={(value) =>
                  handleChangeValue(value, rowIndex, 'valorPonto')
                }
                component={CurrencyTextField as ComponentType<TextFieldProps>}
                isLoading={loadingItens.includes(rowIndex)}
              />
            )
          },
        },
      },
      {
        name: 'percentualComissao',
        label: 'Comissão(%)',
        options: {
          setCellProps() {
            return {
              style: { textAlign: 'right', width: '140px' },
            }
          },
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <Editable
                value={value || 0}
                onChange={(value) =>
                  handleChangeValue(value, rowIndex, 'percentualComissao')
                }
                component={CurrencyTextField as ComponentType<TextFieldProps>}
                isLoading={loadingItens.includes(rowIndex)}
              />
            )
          },
        },
      },
      {
        name: 'precoProduto',
        label: 'Preço',
        options: {
          setCellProps() {
            return {
              style: { textAlign: 'right', width: '140px' },
            }
          },
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <Editable
                value={value || 0}
                onChange={(value) =>
                  handleChangeValue(value, rowIndex, 'preco')
                }
                component={CurrencyTextField as ComponentType<TextFieldProps>}
                isLoading={loadingItens.includes(rowIndex)}
              />
            )
          },
        },
      },
    ],
    [produtosVinculados, loadingItens],
  )

  async function handleUpdateProdutosVinculados() {
    const produtosSelecionados: Array<UpdateVinculoProdutosInputProps> =
      indexProdutosSelecionados.map((index) => {
        return {
          uuid: produtosVinculados?.data[index]?.uuidItem || '',
          produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
          preco: precoGlobal,
        }
      })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })
  }

  async function handleUpdatePercentualComissaoProdutosVinculados() {
    const produtosSelecionados: Array<UpdateVinculoProdutosInputProps> =
      indexProdutosSelecionados.map((index) => {
        return {
          uuid: produtosVinculados?.data[index]?.uuidItem || '',
          produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
          percentualComissao: comissaoGlobal,
        }
      })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })
  }

  async function handleUpdateValorPonto() {
    const produtosSelecionados: Array<UpdateVinculoProdutosInputProps> =
      indexProdutosSelecionados.map((index) => {
        return {
          uuid: produtosVinculados?.data[index]?.uuidItem || '',
          produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
          valorPonto: valorPontoGlobal,
        }
      })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })
  }

  async function handleUpdateQtdMinima() {
    const produtosSelecionados = indexProdutosSelecionados.map((index) => {
      return {
        uuid: produtosVinculados?.data[index].uuidItem || '',
        produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
        // preco: produtosVinculados?.data[index]?.preco,
        quantidadeMinima: quantidadeMinima,
      }
    })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })

    // setIndexProdutosSelecionados([])
    // setQuantidadeMinima()
  }

  async function handleClickDesvincularProdutos() {
    const produtosSelecionadosIds = indexProdutosSelecionados.map((index) => {
      return produtosVinculados?.data[index]?.uuidItem || ''
    })

    if (tabelaPrecoUuid && temProdutosSelecionados(produtosSelecionadosIds)) {
      try {
        await desvincularProdutos({
          tabelaPrecoUuid,
          produtosSelecionadosIds,
        })
      } finally {
        setIndexProdutosSelecionados([])
        closeDialog()
      }
    }
  }

  return (
    <>
      <DataTable
        error={error?.message}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        data={formattedData || []}
        pagination={produtosVinculados?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
          selectableRows: 'multiple',
          rowsSelected: indexProdutosSelecionados,
          sort: true,
          onRowSelectionChange: (_, __, rowsSelected: any) =>
            setIndexProdutosSelecionados(rowsSelected),
          customToolbarSelect() {
            return (
              <Stack spacing={1} mr={1}>
                {Boolean(configuracoes?.usa_grade_fechada) && (
                  <CheckboxButton
                    buttonProps={{
                      onClick: handleUpdateQtdMinima,
                      label: Boolean(quantidadeMinima)
                        ? 'Fechar Grade'
                        : 'Liberar Grade',
                    }}
                    checked={Boolean(quantidadeMinima)}
                    onChange={(e) => {
                      const qtd = e.target.checked ? 1 : 0
                      setQuantidadeMinima(qtd)
                    }}
                    isLoading={isLoadingUpdatePrecoProdutosVinculados}
                  />
                )}

                <CurrencyTextButton
                  buttonProps={{
                    onClick: handleUpdateProdutosVinculados,
                    label: 'Aplicar preços',
                  }}
                  value={precoGlobal}
                  onChange={(_, value) => setPrecoGlobal(value)}
                  isLoading={isLoadingUpdatePrecoProdutosVinculados}
                />

                <CurrencyTextButton
                  buttonProps={{
                    onClick: handleUpdatePercentualComissaoProdutosVinculados,
                    label: 'Aplicar comissões',
                  }}
                  value={comissaoGlobal}
                  onChange={(_, value) => setComissaoGlobal(value)}
                  isLoading={isLoadingUpdatePrecoProdutosVinculados}
                />

                {configuracoes?.usa_programa_fidelidade != 'DESATIVADO' && (
                  <CurrencyTextButton
                    buttonProps={{
                      onClick: handleUpdateValorPonto,
                      label: 'Aplicar valor do ponto',
                    }}
                    value={valorPontoGlobal}
                    onChange={(_, value) => setValorPontoGlobal(value)}
                    isLoading={isLoadingUpdatePrecoProdutosVinculados}
                  />
                )}

                <Button color="error" onClick={openDialog}>
                  Desvincular Produtos
                </Button>
              </Stack>
            )
          },
        }}
      />

      <ConfirmationDialog
        isOpen={isOpenDialog}
        isLoading={isLoadingDesvincularProdutos}
        onClose={closeDialog}
        onClick={handleClickDesvincularProdutos}
      />
    </>
  )
}
