import FilterAdvancedButton from './FilterAdvancedButton'
import FilterAdvancedContainer from './FilterAdvancedContainer'
import FilterContainer from './FilterContainer'
import { FilterProvider } from './FilterContext'
import FilterRoot from './FilterRoot'
import FilterSearchTextField from './FilterSearchTextField'
import FilterApplyButton from './FilterApplyButton'
import FilterResetButton from './FilterResetButton'
import FilterAdvancedActionsContainer from './FilterAdvancedActionsContainer'
import FilterChips from './FilterChips'
import FiltrerChip from './FilterChip'

const Filter = {
  Root: FilterRoot,
  SearchTextField: FilterSearchTextField,
  Container: FilterContainer,
  AdvancedContainer: FilterAdvancedContainer,
  AdvancedActionsContainer: FilterAdvancedActionsContainer,
  AdvancedButton: FilterAdvancedButton,
  Provider: FilterProvider,
  ApplyButton: FilterApplyButton,
  ResetButton: FilterResetButton,
  Chips: FilterChips,
  Chip: FiltrerChip,
}

export default Filter
