import { AppBar, Stack, Toolbar } from '@mui/material'
import React, { ReactNode } from 'react'

export interface HeaderRootProps {
  children: ReactNode
}
// const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export default function HeaderRoot({ children }: HeaderRootProps) {
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Toolbar>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            {children}
          </Stack>
        </Toolbar>
      </AppBar>
      {/* <Offset />
      <Offset /> */}
    </React.Fragment>
  )
}
