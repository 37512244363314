import { createContext, useContext, useState } from 'react'
import invariant from 'tiny-invariant'

export interface PreserveNavigationDataProps<T> {
  data?: T | null
  setData: (data: T) => void
}

export const PreserveNavigationDataContext =
  createContext<PreserveNavigationDataProps<any> | null>(null)

export function PreserveNavigationDataProvider<T>(props: {
  children: React.ReactNode
}) {
  const [data, setData] = useState<T>()
  const { children } = props
  return (
    <PreserveNavigationDataContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </PreserveNavigationDataContext.Provider>
  )
}

export function usePreserveNavigationData<T>(): PreserveNavigationDataProps<T> {
  const value = useContext(PreserveNavigationDataContext)
  invariant(value, 'cannot find PreserveNavigationDataContext provider')
  return value
}
