import { formatCurrency } from '@data-c/hooks'
import { CurrencyCellStyle, DataTable } from '@data-c/ui'
import { ItemPedidoModel } from 'hooks/queries/usePedidos'
import { MUIDataTableColumnDef } from 'mui-datatables'
import ParseTo from 'utils/ParseTo'

export interface TableProps {
  data: Array<{
    item: ItemPedidoModel
    quantidadeProntaEntrega: number
    quantidadeFabricacao: number
  }>
  temProducao: boolean
  maiorTipoVariacao: number
}

export default function Table(props: TableProps) {
  const { data, temProducao, maiorTipoVariacao } = props

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoProduto',
      label: 'Código',
      options: {
        setCellProps: () => ({
          style: {
            width: '96px',
          },
        }),
      },
    },
    {
      name: 'nomeProduto',
      label: 'Nome',
      options: {
        setCellProps: () => ({
          style: {
            minWidth: '240px',
          },
        }),
      },
    },
    {
      name: 'nomeCor',
      label: 'Variação 1',
      options: {
        display: maiorTipoVariacao > 0,
        setCellProps: () => ({
          style: {
            // minWidth: '240px',
          },
        }),
      },
    },
    {
      name: 'quantidadeProntaEntrega',
      label: 'Qtd Pronta Entrega',
      options: {
        display: temProducao,
        setCellProps: () => ({
          style: {
            width: '130px',
            textAlign: 'right ',
          },
        }),
      },
    },
    {
      name: 'quantidadeFabricacao',
      label: 'Qtd Produção',
      options: {
        display: temProducao,
        setCellProps: () => ({
          style: {
            width: '130px',
            textAlign: 'right ',
          },
        }),
      },
    },
    {
      name: 'quantidadeTotal',
      label: 'Qtd Total',
      options: {
        // display: maiorTipoVariacao > 0,
        setCellProps: () => ({
          style: {
            width: '130px',
            textAlign: 'right ',
          },
        }),
      },
    },
    {
      label: 'Total',
      name: 'precoTotalLiquido',
      options: {
        setCellProps: CurrencyCellStyle,
      },
    },
  ]

  const itens = data.map((d) => ({
    codigoProduto: d.item.produto?.codigo,
    nomeProduto: d.item.produto?.nome,
    nomeCor: d.item.cor?.nome,
    quantidadeProntaEntrega: d.quantidadeProntaEntrega,
    quantidadeFabricacao: d.quantidadeFabricacao,
    quantidadeTotal:
      ParseTo.number(d.quantidadeProntaEntrega) +
      ParseTo.number(d.quantidadeFabricacao),
    precoTotalLiquido: formatCurrency(d.item.precoTotalLiquido),
  }))

  return <DataTable columns={columns} data={itens} />
}
