import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material'
import {
  Cancel,
  CheckCircle,
  Handshake,
  KeyboardArrowDown,
  PriceChange,
  RestartAlt,
  Troubleshoot,
} from '@mui/icons-material'
import MarcarComoPerdidaForm from '../MarcarComoPerdidaForm'
import EnviarEmail from '../EnviarEmail'
import usePopOver from 'hooks/usePopover'
import { useUserContext } from 'components/Contexts/UserContext'
import {
  PedidoModel,
  SituacaoPedidoEnum,
  //   useDeleteItemPedido,
  useSubmitCancelarSolicitacaoCotacao,
  useSubmitSolicitarCotacao,
  useSubmitAnalisarCotacao,
  useSubmitConcluirCotacao,
  useSubmitReabrirCotacao,
} from 'hooks/queries/usePedidos'

// import { useDialog } from '@data-c/hooks'
// import FecharPedidoDialog from '../FecharPedidoDialog'
import { DescontoAgregadoModel } from 'hooks/queries/useTabelaPreco'
import { useNavigate } from 'react-router-dom'

export interface OptionsProps {
  pedido: PedidoModel
  descontoAgregadoDesbloqueado?: DescontoAgregadoModel | null
}

export default function Options(props: OptionsProps) {
  const { pedido } = props
  const { anchorEl, open, isOpen, close } = usePopOver()
  const { user, temPermissao } = useUserContext()
  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'
  const navigate = useNavigate()
  // const {
  //   // data: dataDialog,
  //   openDialog: openDialog,
  //   isOpen: isOpenDialog,
  //   closeDialog: closeDialog,
  // } = useDialog()

  const {
    mutateAsync: solicitarCotacao,
    isLoading: isSubmittingSolicitarCotacao,
  } = useSubmitSolicitarCotacao()

  const {
    mutateAsync: analisarCotacao,
    isLoading: isSubmittingAnalisarCotacao,
  } = useSubmitAnalisarCotacao()

  const { mutateAsync: aprovarCotacao, isLoading: isSubmittingAprovarCotacao } =
    useSubmitConcluirCotacao()

  const { mutateAsync: reabrirCotacao, isLoading: isSubmittingReabrirCotacao } =
    useSubmitReabrirCotacao()

  const {
    mutateAsync: cancelarSolicitacaoCotacao,
    isLoading: isSubmittingCancelarSolicitacao,
  } = useSubmitCancelarSolicitacaoCotacao()

  function podeFecharNegociacao() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.COTACAO_APROVADA,
      SituacaoPedidoEnum.WEB,
      SituacaoPedidoEnum.MOBILE,
    ]
    if (!pedido) return false
    return (
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function desabilitarAcoes() {
    const situacoesDesabilitadas = [
      SituacaoPedidoEnum.FECHADO,
      SituacaoPedidoEnum.CANCELADO,
      SituacaoPedidoEnum.LIBERADO,
    ]
    if (!pedido) return false
    return situacoesDesabilitadas.includes(
      pedido.situacao as SituacaoPedidoEnum,
    )
  }

  async function solicitarCotacaoAsync() {
    if (!pedido) return
    await solicitarCotacao(pedido)
    close()
  }

  async function analisarCotacaoAsync() {
    if (!pedido) return
    await analisarCotacao(pedido)
    close()
  }

  async function aprovarCotacaoAsync() {
    if (!pedido) return
    await aprovarCotacao(pedido)
    close()
  }

  async function reabrirCotacaoAsync() {
    if (!pedido) return
    await reabrirCotacao(pedido)
    close()
  }

  async function cancelarSolicitacaoCotacaoAsync() {
    if (!pedido) return
    await cancelarSolicitacaoCotacao(pedido)
    close()
  }

  function podeSolicitarCotacao() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.WEB,
      SituacaoPedidoEnum.MOBILE,
    ]
    if (!pedido) return false
    return (
      !user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeCancelarSolicitacaoCotacao() {
    const situacoesPermitidas = [SituacaoPedidoEnum.COTACAO_SOLICITACAO]
    if (!pedido) return false
    return (
      !user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeAnalisarCotacao() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.COTACAO_SOLICITACAO,
      SituacaoPedidoEnum.WEB,
    ]
    if (!pedido) return false
    return (
      user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeAprovarCotacao() {
    const situacoesPermitidas = [SituacaoPedidoEnum.COTACAO_ANALISE]
    if (!pedido) return false
    return (
      user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeReabrirCotacao() {
    const situacoesPermitidas = [SituacaoPedidoEnum.COTACAO_APROVADA]
    if (!pedido) return false
    return (
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeMarcarPerdida() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.MOBILE,
      SituacaoPedidoEnum.WEB,
      SituacaoPedidoEnum.COTACAO_ANALISE,
      SituacaoPedidoEnum.COTACAO_APROVADA,
      SituacaoPedidoEnum.COTACAO_SOLICITACAO,
    ]
    if (!pedido) return false
    return (
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  return (
    <Box>
      <ClickAwayListener
        onClickAway={() => {
          close()
        }}
      >
        <div>
          <Button
            variant="contained"
            disableElevation
            onClick={open}
            endIcon={<KeyboardArrowDown />}
          >
            Opções
          </Button>
          <Popper
            sx={{ zIndex: 1 }}
            placement="bottom-end"
            anchorEl={anchorEl}
            open={isOpen}
          >
            <Paper
              sx={{
                minWidth: 200,
                maxWidth: '100%',
                pt: 1,
                pb: 1,
              }}
              elevation={0}
            >
              <EnviarEmail pedido={pedido} />
              {!desabilitarAcoes() && <Divider />}

              {podeSolicitarCotacao() && (
                <MenuItem onClick={solicitarCotacaoAsync}>
                  <ListItemIcon>
                    <PriceChange />
                  </ListItemIcon>
                  <ListItemText>Solicitar cotação</ListItemText>
                  {isSubmittingSolicitarCotacao && (
                    <Box>
                      <CircularProgress color="primary" size={18} />
                    </Box>
                  )}
                </MenuItem>
              )}
              {podeAnalisarCotacao() && (
                <MenuItem onClick={analisarCotacaoAsync}>
                  <ListItemIcon>
                    <Troubleshoot />
                  </ListItemIcon>
                  <ListItemText>Analisar Cotação</ListItemText>
                  {isSubmittingAnalisarCotacao && (
                    <Box>
                      <CircularProgress color="primary" size={18} />
                    </Box>
                  )}
                </MenuItem>
              )}
              {podeCancelarSolicitacaoCotacao() && (
                <MenuItem onClick={cancelarSolicitacaoCotacaoAsync}>
                  <ListItemIcon>
                    <Cancel />
                  </ListItemIcon>
                  <ListItemText>Cancelar Cotação</ListItemText>
                  {isSubmittingCancelarSolicitacao && (
                    <Box>
                      <CircularProgress color="primary" size={18} />
                    </Box>
                  )}
                </MenuItem>
              )}
              {podeReabrirCotacao() && (
                <MenuItem onClick={reabrirCotacaoAsync}>
                  <ListItemIcon>
                    <RestartAlt />
                  </ListItemIcon>
                  <ListItemText>Reabrir Cotação</ListItemText>
                  {isSubmittingReabrirCotacao && (
                    <Box>
                      <CircularProgress color="primary" size={18} />
                    </Box>
                  )}
                </MenuItem>
              )}
              {podeAprovarCotacao() && (
                <MenuItem onClick={aprovarCotacaoAsync}>
                  <ListItemIcon>
                    <CheckCircle />
                  </ListItemIcon>
                  <ListItemText>Aprovar Cotação</ListItemText>
                  {isSubmittingAprovarCotacao && (
                    <Box>
                      <CircularProgress color="primary" size={18} />
                    </Box>
                  )}
                </MenuItem>
              )}
              {podeMarcarPerdida() && (
                <MarcarComoPerdidaForm close={close} pedido={pedido} />
              )}
              {podeFecharNegociacao() && (
                <MenuItem
                  onClick={() => {
                    navigate(`/pedidos/pedido/${pedido?.uuid}/fechar`)
                  }}
                >
                  <ListItemIcon>
                    <Handshake />
                  </ListItemIcon>
                  <ListItemText>Fechar negócio</ListItemText>
                </MenuItem>
              )}
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>

      {/* <FecharPedidoDialog
        descontoAgregadoDesbloqueado={descontoAgregadoDesbloqueado}
        open={isOpenDialog}
        onClose={closeDialog}
        pedido={pedido}
      /> */}
    </Box>
  )
}
