import { Box, PaperProps, Stack } from '@mui/material'
import { ReactNode, forwardRef, memo } from 'react'
export interface FunilKanbanProps extends PaperProps {
  children: ReactNode
  alignCenter?: boolean
}

const FunilKanban = forwardRef<HTMLDivElement, FunilKanbanProps>(
  (props: FunilKanbanProps, ref) => {
    const { children, alignCenter, ...rest } = props

    return (
      <Box
        {...rest}
        sx={
          alignCenter
            ? {
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                overflow: 'auto',
              }
            : {
                display: 'flex',
                flexGrow: 1,
                overflow: 'auto',
              }
        }
      >
        <Stack id="stack-column" ref={ref} spacing={0} direction="row">
          {children}
        </Stack>
      </Box>
    )
  },
)

export default memo(FunilKanban)
