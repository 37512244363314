import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import useFunilVenda, { FunilVendaModel } from 'hooks/queries/useFunilVenda'

export type DropDownFunilVendasProps = {
  onChange: (value: FunilVendaModel | undefined) => void
} & Omit<TextFieldProps, 'onChange'>

export default function DropDownFunilVendas(props: DropDownFunilVendasProps) {
  const { onChange, value, ...rest } = props
  const { useQueryRaw } = useFunilVenda()
  const { data } = useQueryRaw()
  const funis = data || []

  function handleChange(uuid: string) {
    const valorEncontrado = funis.find((f) => f.uuid === uuid)
    onChange(valorEncontrado)
  }

  return (
    <TextField
      select
      onChange={(e) => {
        handleChange(e.target.value)
      }}
      value={value}
      {...rest}
    >
      {funis.map((f) => (
        <MenuItem key={f.uuid} value={f.uuid}>
          {f.nome}
        </MenuItem>
      ))}
    </TextField>
  )
}
